import { Box, Stack, Typography, useTheme } from '@mui/material';
import { countOccurrencesArray } from 'common/utils/count';
import {
  IPhotoIntelligenceData,
  LockColor,
  PhotoIntelligenceStatusEnum,
  SeverityLevelEnum,
  ToggleEnumType,
} from 'constants/photoIntelligence';
import ReactECharts from 'echarts-for-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { photoIntelligenceSelector } from 'store/slices/map-view/photoIntelligenceAnalytics';
import VisualizationTableCommon from './components/VisualizationTableCommon';

interface StatusChartProps {
  data: IPhotoIntelligenceData[] | undefined;
  isChartMode: boolean;
}

const StatusChart: FC<StatusChartProps> = ({ data, isChartMode }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { status, severityLevel, frequency } = useAppSelector(photoIntelligenceSelector);

  const filteredListData = data
    ?.filter((item) =>
      severityLevel.listData
        .filter((each) => each.value)
        ?.map((each) => each.key)
        ?.some((key) => typeof key === 'string' && item.severityLevels.includes(key as SeverityLevelEnum))
    )
    ?.filter((item) =>
      frequency.listData
        .filter((each) => each.value)
        ?.map((each) => each.key)
        ?.includes(item.frequency)
    )
    ?.map((item) => ({
      ...item,
      status: [item.status].filter((ele) =>
        status.listData
          .filter((each) => each.value)
          ?.map((each) => each.key)
          ?.includes(ele)
      ),
    }));

  const reducedListData = filteredListData?.reduce(
    (acc, cur) => {
      acc.status = [...acc.status, ...cur.status];
      return acc;
    },
    { status: [] as PhotoIntelligenceStatusEnum[] }
  );

  const occurrenceData = countOccurrencesArray(reducedListData?.status || [], ToggleEnumType.STATUS);

  const finalListData = occurrenceData?.map((item) => ({
    value: item.value,
    itemStyle: { color: LockColor[item.key] },
    name: t(`trans.${item.key}`),
  }));

  const options = {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      show: true,
      orient: 'vertical',
      right: 0,
      top: 10,
      textStyle: {
        color: theme.palette.mode === 'dark' ? '#fff' : '#000',
      },
    },
    series: [
      {
        name: 'Status',
        type: 'pie',
        top: 0,
        data: finalListData || [],
        label: {
          show: false,
        },
        emphasis: {
          disabled: true,
        },
      },
    ],
  };

  const renderChart = (
    <Stack sx={{ px: 2.5, my: '15px', height: '100%' }} direction="row" spacing={3}>
      <ReactECharts option={options} style={{ width: '100%', height: '100%' }} />
    </Stack>
  );
  const renderTable = <VisualizationTableCommon data={occurrenceData} fieldName="Status" />;

  return (
    <Box style={{ alignItems: 'center' }}>
      <Typography>{t('trans.status')}</Typography>
      {isChartMode ? renderChart : renderTable}
    </Box>
  );
};

export default StatusChart;
