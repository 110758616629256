import { QUERY_KEY } from 'constants/constants';
import { IParent, IPhotoIntelligenceMLevel } from 'constants/photoIntelligence';
import { useQuery } from 'react-query';
import { getPhotoIntelligenceAllLocation } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';

const useEnableSummaryInHigherLevels = () => {
  const { isLastLevelId, levelId } = useAppSelector(mapViewSelector);

  const { data: dataPhotoIntelligence } = useQuery<IPhotoIntelligenceMLevel[]>(
    [QUERY_KEY.ALL_LOCATION_PHOTO_INTELLIGENCE, levelId],
    () => getPhotoIntelligenceAllLocation(levelId!),
    {
      enabled: !!levelId && !isLastLevelId,
    }
  );

  const listIdParents: string[] = [];

  const recursiveFindParentId = (node: IParent | undefined) => {
    if (!node) return;
    listIdParents.push(node._id);
    if (!node.parent) return;
    recursiveFindParentId(node.parent);
  };

  dataPhotoIntelligence?.forEach((item) => recursiveFindParentId(item.level.parent));

  const setIdParents = [...new Set([...listIdParents])];

  const isShowPhotoIntelligenceSummaryInHigherLevel = setIdParents.includes(levelId!);
  return { isShowPhotoIntelligenceSummaryInHigherLevel };
};

export default useEnableSummaryInHigherLevels;
