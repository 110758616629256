import { Box, InputLabel, useTheme } from '@mui/material';
import { ButtonCropType } from 'components/Common/ButtonCropType';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clearVigorAnalytics } from 'store/slices/map-view/vigorAnalytics';
import { changeCropType, changeLevelId, mapViewSelector } from 'store/slices/mapViewSlice';

const CardTreeStructure = (props: any) => {
  const theme = useTheme();
  const { cropType } = useAppSelector(mapViewSelector);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { cropTypeOptions, setSelectedCropType } = props;

  const setLevelId = useCallback(() => {
    dispatch(changeLevelId({ levelId: undefined, isLevelLasted: undefined, levels: [] }));
    //reset vigor analytic
    dispatch(clearVigorAnalytics());
  }, [dispatch]);

  const setCropTypeSelected = useCallback(
    (type?: string) => {
      dispatch(changeCropType(type));
    },
    [dispatch]
  );

  useEffect(() => {
    setCropTypeSelected();
    setLevelId();
  }, []);

  const styles = {
    userCardStyles: {
      minWidth: '120px',
      width: 'calc(100% - 12px)',
      backgroundColor: theme.palette.mode === 'dark' ? '#1F1F1F' : '#FFFFFF',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      borderRadius: '10px',
      textTransform: 'none',
      position: 'relative',
      boxShadow: '0 1px 2px 0 rgba(0, 0 ,0, .1), 0 1px 3px 0 rgba(0, 0, 0, 0.06)',
      border: `1px solid ${theme.palette.divider}`,
      padding: '12px',
    },
    userNameStyles: {
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '14px',
      color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#616161',
      textAlign: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      width: '100px',
    },
    avatarStyles: {
      height: '50px',
      width: '50px',
      m: 'auto',
      mt: 1.75,
      mb: 1.5,
    },
  };

  return (
    <Box
      sx={{
        ...styles.userCardStyles,
      }}>
      <InputLabel sx={{ fontWeight: '600' }}>{t('trans.type')}</InputLabel>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: 'auto',
          width: '100%',
        }}>
        {isEmpty(cropTypeOptions) ? (
          <InputLabel sx={{ color: '#9E9E9E', fontWeight: 400, fonSize: '14px', textTransform: 'unset' }}>
            You have not selected any type yet
          </InputLabel>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              flexWrap: 'wrap',
            }}>
            {props.testData
              .sort((a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name))
              .map((item: any) => (
                <Box
                  key={item._id}
                  sx={{
                    mx: 1,
                    my: 0.5,
                  }}>
                  <ButtonCropType
                    isSelected={item._id === cropType}
                    label={item.name}
                    icon={item.icon}
                    selectedCropType={() => {
                      if (cropType !== item._id) {
                        setCropTypeSelected(item._id);
                        setSelectedCropType(item._id);
                      } else {
                        setCropTypeSelected();
                        setSelectedCropType();
                      }
                      setLevelId();
                    }}
                  />
                </Box>
              ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CardTreeStructure;
