import { FormControlLabel, Grid, Stack } from '@mui/material';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeFrequencyToggle,
  changeIsSelectAllFrequency,
  changeIsSelectAllSeverityLevel,
  changeIsSelectAllStatus,
  changeSeverityLevelToggle,
  changeStatusToggle,
  photoIntelligenceSelector,
} from 'store/slices/map-view/photoIntelligenceAnalytics';
import CommonCollapse from './components/CommonCollapse';

const ToggleArea = () => {
  const { t } = useTranslation();
  const [isOpenStatus, setIsOpenStatus] = useState<boolean>(true);
  const [isOpenSeverityLevel, setIsOpenSeverityLevel] = useState<boolean>(true);
  const [isOpenFrequency, setIsOpenFrequency] = useState<boolean>(true);

  const dispatch = useAppDispatch();
  const {
    status: { isSelectAll: isStatusSelectAll, listData: statusList },
    severityLevel: { isSelectAll: isSeverityLevelSelectAll, listData: severityLevelList },
    frequency: { isSelectAll: isFrequencySelectAll, listData: frequencyList },
  } = useAppSelector(photoIntelligenceSelector);

  const listAllToggle = [
    {
      key: 1,
      label: t('trans.status'),
      isOpen: isOpenStatus,
      setIsOpen: setIsOpenStatus,
      isSelectAll: isStatusSelectAll,
      onChangeAll: changeIsSelectAllStatus(!isStatusSelectAll),
      list: statusList.map((item) => ({ ...item, label: t(item.label) })),
      onChangeToggle: changeStatusToggle,
    },
    {
      key: 2,
      label: t('trans.severity_level'),
      isOpen: isOpenSeverityLevel,
      setIsOpen: setIsOpenSeverityLevel,
      isSelectAll: isSeverityLevelSelectAll,
      onChangeAll: changeIsSelectAllSeverityLevel(!isSeverityLevelSelectAll),
      list: severityLevelList.map((item) => ({ ...item, label: t(item.label) })),
      onChangeToggle: changeSeverityLevelToggle,
    },
    {
      key: 3,
      label: t('trans.frequency'),
      isOpen: isOpenFrequency,
      setIsOpen: setIsOpenFrequency,
      isSelectAll: isFrequencySelectAll,
      onChangeAll: changeIsSelectAllFrequency(!isFrequencySelectAll),
      list: frequencyList.map((item) => ({ ...item, label: t(item.label) })),
      onChangeToggle: changeFrequencyToggle,
    },
  ];

  return (
    <>
      {listAllToggle.map((area) => (
        <CommonCollapse label={area.label} isOpen={area.isOpen} setIsOpen={area.setIsOpen} key={area.key}>
          <Stack>
            <FormControlLabel
              sx={{ ml: 0.5 }}
              control={<SwitchCustom checked={area.isSelectAll} onChange={() => dispatch(area.onChangeAll)} />}
              label={t('trans.select_all')}
            />
            <Grid container>
              {area.list.map((item) => (
                <Grid item xs={6} key={item.key}>
                  <FormControlLabel
                    sx={{ ml: 0.5 }}
                    control={
                      <SwitchCustom
                        checked={item.value}
                        onChange={() => dispatch(area.onChangeToggle({ key: item.key, value: !item.value }))}
                      />
                    }
                    label={item.label}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>
        </CommonCollapse>
      ))}
    </>
  );
};

export default ToggleArea;
