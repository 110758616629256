import { Box, Button } from '@mui/material';
import { AxiosResponse } from 'axios';
import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import CommonModal from 'components/Common/CommonModal';
import { QUERY_KEY } from 'constants/constants';
import { IPhotoIntelligenceData, s3BaseUrl } from 'constants/photoIntelligence';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { useQuery } from 'react-query';
import { sendGetDataAnalysisByLevelIdDate } from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeIsShowVideoViewModal } from 'store/slices/map-view/photoIntelligenceAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';

interface UploadedData {
  fileType: string;
  path: string;
}

interface ApiDataItem {
  name: string;
  uploaded: UploadedData[];
}

interface ImageViewModalProps {
  data: IPhotoIntelligenceData | undefined;
}

const VideoViewModal: FC<ImageViewModalProps> = ({ data }) => {
  const { t } = useTranslation();
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  const dispatch = useAppDispatch();
  const { kpName, videoMetadata } = data || {};
  const { hour = '00', minute = '00', second = '00' } = videoMetadata || {};
  const { dateAnalyticSelected, levelId } = useAppSelector(mapViewSelector);
  const playerRef = useRef<ReactPlayer>(null);

  const formattedTimeStamp = `${hour}:${minute}:${second}`;
  const convertTimeStampToSeconds = (timeStamp: string): number => {
    const [hours, minutes, seconds] = timeStamp.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };
  const startTimeInSeconds = convertTimeStampToSeconds(formattedTimeStamp);

  const { data: apiResponse } = useQuery<AxiosResponse<ApiDataItem[]>>(
    [QUERY_KEY.DATA_ANALYSIS, levelId],
    () => sendGetDataAnalysisByLevelIdDate(levelId || '', dateAnalyticSelected || ''),
    { enabled: !!dateAnalyticSelected && !!levelId }
  );

  const videoUrl = apiResponse?.data
    .find((item: ApiDataItem) => item.name === TYPE_ANALYTICS_MAP_VIEW.PHOTO_INTELLIGENCE)
    ?.uploaded.find((upload: UploadedData) => upload.fileType === 'VIDEO')?.path;

  const fullVideoUrl = videoUrl ? `${s3BaseUrl}${videoUrl}` : '';

  const handleDownload = () => {
    if (fullVideoUrl) {
      const link = document.createElement('a');
      link.href = fullVideoUrl;
      link.download = 'video.mp4';
      link.click();
    }
  };

  useEffect(() => {
    if (isReady && playerRef.current) {
      playerRef.current.seekTo(startTimeInSeconds, 'seconds');
      setIsLoading(false);
    }
  }, [isReady, startTimeInSeconds]);

  return (
    <CommonModal
      style={{ height: '700px', width: '900px', padding: '20px' }}
      isShow={true}
      handleClose={() => dispatch(changeIsShowVideoViewModal({ id: '', isShow: false }))}>
      <Box mb={2}>{kpName}</Box>
      <Box style={{ position: 'absolute', top: '30px', right: '10px' }}>
        <Button variant="text" sx={{ textDecoration: 'underline' }} onClick={handleDownload}>
          {t('trans.download')}
        </Button>
      </Box>
      <Box style={{ position: 'relative' }}>
        <ReactPlayer
          ref={playerRef}
          url={fullVideoUrl}
          width="100%"
          height="auto"
          controls
          style={{ marginBottom: '30px', marginTop: '40px' }}
          onReady={() => setIsReady(true)}
          onBuffer={() => setIsLoading(true)}
          onBufferEnd={() => setIsLoading(false)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
        {isLoading && isHovered && (
          <Box
            className="loading-overlay"
            style={{
              position: 'absolute',
              top: '89.1%',
              left: '2.8%',
              transform: 'translate(-50%, -50%)',
              zIndex: 10,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              borderRadius: '50%',
              pointerEvents: 'none',
            }}>
            <Box
              sx={{
                border: '4px solid rgba(255, 255, 255, 0.3)',
                borderTop: '4px solid #3498db',
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                animation: 'spin 1s linear infinite',
                '@keyframes spin': {
                  '0%': {
                    transform: 'rotate(0deg)',
                  },
                  '100%': {
                    transform: 'rotate(360deg)',
                  },
                },
              }}
            />
          </Box>
        )}
      </Box>
      <Box mb={2}>
        {t('trans.time_stamp')} {formattedTimeStamp}
      </Box>
    </CommonModal>
  );
};

export default VideoViewModal;
