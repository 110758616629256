import { Box, Button, Typography, useTheme } from '@mui/material';
import ExcelButton from 'components/Common/ExcelButton';
import { saveAs } from 'file-saver';
import useCheckingEnableAllLevels from 'hooks/common/useCheckingEnableAllLevels';
import { useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { exportExcelAnalyticsTab } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { AnalyticsTab } from '..';
import AnalyticsHigherLevels from '../../AnalyticsHigherLevels';

const TraditionalAnalytics = () => {
  const { clientId } = useParams();
  const { levelId, cropType } = useAppSelector(mapViewSelector);
  const [isShowMoreBtn, setIsShowMoreBtn] = useState(false);
  const scrollBlockRef = useRef() as any;
  const { t } = useTranslation();
  const theme = useTheme();
  const { isEnableAnalyticAllLevels } = useCheckingEnableAllLevels();

  const exportExcelMutation = useMutation(exportExcelAnalyticsTab, {
    onSuccess: async (res) => {
      saveAs(res.request.responseURL, 'Analytics.xlsx');
      toast.success('Download file successful');
    },
  });

  return (
    <>
      {isEnableAnalyticAllLevels ? (
        <Box
          sx={{
            width: '100%',
            height: '80vh',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <Scrollbars style={{ width: '100%', height: '95%' }} autoHide>
            <AnalyticsTab />
          </Scrollbars>
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '80vh',
          }}>
          <Scrollbars
            ref={scrollBlockRef}
            style={{ width: '100%', height: '100%' }}
            autoHide
            onScrollFrame={(values: any) => {
              if (values.top === 1 || values.top === 0) {
                setIsShowMoreBtn(false);
              } else {
                setIsShowMoreBtn(true);
              }
            }}>
            <AnalyticsTab />
            <AnalyticsHigherLevels />
            <Box sx={{ textAlign: 'center', mt: '16px', mb: '24px' }}>
              {/* Inside directly analytics tab */}
              <ExcelButton
                onClick={() => exportExcelMutation.mutate({ levelId: levelId || null, clientId: clientId!, cropType })}
                disabled={exportExcelMutation.isLoading}
                isLoading={exportExcelMutation.isLoading}
              />
            </Box>
            <Box
              sx={{
                display: isShowMoreBtn ? 'block' : 'none',
                width: '100%',
                height: '40px',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                backgroundColor: 'transparent',
                position: 'fixed',
                bottom: '60px',
                transition: 'all 0.3s ease',
                marginLeft: 0,
                marginRight: 0,
                left: 0,
                right: 0,
              }}>
              <Button
                sx={{
                  maxWidth: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: (theme) => theme.palette.background.default,
                  borderRadius: '5px',
                  padding: '5px',
                  border: `1px solid ${theme.palette.primary.main}`,
                  boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.5)',
                  margin: 'auto',
                  '&:hover': {
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: '#fff',
                  },
                }}
                onClick={() => {
                  scrollBlockRef.current.view.scroll({
                    top: 10000,
                    behavior: 'smooth',
                  });
                }}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    width: '200px',
                  }}>
                  {t('trans.more')}
                </Typography>
              </Button>
            </Box>
          </Scrollbars>
        </Box>
      )}
    </>
  );
};

export default TraditionalAnalytics;
