import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clientSelector } from 'store/slices/clientSlice';
import { changeIsShowBottomBar, mapViewSelector } from 'store/slices/mapViewSlice';
import { CropManagement } from '../CropManagement';
import TraditionalAnalytics from '../TraditionalAnalytics';

enum AnalyticsTabModeEnum {
  ERMS = 'ERMS',
  TRADITION = 'Intelligent',
}

const AnalyticsTabContent = () => {
  const [analyticsTabMode, setAnalyticsTabMode] = useState<AnalyticsTabModeEnum>(AnalyticsTabModeEnum.TRADITION);
  const { selectedLevelHierarchyList, levelId } = useAppSelector(mapViewSelector);
  const { clientData } = useAppSelector(clientSelector);
  const { enableCropManagement } = clientData || {};
  const dispatch = useAppDispatch();

  // show bottom bar when in Erms mode
  useEffect(() => {
    dispatch(changeIsShowBottomBar(analyticsTabMode === AnalyticsTabModeEnum.ERMS));
  }, [analyticsTabMode, dispatch, levelId]);

  const isEnabledErms = selectedLevelHierarchyList?.length && enableCropManagement;

  const handleChange = (event: any) => {
    setAnalyticsTabMode(event.target.value);
  };

  return (
    <>
      <Box>
        <RadioGroup
          row
          aria-label="analyticsMode"
          name="analyticsMode"
          value={analyticsTabMode}
          onChange={handleChange}>
          <FormControlLabel
            value={AnalyticsTabModeEnum.ERMS}
            control={<Radio disabled={!isEnabledErms} />}
            label={AnalyticsTabModeEnum.ERMS}
          />
          <FormControlLabel
            value={AnalyticsTabModeEnum.TRADITION}
            control={<Radio />}
            label={AnalyticsTabModeEnum.TRADITION}
          />
        </RadioGroup>
      </Box>
      {analyticsTabMode === AnalyticsTabModeEnum.TRADITION ? <TraditionalAnalytics /> : <CropManagement />}
    </>
  );
};

export default AnalyticsTabContent;
