import { LayerTypeEnum } from 'common/defines/constants';
import { getBoundsForPoints } from 'common/utils/util';
import { DEFAULT_VIEWPORT } from 'constants/constants';
import useCheckingEnableAllLevels from 'hooks/common/useCheckingEnableAllLevels';
import { get, isEmpty } from 'lodash';
import { FC, MutableRefObject, useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import PolygonLayer from './PolygonLayer';

interface PolygonsLastLevelProps {
  dataMapViewLastLevel: any;
  containerMapRef: MutableRefObject<any>;
  mapRef: MutableRefObject<any>;
}

const PolygonsLastLevel: FC<PolygonsLastLevelProps> = ({ containerMapRef, dataMapViewLastLevel, mapRef }) => {
  const { isEnableAnalyticAllLevels } = useCheckingEnableAllLevels();
  const { layerTypeCurrentList } = useAppSelector(mapViewSelector);

  useEffect(() => {
    let allPointList = [];
    const location = dataMapViewLastLevel.data?.location;
    const geometryType = get(location, 'features[0].geometry.type');

    if (geometryType === 'MultiPolygon') {
      const coordinateList = get(location, 'features[0].geometry.coordinates');
      allPointList = coordinateList.flat(2);
    } else if (geometryType === 'Polygon') {
      const coordinates = get(location, 'features[0].geometry.coordinates');
      allPointList = coordinates.flat();
    }

    const calculatorViewport = (pointsPolygon: any[], mapRefCurrent: any) => {
      try {
        if (mapRefCurrent.current && !isEmpty(pointsPolygon)) {
          return getBoundsForPoints(
            pointsPolygon,
            mapRefCurrent.current.clientWidth,
            mapRefCurrent.current.clientHeight
          );
        }
      } catch (error) {
        console.log(error);
      }

      return DEFAULT_VIEWPORT;
    };
    const pointCenter = calculatorViewport(allPointList, containerMapRef);
    mapRef.current?.flyTo({
      center: [pointCenter.longitude, pointCenter.latitude],
      zoom: pointCenter.zoom,
      essential: true,
      speed: 1.0,
      curve: 1,
    });
  }, [containerMapRef, dataMapViewLastLevel.data, mapRef]);

  const hasOrthoimageLayer = layerTypeCurrentList.some((layer) => layer.layerType === LayerTypeEnum.RGB_ORTHOIMAGE);

  if (!dataMapViewLastLevel?.data || !isEnableAnalyticAllLevels) {
    return <></>;
  }

  return (
    <PolygonLayer
      item={dataMapViewLastLevel.data}
      isOnTopOfOrthoimage={hasOrthoimageLayer}
      isLastLevelId
      mapRef={mapRef}
    />
  );
};

export default PolygonsLastLevel;
