import { Box } from '@mui/material';
import { forwardRef } from 'react';

interface CircleBoxProps {
  color: string;
  label: number;
  isCollapseSpace?: boolean;
}

const CircleBox = forwardRef<HTMLDivElement, CircleBoxProps>((props, ref) => {
  const { label, color, isCollapseSpace = false } = props;
  return label ? (
    <Box
      ref={ref}
      {...props}
      sx={{
        width: 20,
        height: 20,
        borderRadius: '50%',
        backgroundColor: color,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'black',
        fontSize: '12px',
        fontWeight: 'bold',
      }}>
      {label}
    </Box>
  ) : isCollapseSpace ? null : (
    <Box ref={ref} {...props} sx={{ width: 20, height: 20 }}></Box>
  );
});

export default CircleBox;
