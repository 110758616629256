import AutorenewIcon from '@mui/icons-material/Autorenew';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Avatar, Box, Button, IconButton, TextField, Typography, useTheme } from '@mui/material';
import CommonModal from 'components/Common/CommonModal';
import { QUERY_KEY } from 'constants/constants';
import { IPhotoIntelligenceComment } from 'constants/photoIntelligence';
import { t } from 'i18next';
import PopupState from 'material-ui-popup-state';
import { ChangeEvent, FC, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { createPhotoIntelligenceComment } from 'services/clients/apiClient.services';
import { uploadMedia } from 'services/media';

interface DocumentUploadModalProps {
  _id: string | undefined;
  setListComments: (arg: IPhotoIntelligenceComment[]) => void;
  setIsShowUploadDocumentModal: (arg: boolean) => void;
  handleClose: () => void;
}

const DocumentUploadModal: FC<DocumentUploadModalProps> = ({
  _id,
  handleClose,
  setListComments,
  setIsShowUploadDocumentModal,
}) => {
  const theme = useTheme();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileType, setFileType] = useState<'photo' | 'document' | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [newComment, setNewComment] = useState<string>('');
  const queryClient = useQueryClient();

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    setNewComment(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendComment();
    }
  };

  const createCommentMutation = useMutation(createPhotoIntelligenceComment, {
    onSuccess: () => {
      setNewComment('');
      setListComments([]);
      setIsShowUploadDocumentModal(false);
      toast.success('Create new message successful');
      queryClient.invalidateQueries([QUERY_KEY.PHOTO_INTELLIGENCE_COMMENT_LIST]);
    },
    onError: (error) => {
      console.error('Error creating comment:', error);
    },
  });

  const handleSendComment = async () => {
    if (_id && selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      try {
        const response = await uploadMedia(formData);
        const s3Url = response.data.path;
        await createCommentMutation.mutateAsync({
          photoIntelligenceId: _id,
          content: newComment,
          media: s3Url,
        });
        setNewComment('');
        setSelectedFile(null);
      } catch (error) {
        console.error('Error uploading media or creating comment:', error);
      }
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setNewComment('');
      if (file.type.startsWith('image/')) {
        setFileType('photo');
        setPreviewUrl(URL.createObjectURL(file));
      } else {
        setFileType('document');
        setPreviewUrl(null);
      }
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
    setNewComment('');
  };

  const renderFileActions = () => {
    const topPosition = fileType === 'photo' ? 0 : 40;
    return (
      <Box display="flex" position="absolute" top={topPosition} right={0}>
        <IconButton onClick={handleRemoveFile} color="error" size="small">
          <DeleteOutlineIcon />
        </IconButton>
        <input
          accept="image/*,application/pdf"
          style={{ display: 'none' }}
          id="change-file"
          type="file"
          onChange={handleFileUpload}
        />
        <label htmlFor="change-file">
          <IconButton color="primary" size="small" component="span">
            <AutorenewIcon />
          </IconButton>
        </label>
      </Box>
    );
  };

  const renderFileContent = () => {
    if (fileType === 'photo' && previewUrl) {
      return (
        <Box position="relative" mt={2}>
          <img src={previewUrl} alt="view" style={{ maxWidth: '100%', maxHeight: '300px' }} />
          {renderFileActions()}
        </Box>
      );
    }

    if (fileType === 'document') {
      const truncateMiddle = (str: string | any[], maxLength: number) => {
        if (str.length <= maxLength) return str;
        const start = str.slice(0, Math.ceil(maxLength / 2));
        const end = str.slice(-Math.floor(maxLength / 2));
        return `${start}...${end}`;
      };
      const fileName = selectedFile?.name;
      const truncatedFileName = fileName ? truncateMiddle(fileName, 30) : '';
      return (
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} width="100%">
          <Box display="flex" alignItems="center">
            <Avatar sx={{ width: 48, height: 48, mr: 1 }}>
              <InsertDriveFileIcon sx={{ color: 'white' }} />
            </Avatar>
            <Box display="flex" flexDirection="column">
              <Typography variant="body1" fontWeight="bold">
                {truncatedFileName}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {selectedFile && selectedFile.size ? `${(selectedFile.size / 1024).toFixed(1)} KB` : 'Unknown size'}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" gap={1}>
            {renderFileActions()}
          </Box>
        </Box>
      );
    }
    return null;
  };

  return (
    <>
      <PopupState variant="popover" popupId="file-upload-popup">
        {(popupState) => (
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Box
              marginTop="36px"
              marginRight="80px"
              display="flex"
              flexDirection="column"
              border="1px solid #ccc"
              borderRadius={2}
              position="absolute"
              bottom="50px"
              left="8px"
              style={{ background: '#24292e' }}>
              <input
                accept="image/*, video/*"
                style={{ display: 'none' }}
                id="upload-photo"
                type="file"
                onChange={(event) => {
                  popupState.close();
                  handleFileUpload(event);
                }}
              />
              <label htmlFor="upload-photo">
                <Button
                  component="span"
                  style={{ color: theme.palette.color.grey2 }}
                  startIcon={<InsertPhotoIcon />}
                  sx={{ justifyContent: 'flex-start', width: '150px' }}>
                  Image
                </Button>
              </label>

              <input
                accept="application/pdf"
                style={{ display: 'none' }}
                id="upload-document"
                type="file"
                onChange={(event) => {
                  popupState.close();
                  handleFileUpload(event);
                }}
              />
              <label htmlFor="upload-document">
                <Button
                  style={{ color: theme.palette.color.grey2 }}
                  component="span"
                  startIcon={<UploadFileIcon />}
                  sx={{ justifyContent: 'flex-start', width: '150px' }}>
                  Document
                </Button>
              </label>
            </Box>
          </Box>
        )}
      </PopupState>
      {selectedFile && (
        <CommonModal isShow={true} handleClose={handleClose} style={{ width: '400px', padding: '20px' }}>
          <Box display="flex" flexDirection="column" alignItems="center" position="relative" style={{ gap: '10px' }}>
            <Typography>{fileType === 'photo' ? `Send an image: ${selectedFile.name}` : 'Send as a file'}</Typography>

            {renderFileContent()}

            <TextField
              type="text"
              placeholder={t('trans.type_new_messages')}
              style={{ flex: 1 }}
              value={newComment}
              onChange={(e) => handleChangeInput(e)}
              onKeyDown={handleKeyDown}
              minRows={1}
              maxRows={3}
              size="small"
              fullWidth
              multiline
              InputProps={{
                sx: {
                  '& fieldset': {
                    border: '1px solid',
                  },
                },
              }}
            />

            <Button onClick={handleSendComment} variant="contained" sx={{ mt: 2 }}>
              Send
            </Button>
          </Box>
        </CommonModal>
      )}
    </>
  );
};

export default DocumentUploadModal;
