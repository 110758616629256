import { Box, Button, Grid, InputLabel } from '@mui/material';
import { useAnalyticCardTabStyle } from '../../MapViewStyle';

export const AnalyticCardTab = ({ icon, label, isSelected, onSelect, isAnalyticActive }: any) => {
  const classes = useAnalyticCardTabStyle();

  const renderClassButton = () => {
    if (isAnalyticActive) {
      return isSelected ? classes.buttonStyleActive : classes.buttonStyle;
    } else {
      return classes.buttonStyleDisabled;
    }
  };

  return (
    <Grid
      direction="column"
      container
      alignItems="center"
      justifyContent="center"
      spacing={0}
      width="fit-content"
      sx={{
        mb: '16px',
      }}>
      <Button
        onClick={() => {
          if (isAnalyticActive) {
            onSelect();
          }
        }}
        className={renderClassButton()}>
        <Box>
          {icon}
          <Box className={classes.labelBox}>
            <InputLabel
              sx={{ textTransform: 'capitalize' }}
              className={isSelected ? classes.labelTextActive : classes.labelText}>
              {label}
            </InputLabel>
          </Box>
        </Box>
      </Button>
    </Grid>
  );
};
