import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Box, Button, Collapse, Typography, useTheme } from '@mui/material';
import { useBoxLayout, useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { IPhotoIntelligenceData, LockColor, PhotoIntelligenceStatusEnum } from 'constants/photoIntelligence';
import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeDetailBarList,
  changeSelectedKpInfo,
  photoIntelligenceSelector,
} from 'store/slices/map-view/photoIntelligenceAnalytics';
import AssociatedKps from './AssociatedKps';
import CommentView from './CommentView';
import ImageView from './ImageView';
import SeverityLevel from './SeverityLevel';
import SwitchStatus from './SwitchStatus';
import TableInfo from './TableInfo';
import VideoView from './VideoView';

interface PhotoIntelligenceBarProps {
  data: IPhotoIntelligenceData;
}

const PhotoIntelligenceBar: FC<PhotoIntelligenceBarProps> = ({ data }) => {
  const { status, kpName, _id, lat, lon } = data || {};

  const { barDetailList } = useAppSelector(photoIntelligenceSelector);
  const selectedBarDetail = barDetailList?.find((item) => item.id === _id);
  const { isOpenCollapse } = selectedBarDetail || {};
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const classes = useLayerSelectCardStyle();
  const classes2 = useBoxLayout();

  const handleClickCollapse = () => {
    const newBarDetailList = barDetailList.map((item) =>
      item.id === _id ? { ...item, isOpenCollapse: !isOpenCollapse } : { ...item, isOpenCollapse: false }
    );
    dispatch(changeDetailBarList(newBarDetailList));
  };

  const handleClickKpName = () => {
    dispatch(changeSelectedKpInfo({ _id, lat, lon, isDone: false }));
  };

  return (
    <Box
      id={`photo_bar_${_id}`}
      className={classes2.boxLayout}
      style={{ padding: '10px 20px', borderRadius: '20px', marginBottom: '10px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          sx={{
            fontSize: '17px',
            width: '100px',
            color: theme.palette.color.grey2,
            cursor: 'pointer',
            '&:hover': {
              color: theme.palette.primary.main,
            },
          }}
          onClick={handleClickKpName}>
          {kpName}
        </Typography>

        <SeverityLevel data={data} />

        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          {status === PhotoIntelligenceStatusEnum.OPEN ? (
            <LockOpenIcon fontSize="medium" sx={{ color: LockColor[status] }} />
          ) : (
            <LockOutlinedIcon fontSize="medium" sx={{ color: LockColor[status] }} />
          )}
          <ImageView id={_id} />
          <VideoView id={_id} />
          <CommentView id={_id} />
        </Box>
        <Button
          endIcon={
            isOpenCollapse ? (
              <ExpandMoreIcon style={{ fontWeight: 'bold' }} />
            ) : (
              <ChevronRightIcon style={{ fontWeight: 'bold' }} />
            )
          }
          onClick={() => handleClickCollapse()}
          classes={{ root: classes.buttonTextStyle }}
          sx={{ flexGrow: 0 }}
        />
      </Box>
      <Collapse in={isOpenCollapse} timeout="auto" unmountOnExit sx={{ px: 1.5 }}>
        <TableInfo data={data} />
        <SwitchStatus data={data} />
        <AssociatedKps data={data} />
      </Collapse>
    </Box>
  );
};

export default PhotoIntelligenceBar;
