import { center, centerOfMass, distance, point } from '@turf/turf';
import { FORMAT_DATE } from 'constants/constants';
import { isEmpty } from 'lodash';
import moment from 'moment';
import randomColor from 'randomcolor';
import SunCalc from 'suncalc';
import WebMercatorViewport from 'viewport-mercator-project';

export const GeneratorColor = () => {
  return randomColor({ luminosity: 'dark' });
};

export const applyToArray = (func: any, array: any) => func.apply(Math, array);

export const getBoundsForPoints = (points: any, width: number, height: number) => {
  // Calculate corner values of bounds
  const pointsLong = points.map((point: any) => point[0]);
  const pointsLat = points.map((point: any) => point[1]);
  const cornersLongLat = [
    [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
    [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
  ] as [[number, number], [number, number]];
  const viewportA = new WebMercatorViewport({ width, height }).fitBounds(cornersLongLat, { padding: 200 });
  const { longitude, latitude, zoom } = viewportA;

  return { longitude, latitude, zoom };
};

export const getCurrentLevel = (levelList: any, id: string): any => {
  if (isEmpty(levelList)) {
    return null;
  }

  for (const level of levelList) {
    if (level._id === id) {
      return level;
    }
    const currentLevel = getCurrentLevel(level.children, id);
    if (currentLevel) {
      return currentLevel;
    }
  }
};

export const getSunPosition = (latitude: number, longitude: number) => {
  const sunPos = SunCalc.getPosition(new Date(), latitude, longitude);
  const sunAzimuth = 180 + (sunPos.azimuth * 180) / Math.PI;
  const sunAltitude = 90 - (sunPos.altitude * 180) / Math.PI;
  return [sunAzimuth, sunAltitude];
};

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : {
        r: 0,
        g: 0,
        b: 0,
      };
};

export const generatorPointsParabola = (
  pointCenters: Array<any>,
  pointLocationSelect: { longitude: number; latitude: number; id: string },
  pointCenterSelect: string
): Array<any> => {
  let arcsPoints: any = [];
  pointCenters.forEach((p) => {
    if (p.id !== pointCenterSelect) {
      let line = [];
      const from = point([pointLocationSelect.longitude, pointLocationSelect.latitude]);
      const to = point([p.longitude, p.latitude]);
      for (let i = 0; i < 1; i += 0.01) {
        const x = pointLocationSelect.longitude + (p.longitude - pointLocationSelect.longitude) * i;
        const y = pointLocationSelect.latitude + (p.latitude - pointLocationSelect.latitude) * i;
        const z = distance(from, to) * 800 * Math.sin(Math.PI * i);
        line.push([x, y, z]);
      }
      line.push([p.longitude, p.latitude, 0]);
      arcsPoints.push(line);
    }
  });
  return arcsPoints;
};

export const rangeDivider = (min: number, max: number, intervals: number): any => {
  const dividedArray = [];
  const markers = [];
  const range = max - min;
  const equallyDividend = range / intervals;
  for (let chunk = 0; chunk < intervals; chunk++) {
    if (chunk) {
      markers.push(min + chunk * equallyDividend);
    }
    dividedArray.push({
      from: chunk === 0 ? min : (min + chunk * equallyDividend).toFixed(3),
      to: chunk === intervals - 1 ? max : (min + (chunk + 1) * equallyDividend).toFixed(3),
      color: randomColor(),
      lable: `Label: ${chunk + 1}`,
    });
  }
  return [markers, dividedArray];
};

export function readFileAsync(file: any, asText: boolean = false) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      resolve(reader.result as any);
    };

    reader.onerror = reject;
    if (asText) {
      reader.readAsText(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  });
}

export const str2ab = (str: any) => {
  let buf = new ArrayBuffer(str.length);
  let bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
};

export const sizeByZoomLevel = (zoomLevel: number) => {
  return 1 / (300 + (1 / 10 ** 15.15) * Math.exp(2.3 * zoomLevel)) + 0.0003;
  // sigmoid function with min = 0.0003, max = 0.0008, and x = zoomLevel
};

export const findCenterOfGeometry = (geometry: { coordinates: any[]; type: string }): number[] => {
  const { coordinates, type } = geometry;
  switch (type) {
    case 'LineString': {
      return center(geometry).geometry.coordinates;
    }
    case 'Polygon': {
      return centerOfMass(geometry).geometry.coordinates;
    }
    case 'Point':
    default:
      return coordinates;
  }
};

// flat object lib:
// https://www.npmjs.com/package/flat?activeTab=code

function isBuffer(obj: any) {
  return obj && obj.constructor && typeof obj.constructor.isBuffer === 'function' && obj.constructor.isBuffer(obj);
}

function keyIdentity(key: any) {
  return key;
}

export const flatten = (target?: any, opts?: any) => {
  opts = opts || {};

  const delimiter = opts.delimiter || '.';
  const maxDepth = opts.maxDepth;
  const transformKey = opts.transformKey || keyIdentity;
  const output: any = {};

  function step(object?: any, prev?: any, currentDepth?: any) {
    currentDepth = currentDepth || 1;
    Object.keys(object).forEach(function (key) {
      const value = object[key];
      const isarray = opts.safe && Array.isArray(value);
      const type = Object.prototype.toString.call(value);
      const isbuffer = isBuffer(value);
      const isobject = type === '[object Object]' || type === '[object Array]';

      const newKey = prev ? prev + delimiter + transformKey(key) : transformKey(key);

      if (
        !isarray &&
        !isbuffer &&
        isobject &&
        Object.keys(value).length &&
        (!opts.maxDepth || currentDepth < maxDepth)
      ) {
        return step(value, newKey, currentDepth + 1);
      }
      output[newKey] = value;
    });
  }
  step(target);
  return output;
};

// end flat object lib

export const setValueByDynamicPath = (obj: any, path: Array<string>, newValue: {}) => {
  let currentObj = obj;

  for (let i = 0; i < path.length - 1; i++) {
    const key = path[i];
    currentObj = currentObj[key];
  }

  const lastKey = path[path.length - 1];
  currentObj[lastKey] = newValue;
};

export const interLeaveItemInArray = (arr: number[], x: string) => arr.flatMap((e) => [`${e}`, x]);

export const getFromDateAndToDate = (month: number, year: number) => {
  //month starts from 0-index
  const startDate = moment(`${year}-${month + 1}-01`).format(FORMAT_DATE);
  const endDate = moment(`${month === 11 ? year + 1 : year}-${month === 11 ? 1 : month + 2}-01`)
    .subtract(1, 'day')
    .format(FORMAT_DATE);

  return { startDate, endDate };
};

export const getDayFromDate = (date: string) => Number(moment(date).format('DD'));
