import { Grid, styled, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { QUERY_KEY } from 'constants/constants';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { getUIDisplay, queryMe } from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { changeDrawIssueShape, rightBarSelector } from 'store/slices/rightBarSlice';
import { TabPanel } from '../TabPanel';
import AnalyticsTabContent from './AnalyticsTab/AnalyticsTabContent';
import InformationTab from './InfomationTab';
import IssuesTab from './IssuesTab';

const a11yProps = (index: number) => {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
};

const TabStyled = styled(Tab)(() => ({
  textTransform: 'unset',
  color: '#CACED3',
  fontWeight: 500,
  fontSize: '12px',
  '&.Mui-selected': {
    color: '#3C4043',
  },
}));

export const RightBar = () => {
  const [tabNumber, setTabNumber] = useState<number>(0);
  const { clientId } = useParams();
  const { levelId } = useAppSelector(mapViewSelector);
  const { data: userInfo } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe());
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const tabMenu = useMemo(
    () => [
      {
        id: 0,
        label: t('mapView.analytics'),
        value: 'Analytics',
        disabled: false,
      },
      {
        id: 1,
        label: t('mapView.information'),
        value: 'Information',
        disabled: false,
      },
      {
        id: 2,
        label: t('mapView.issues'),
        value: 'Issues',
        disabled: false,
      },
      {
        id: 3,
        label: t('mapView.flight_mission'),
        value: 'Flight Mission',
        disabled: false,
      },
      {
        id: 4,
        label: t('mapView.flight_log'),
        value: 'Flight Log',
        disabled: false,
      },
    ],
    [t]
  );

  const {
    issuesTab: { drawIssueShape },
  } = useAppSelector(rightBarSelector);

  useEffect(() => {
    if (tabNumber !== 2) {
      // 2 as tab "Issues"
      dispatch(changeDrawIssueShape({ ...drawIssueShape, isShow: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabNumber]);

  const userId = useMemo(() => {
    if (!userInfo) return '';
    return userInfo.data._id;
  }, [userInfo]);

  const { data: userInfoByClient } = useQuery(
    [QUERY_KEY.USER_INFO_BY_CLIENT, clientId, userId],
    () => getUIDisplay(clientId!, userId),
    {
      enabled: !!clientId && userId !== '' && !userInfo?.data?.isSuperAdmin && !userInfo?.data?.isSuperUser,
    }
  );

  const flagTabData = useMemo(() => {
    if (userInfo?.data?.isSuperAdmin || userInfo?.data?.isSuperUser) {
      return tabMenu;
    } else {
      return userInfoByClient?.data?.flagTab;
    }
  }, [tabMenu, userInfo?.data?.isSuperAdmin, userInfo?.data?.isSuperUser, userInfoByClient?.data?.flagTab]);

  const newTabMenu = useMemo(() => {
    const newTabMenuData: any[] = [];
    if (userInfo?.data?.isSuperAdmin || userInfo?.data?.isSuperUser) {
      return flagTabData;
    } else {
      tabMenu.forEach((item: any) => {
        switch (item.value) {
          case 'Analytics':
            if (flagTabData.includes('MAPVIEW-ANALYSIS')) {
              newTabMenuData.push(item);
              return item;
            }
            break;
          case 'Information':
            if (flagTabData.includes('MAPVIEW-INFORMATION')) {
              newTabMenuData.push(item);
              return item;
            }
            break;
          case 'Issues': {
            if (flagTabData.includes('MAPVIEW-MANAGE-ISSUE')) {
              newTabMenuData.push(item);
              return item;
            }
            break;
          }
          case 'Flight Mission':
            if (flagTabData.includes('MAPVIEW-DRONE-OPERATION')) {
              newTabMenuData.push(item);
              return item;
            }
            break;
          case 'Flight Log':
            if (flagTabData.includes('MAPVIEW-MEASUREMENT')) {
              newTabMenuData.push(item);
              return item;
            }
            break;
          default:
            break;
        }
      });
      return newTabMenuData;
    }
  }, [flagTabData, tabMenu, userInfo?.data?.isSuperAdmin, userInfo?.data?.isSuperUser]);

  useLayoutEffect(() => {
    setTabNumber(0);
  }, [levelId]);

  const theme = useTheme();

  return (
    <Grid
      container
      direction="column"
      sx={{
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#22263199' : theme.palette.background.default),
        height: 'fit-content',
        width: '100%',
      }}>
      <Grid direction="row" width="100%" height="100%">
        <Grid direction="row">
          <Tabs
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#22263199' : theme.palette.background.default,
              borderBottom: `1px solid ${theme.palette.divider}`,
              padding: '0px',
              margin: '0px',
            }}
            value={tabNumber}
            children={newTabMenu.map((item: any, index: number) => {
              return (
                <TabStyled
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    maxWidth: '90px',
                    '&.Mui-selected': {
                      color: (theme) => theme.palette.primary.main,
                    },
                  }}
                  key={item.id}
                  label={item.label}
                  {...a11yProps(item.id)}
                  onClick={() => {
                    setTabNumber(item.id);
                  }}
                  value={item.id}
                />
              );
            })}
          />
        </Grid>
        <Grid direction="row">
          <SwipeableViews index={tabNumber}>
            <TabPanel value={tabNumber} index={0}>
              <AnalyticsTabContent />
            </TabPanel>
            <TabPanel value={tabNumber} index={1}>
              <InformationTab />
            </TabPanel>
            <TabPanel value={tabNumber} index={2}>
              <IssuesTab />
            </TabPanel>
            <TabPanel value={tabNumber} index={3}>
              <Typography sx={{ p: 3, pt: 1 }}>{t('mapView.flight_mission')}</Typography>
            </TabPanel>
            <TabPanel value={tabNumber} index={4}>
              <Typography sx={{ p: 3, pt: 1 }}>{t('mapView.flight_log')}</Typography>
            </TabPanel>
          </SwipeableViews>
        </Grid>
      </Grid>
    </Grid>
  );
};
