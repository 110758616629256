import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Collapse, Typography } from '@mui/material';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { FC, ReactNode } from 'react';

interface CommonCollapseProps {
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  children: ReactNode;
  label: string;
}

const CommonCollapse: FC<CommonCollapseProps> = ({ isOpen, setIsOpen, children, label }) => {
  const classes = useLayerSelectCardStyle();

  return (
    <>
      <Button
        startIcon={
          isOpen ? (
            <ExpandMoreIcon style={{ fontWeight: 'bold' }} />
          ) : (
            <ChevronRightIcon style={{ fontWeight: 'bold' }} />
          )
        }
        onClick={() => setIsOpen(!isOpen)}
        classes={{ root: classes.buttonTextStyle }}
        style={{ width: '100%' }}>
        <Typography
          className={classes.buttonTextStyle}
          style={{ marginRight: 'auto', fontWeight: 'bold', fontSize: '13px !important' }}>
          {label}
        </Typography>
      </Button>
      <Collapse in={isOpen} timeout="auto" unmountOnExit sx={{ px: 1.5 }}>
        {children}
      </Collapse>
    </>
  );
};

export default CommonCollapse;
