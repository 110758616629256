import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, TextField, useTheme } from '@mui/material';
import { QUERY_KEY } from 'constants/constants';
import { IPhotoIntelligenceComment } from 'constants/photoIntelligence';
import { t } from 'i18next';
import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { createPhotoIntelligenceComment } from 'services/clients/apiClient.services';
import DocumentUploadModal from './DocumentUploadModal';

interface TextFieldAreaProps {
  _id: string | undefined;
  setListComments: (arg: IPhotoIntelligenceComment[]) => void;
  setIsShowUploadDocumentModal: (arg: boolean) => void;
}

const TextFieldArea: FC<TextFieldAreaProps> = ({ _id, setListComments }) => {
  const theme = useTheme();
  const [newComment, setNewComment] = useState<string>('');
  const [isShowUploadDocumentModal, setIsShowUploadDocumentModal] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const createCommentMutation = useMutation(createPhotoIntelligenceComment, {
    onSuccess: () => {
      setNewComment('');
      setIsShowUploadDocumentModal(false);
      toast.success('Create new message successful');
      queryClient.invalidateQueries([QUERY_KEY.PHOTO_INTELLIGENCE_COMMENT_LIST]);
    },
    onError: (error) => {
      console.error('Error creating comment:', error);
    },
  });

  const handleSendComment = () => {
    _id &&
      createCommentMutation.mutate({
        photoIntelligenceId: _id,
        content: newComment,
        media: '',
      });
    setIsShowUploadDocumentModal(false);
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    setNewComment(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendComment();
    }
  };

  const modalRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #ccc',
          borderRadius: '8px',
          position: 'fixed',
          bottom: '10px',
          left: '5px',
          right: '5px',
        }}>
        <Button
          style={{ color: theme.palette.color.grey2 }}
          onClick={() => setIsShowUploadDocumentModal(true)}
          onDoubleClick={() => setIsShowUploadDocumentModal(false)}>
          <AttachFileIcon />
        </Button>
        <TextField
          type="text"
          placeholder={t('trans.type_new_messages')}
          style={{ flex: 1 }}
          size="small"
          value={newComment}
          onChange={(e) => handleChangeInput(e)}
          onKeyDown={handleKeyDown}
          minRows={1}
          maxRows={3}
          fullWidth
          multiline
          InputProps={{
            sx: {
              '& fieldset': {
                border: 'none',
              },
            },
          }}
        />
        <Button style={{ cursor: 'pointer' }} onClick={handleSendComment}>
          <SendIcon />
        </Button>
      </Box>
      <Box style={{ marginRight: '340px' }} ref={modalRef}>
        {isShowUploadDocumentModal && (
          <DocumentUploadModal
            _id={_id}
            setListComments={setListComments}
            setIsShowUploadDocumentModal={setIsShowUploadDocumentModal}
            handleClose={() => setIsShowUploadDocumentModal(false)}
          />
        )}
      </Box>
    </>
  );
};

export default TextFieldArea;
