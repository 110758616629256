import { styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface VisualizationTableCommonProps {
  data: { key: string; value: number }[];
  fieldName: string;
}

const VisualizationTableCommon: FC<VisualizationTableCommonProps> = ({ data, fieldName }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colorBorder = theme.palette.mode === 'dark' ? '#fff !important' : '#6E6B7B !important';

  const CustomTableCell = styled(TableCell)(({ theme }) => ({
    color: colorBorder,
    borderBottom: `1px solid ${colorBorder}`,
    padding: '8px',
  }));

  const total = data.reduce((acc, cur) => {
    acc += cur.value;
    return acc;
  }, 0);

  const convertedData = data.map((item) => ({
    key: item.key,
    value: item.value,
    percent: total ? ((item.value / total) * 100).toFixed(2) : '0.00',
  }));

  return (
    <TableContainer
      sx={{
        mt: '12px',
        border: `1px solid ${colorBorder}`,
      }}>
      <Table sx={{}}>
        <TableHead>
          <TableRow>
            <CustomTableCell align="left">{fieldName}</CustomTableCell>
            <CustomTableCell align="center">{t('trans.count')}</CustomTableCell>
            <CustomTableCell align="center">{t('trans.percentage')}</CustomTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {convertedData?.map((row) => (
            <TableRow key={row.key}>
              <CustomTableCell component="td" scope="row">
                {row.key}
              </CustomTableCell>
              <CustomTableCell align="center">{row.value}</CustomTableCell>
              <CustomTableCell align="center">{row.percent}%</CustomTableCell>
            </TableRow>
          ))}
          <TableRow>
            <CustomTableCell component="td" scope="row">
              TOTAL
            </CustomTableCell>
            <CustomTableCell align="center">{total}</CustomTableCell>
            <CustomTableCell align="center">100.00%</CustomTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VisualizationTableCommon;
