import { Box, Button, FormControlLabel, Typography } from '@mui/material';
import CommonModal from 'components/Common/CommonModal';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { QUERY_KEY } from 'constants/constants';
import { IPhotoIntelligenceData, PhotoIntelligenceStatusEnum } from 'constants/photoIntelligence';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { updatePhotoIntelligenceStatus } from 'services/clients/apiClient.services';

interface SwitchStatusProps {
  data: IPhotoIntelligenceData | undefined;
}

const SwitchStatus: FC<SwitchStatusProps> = ({ data }) => {
  const { t } = useTranslation();
  const { _id, status } = data || {};
  const isOpen = status === PhotoIntelligenceStatusEnum.OPEN;
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const handleConfirm = () => {
    setIsOpenModal(false);
    _id &&
      changeStatusMutation.mutate({
        _id: _id,
        status:
          status === PhotoIntelligenceStatusEnum.OPEN
            ? PhotoIntelligenceStatusEnum.CLOSED
            : PhotoIntelligenceStatusEnum.OPEN,
      });
  };

  const handleCancel = () => {
    setIsOpenModal(false);
  };

  const changeStatusMutation = useMutation(updatePhotoIntelligenceStatus, {
    onSuccess(res) {
      queryClient.invalidateQueries([QUERY_KEY.PHOTO_INTELLIGENCE_ANALYSIS]);
      toast.success(t('trans.new_status_saved'));
    },
  });

  const handleChangeStatus = () => {
    setIsOpenModal(true);
  };

  return (
    <Box sx={{ mt: '8px' }} display="flex" justifyContent="space-between">
      <Typography>{t('trans.status')}</Typography>
      <Box>
        <FormControlLabel
          sx={{ ml: 0.5 }}
          control={<SwitchCustom checked={isOpen} onChange={handleChangeStatus} />}
          label={isOpen ? t('trans.open') : t('trans.close')}
        />
      </Box>
      <CommonModal
        isShow={isOpenModal}
        handleClose={handleCancel}
        closeIconColor="none"
        style={{ height: '220px', width: '400px' }}>
        <Box sx={{ whiteSpace: 'pre-line', padding: '30px', textAlign: 'center' }}>
          {`${t('trans.by_changing_this_KP_status')} ${
            status === PhotoIntelligenceStatusEnum.OPEN ? t('trans.closed') : t('trans.opend')
          } ${t('trans.by_the_system_too')} \n\n${t('trans.do_you_want_to_proceed')}`}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '0px 70px' }}>
          <Button
            onClick={handleConfirm}
            style={{ background: '#4FE3AE', color: 'white', height: '40px', width: '100px' }}>
            {t('trans.yes')}
          </Button>
          <Button
            onClick={handleCancel}
            style={{ marginLeft: '10px', background: '#FF0506', color: 'white', height: '40px', width: '100px' }}>
            {t('trans.cancel')}
          </Button>
        </Box>
      </CommonModal>
    </Box>
  );
};

export default SwitchStatus;
