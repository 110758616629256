import { PhotoIntelligenceStatusEnum, SeverityLevelEnum, ToggleEnumType } from 'constants/photoIntelligence';

const generateInitialValue = (enumType: ToggleEnumType) => {
  switch (enumType) {
    case ToggleEnumType.SEVERITY_LEVELS:
      return {
        [SeverityLevelEnum.HIGH]: 0,
        [SeverityLevelEnum.MEDIUM]: 0,
        [SeverityLevelEnum.LOW]: 0,
        [SeverityLevelEnum.GROUND_FEATURES]: 0,
      };
    case ToggleEnumType.STATUS:
      return {
        [PhotoIntelligenceStatusEnum.CLOSED]: 0,
        [PhotoIntelligenceStatusEnum.OPEN]: 0,
      };
    case ToggleEnumType.FINDINGS:
    default:
      return {};
  }
};

export const countOccurrences = <T extends SeverityLevelEnum | PhotoIntelligenceStatusEnum | string>(
  arr: T[],
  enumType: ToggleEnumType
): Record<T, number> => {
  const initialValue = generateInitialValue(enumType);
  let count: Record<T, number> = initialValue as Record<T, number>;
  if (!arr) return count;

  arr.forEach((item: T) => {
    if (count[item]) {
      count[item]++;
    } else {
      count[item] = 1;
    }
  });

  return count;
};

export const countOccurrencesArray = <T extends SeverityLevelEnum | PhotoIntelligenceStatusEnum | string>(
  arr: T[],
  enumType: ToggleEnumType
) => {
  return Object.entries(countOccurrences(arr, enumType)).map(([key, value]) => ({
    key: key as T,
    value: value as number,
  }));
};
