/* eslint-disable jsx-a11y/alt-text */
import { Add } from '@mui/icons-material';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  Select,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ISettingCropType } from 'common/defines/clients';
import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { ClientActionsDialog } from 'components/ClientsInfo/ClientActionsDialog';
import WaitingPageAPI from 'components/Common/WaitingAPI';
import { QUERY_KEY, rowPerPageOptions } from 'constants/constants';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { deleteCropTypeSettingsById, getSettingsDataCroptype } from 'services/settings/settings.services';
import { useCropTypeSettingStyles } from './CropTypeSettingStyle';
import UserAnalysisDialogCropType from './UserAnalysisDialogCropType';

const typAnalysisOptons = [
  {
    id: 1,
    label: TYPE_ANALYTICS_MAP_VIEW.LAND_USE_ANALYSIS,
    value: TYPE_ANALYTICS_MAP_VIEW.LAND_USE_ANALYSIS,
  },
  {
    id: 2,
    label: TYPE_ANALYTICS_MAP_VIEW.CROP_COVERAGE,
    value: TYPE_ANALYTICS_MAP_VIEW.CROP_COVERAGE,
  },
  {
    id: 3,
    label: TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_FIELD,
    value: TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_FIELD,
  },
  {
    id: 4,
    label: TYPE_ANALYTICS_MAP_VIEW.WATER_LOGGING,
    value: TYPE_ANALYTICS_MAP_VIEW.WATER_LOGGING,
  },
  {
    id: 5,
    label: TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA,
    value: TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA,
  },
  {
    id: 6,
    label: TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS,
    value: TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS,
  },
  {
    id: 7,
    label: TYPE_ANALYTICS_MAP_VIEW.BOUNDING_BOX,
    value: TYPE_ANALYTICS_MAP_VIEW.BOUNDING_BOX,
  },
  {
    id: 8,
    label: TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT,
    value: TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT,
  },
  {
    id: 9,
    label: TYPE_ANALYTICS_MAP_VIEW.VIGOR_TREE,
    value: TYPE_ANALYTICS_MAP_VIEW.VIGOR_TREE,
  },
  {
    id: 10,
    label: TYPE_ANALYTICS_MAP_VIEW.STRESS_TREE,
    value: TYPE_ANALYTICS_MAP_VIEW.STRESS_TREE,
  },
  {
    id: 11,
    label: TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_TREE,
    value: TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_TREE,
  },
  {
    id: 12,
    label: TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_TREE,
    value: TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_TREE,
  },
  {
    id: 13,
    label: TYPE_ANALYTICS_MAP_VIEW.WEED_INVASION,
    value: TYPE_ANALYTICS_MAP_VIEW.WEED_INVASION,
  },
  {
    id: 14,
    label: TYPE_ANALYTICS_MAP_VIEW.VACANT_AREA,
    value: TYPE_ANALYTICS_MAP_VIEW.VACANT_AREA,
  },
  {
    id: 15,
    label: TYPE_ANALYTICS_MAP_VIEW.TILLER_DENSITY,
    value: TYPE_ANALYTICS_MAP_VIEW.TILLER_DENSITY,
  },
  {
    id: 16,
    label: TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_FIELD,
    value: TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_FIELD,
  },
  {
    id: 17,
    label: TYPE_ANALYTICS_MAP_VIEW.VIGOR_FIELD,
    value: TYPE_ANALYTICS_MAP_VIEW.VIGOR_FIELD,
  },
  {
    id: 18,
    label: TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_TREE,
    value: TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_TREE,
  },
  {
    id: 19,
    label: TYPE_ANALYTICS_MAP_VIEW.STRESS_FIELD,
    value: TYPE_ANALYTICS_MAP_VIEW.STRESS_FIELD,
  },
  {
    id: 20,
    label: TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_FIELD,
    value: TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_FIELD,
  },
  {
    id: 21,
    label: TYPE_ANALYTICS_MAP_VIEW.SOIL_WATER_CONTENT_FIELD,
    value: TYPE_ANALYTICS_MAP_VIEW.SOIL_WATER_CONTENT_FIELD,
  },
  {
    id: 22,
    label: TYPE_ANALYTICS_MAP_VIEW.SOIL_EROSION_FIELD,
    value: TYPE_ANALYTICS_MAP_VIEW.SOIL_EROSION_FIELD,
  },
  {
    id: 23,
    label: TYPE_ANALYTICS_MAP_VIEW.SOIL_MOISTURE_CONTENT,
    value: TYPE_ANALYTICS_MAP_VIEW.SOIL_MOISTURE_CONTENT,
  },
  {
    id: 24,
    label: TYPE_ANALYTICS_MAP_VIEW.FISSURE,
    value: TYPE_ANALYTICS_MAP_VIEW.FISSURE,
  },
  {
    id: 25,
    label: TYPE_ANALYTICS_MAP_VIEW.LEAF_PHENOLOGY_FIELD,
    value: TYPE_ANALYTICS_MAP_VIEW.LEAF_PHENOLOGY_FIELD,
  },
  {
    id: 26,
    label: TYPE_ANALYTICS_MAP_VIEW.SLOPE_FIELD,
    value: TYPE_ANALYTICS_MAP_VIEW.SLOPE_FIELD,
  },
  {
    id: 27,
    label: TYPE_ANALYTICS_MAP_VIEW.GAP,
    value: TYPE_ANALYTICS_MAP_VIEW.GAP,
  },
  {
    id: 28,
    label: TYPE_ANALYTICS_MAP_VIEW.ELEVATION_FIELD,
    value: TYPE_ANALYTICS_MAP_VIEW.ELEVATION_FIELD,
  },
  {
    id: 29,
    label: TYPE_ANALYTICS_MAP_VIEW.WATERSHED_BASIN,
    value: TYPE_ANALYTICS_MAP_VIEW.WATERSHED_BASIN,
  },
  {
    id: 30,
    label: TYPE_ANALYTICS_MAP_VIEW.TREE_TILT_FALLEN,
    value: TYPE_ANALYTICS_MAP_VIEW.TREE_TILT_FALLEN,
  },
  {
    id: 31,
    label: TYPE_ANALYTICS_MAP_VIEW.CUSTOM_FIELD,
    value: TYPE_ANALYTICS_MAP_VIEW.CUSTOM_FIELD,
  },
  {
    id: 32,
    label: TYPE_ANALYTICS_MAP_VIEW.VEGETATION_ENCROACHMENT,
    value: TYPE_ANALYTICS_MAP_VIEW.VEGETATION_ENCROACHMENT,
  },
  {
    id: 33,
    label: TYPE_ANALYTICS_MAP_VIEW.TREE_HEIGHT,
    value: TYPE_ANALYTICS_MAP_VIEW.TREE_HEIGHT,
  },
  {
    id: 34,
    label: TYPE_ANALYTICS_MAP_VIEW.CO2_SEQUESTRATION,
    value: TYPE_ANALYTICS_MAP_VIEW.CO2_SEQUESTRATION,
  },
  {
    id: 35,
    label: TYPE_ANALYTICS_MAP_VIEW.PHOTO_INTELLIGENCE,
    value: TYPE_ANALYTICS_MAP_VIEW.PHOTO_INTELLIGENCE,
  },
];

const CropTypeSettings = () => {
  const classes = useCropTypeSettingStyles();
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [searchSettings, setSearchSettings] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [settingIndex, setSettingIndex] = useState('');
  const [openAddAnalysisCropType, setOpenAddAnalysisCropType] = useState(false);
  const [dataById, setDataById] = useState<ISettingCropType>();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { t } = useTranslation();

  // Call API
  const {
    data,
    refetch,
    isLoading: loadingDataCroptype,
  } = useQuery([QUERY_KEY.USER_SETTINGS_CROPTYPE, page, rowsPerPage, searchSettings], () =>
    getSettingsDataCroptype(page, rowsPerPage, searchSettings)
  );

  const dataSettingsAnalyticsValue = useMemo(() => {
    return data?.data?.data;
  }, [data]);
  const dataValue = useMemo(() => {
    return data?.data?.metadata;
  }, [data]);

  const indexStartPage = useMemo(() => {
    return (dataValue?.currentPage - 1) * dataValue?.pageSize + 1;
  }, [dataValue]);

  const indexEndPage = useMemo(() => {
    if (!dataValue) {
      return 0;
    }
    return Math.min(dataValue?.currentPage * dataValue?.pageSize, dataValue?.totalCount);
  }, [dataValue]);

  // Pagination controls
  const handleChangePageSize = (event: any) => {
    setPage(1);
    setRowsPerPage(event.target.value);
  };

  // Open/ Close add new type modal
  const handleOpenAddAnalysisCropType = (id?: string) => {
    setOpenAddAnalysisCropType(true);
    if (id) {
      setSettingIndex(id);
    } else {
      setSettingIndex('');
    }
  };

  const handleCloseAddAnalysisCropType = () => {
    setSettingIndex('');
    setDataById(undefined);
    setOpenAddAnalysisCropType(false);
  };

  // Handle Edit/ Delete type
  const handleDelete = useMutation((id: string) => deleteCropTypeSettingsById(id), {
    onSuccess: () => {
      setOpenDeleteModal(false);
      setSettingIndex('');
      queryClient.invalidateQueries([QUERY_KEY.USER_SETTINGS_CROPTYPE]);
      refetch();
    },
    onError: (result: any) => {
      console.log(result);
    },
  });

  const onSave = () => {
    handleDelete.mutate(settingIndex);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
    setSettingIndex('');
    refetch();
  };

  const getDate = useCallback((dateString: string) => {
    return moment(dateString).format('DD/MM/YYYY HH:mm:ss');
  }, []);

  const formatBytes = (file: any) => {
    const bytes = file?.size;
    if (!bytes) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  return (
    <Grid className={classes.cropTypeSettings}>
      <Box className={classes.cropTypeSettingsBox}>
        <Box className={classes.controlBox}>
          <Typography mr="10px" fontSize="16px">
            Show
          </Typography>
          <Select className={classes.selectShow} value={rowsPerPage} onChange={handleChangePageSize}>
            {rowPerPageOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <TextField
            className={classes.tableSearch}
            type="text"
            placeholder="Search Type..."
            onChange={(e) => {
              setSearchSettings(e.target.value);
            }}
            InputProps={{
              size: 'small',
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon className={classes.clientActionsIConSearch} />
                </InputAdornment>
              ),
              inputProps: {
                style: {
                  fontFamily: 'Barlow',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '14px',
                  lineHeight: '17px',
                },
              },
            }}
          />
          <Button
            startIcon={<Add />}
            onClick={() => {
              handleOpenAddAnalysisCropType();
            }}
            className={classes.addCropTypeBtn}>
            Add Type
          </Button>
        </Box>
      </Box>
      {openAddAnalysisCropType && (
        <UserAnalysisDialogCropType
          settingIndex={settingIndex}
          onClose={handleCloseAddAnalysisCropType}
          page={page}
          rowsPerPage={rowsPerPage}
          searchSettings={searchSettings}
          editData={dataById}
          typAnalysisOptons={typAnalysisOptons}
        />
      )}
      <TableContainer>
        <Table className={classes.tableBox} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow className={classes.tableRowTitle}>
              <TableCell>Type Name</TableCell>
              <TableCell align="center">Document</TableCell>
              <TableCell align="center">Created At</TableCell>
              <TableCell align="center">Update At</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          {loadingDataCroptype ? (
            <>
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={4}
                    sx={{
                      padding: '16px 0',
                    }}>
                    <WaitingPageAPI />
                  </TableCell>
                </TableRow>
              </TableBody>
            </>
          ) : (
            <TableBody>
              {dataSettingsAnalyticsValue && dataSettingsAnalyticsValue.length > 0 ? (
                dataSettingsAnalyticsValue?.map((cropTypeValue: any, index: number) => {
                  const fileNameRegex = cropTypeValue?.pdfDocument?.replace(/^.*[\\\/-]/, '');
                  const documentFile = new File([cropTypeValue.pdfDocument], decodeURI(fileNameRegex) || '');

                  return (
                    <TableRow key={index} className={classes.tableRowCpn}>
                      <TableCell className={classes.cropTypeNameContainer}>
                        <Box
                          className={classes.dataViewIcon}
                          sx={{
                            backgroundColor: cropTypeValue.color,
                          }}>
                          <SvgIcon
                            component={() => (
                              <img
                                src={cropTypeValue.icon}
                                alt={cropTypeValue.name}
                                style={{
                                  height: '20px',
                                  width: '20px',
                                }}
                              />
                            )}
                          />
                        </Box>
                        <Typography className={classes.marginLeft}>{cropTypeValue.name}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        {cropTypeValue.pdfDocument ? (
                          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <InsertDriveFileIcon fontSize="large" color="info" />
                            <Box sx={{ textAlign: 'left', px: 1 }}>
                              <Typography
                                sx={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  fontSize: '12px',
                                  maxWidth: '120px',
                                }}>
                                {documentFile.name}
                              </Typography>
                              <Typography color="disabled" sx={{ fontSize: '12px' }}>
                                {moment(documentFile.lastModified).format('YYYY-MM-DD')}, {formatBytes(documentFile)}
                              </Typography>
                            </Box>
                          </Box>
                        ) : null}
                      </TableCell>
                      <TableCell align="center">{getDate(cropTypeValue.createdAt)}</TableCell>
                      <TableCell align="center">{getDate(cropTypeValue.updatedAt)}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            handleOpenAddAnalysisCropType(cropTypeValue._id);
                          }}>
                          <EditRoundedIcon className={classes.clientActionsIcon} />
                        </IconButton>
                        {cropTypeValue.pdfDocument ? (
                          <Tooltip title="Download Document">
                            <a href={cropTypeValue.pdfDocument} download={cropTypeValue.pdfDocument}>
                              <IconButton aria-label="document">
                                <InsertDriveFileIcon className={classes.clientActionsIcon} />
                              </IconButton>
                            </a>
                          </Tooltip>
                        ) : null}

                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            setOpenDeleteModal(true);
                            setSettingIndex(cropTypeValue._id);
                          }}>
                          <DeleteRoundedIcon className={classes.clientActionsIcon} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Typography variant="body1" color="textSecondary">
                      {t('dashboard.no_data_found_or_no_permission')}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {dataSettingsAnalyticsValue && dataSettingsAnalyticsValue.length > 0 && (
        <Box className={classes.paginationContainer}>
          <Typography>
            Showing {indexStartPage} to {indexEndPage} of {dataValue?.totalCount} types
          </Typography>
          <Stack spacing={2}>
            <Pagination
              className={classes.paginationBox}
              count={dataValue?.pageCount}
              defaultPage={1}
              page={page}
              onChange={(_, value) => {
                setPage(value);
              }}
            />
          </Stack>
        </Box>
      )}
      {/* Delete */}
      <ClientActionsDialog
        title="Delete Type Setting"
        open={openDeleteModal}
        onClose={closeDeleteModal}
        description="Are you sure about that?"
        onCancel={closeDeleteModal}
        onSave={onSave}
        submit={false}
      />
    </Grid>
  );
};

export default CropTypeSettings;
