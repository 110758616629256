import { Grid, useTheme } from '@mui/material';
import { IAnalyticsTabs3DData } from 'common/defines/clients';
import { getDayFromDate } from 'common/utils/util';
import { CROP_HIERARCHY, NO_DATA_LABEL, PRODUCTION_TRENDS_TYPES, QUERY_KEY, SEVERITY } from 'constants/constants';
import ReactEcharts, { EChartsOption } from 'echarts-for-react';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getDashboardProductionTrends } from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setWeighbridgeByProduct } from 'store/slices/dashboardWidgetSlice';
import { bottomBarSelector } from 'store/slices/map-view/bottomBarSlice';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { getColorPaletteForAnalyticsTab } from '../analyticsTabConstants';

export const WeighbridgeByProductChart = () => {
  const { dateRange } = useAppSelector(bottomBarSelector);
  const theme = useTheme();
  const { clientId } = useParams();
  const dispatch = useAppDispatch();
  const { selectedLevelHierarchyList } = useAppSelector(mapViewSelector);
  const [monthlyProductWeightData, setMonthlyProductWeightData] = useState<EChartsOption>({});
  const [levelHierarchyObject, setLevelHierarchyObject] = useState<any>(null);

  const findSeverity = (totalValue: number = 0, value: number = 0): string => {
    const partSize = totalValue / 3;
    if (totalValue && value) {
      if (value <= partSize) {
        return SEVERITY.LOW;
      } else if (value <= partSize * 2) {
        return SEVERITY.MEDIUM;
      } else {
        return SEVERITY.HIGH;
      }
    } else {
      return SEVERITY.LOW;
    }
  };

  const colorPalette = getColorPaletteForAnalyticsTab(theme.palette.background.paper);

  const formLevelBasedQuery = useCallback(() => {
    let levelData: any[] =
      selectedLevelHierarchyList && selectedLevelHierarchyList.length ? [...selectedLevelHierarchyList] : [];

    if (levelData.length) {
      let estateObj = levelData.filter((ele) => ele?.level === 1)[0] || {};
      let divisionObj = levelData.filter((ele) => ele?.level === 2)[0] || {};
      let blockObj = levelData.filter((ele) => ele?.level === 3)[0] || {};
      setLevelHierarchyObject({
        estateId: estateObj?.cropManagementAssetId || '',
        divisionName: divisionObj?.name || '',
        blockName: blockObj?.name || '',
      });
    } else {
      setLevelHierarchyObject(null);
    }
  }, [selectedLevelHierarchyList]);

  useEffect(() => {
    formLevelBasedQuery();
  }, [selectedLevelHierarchyList, formLevelBasedQuery]);

  // useEffect(() => {
  //     refetchProductionTrendData();
  // }, [dateRange, levelHierarchyObject]);

  useQuery(
    [QUERY_KEY.GET_PRODUCTION_TREND_DATA, dateRange, levelHierarchyObject],
    () =>
      getDashboardProductionTrends({
        clientId: clientId!,
        fromDate: dateRange.startDate,
        toDate: dateRange.endDate,
        estateId: levelHierarchyObject?.estateId || '',
        divName: levelHierarchyObject?.divisionName || '',
        blockName: levelHierarchyObject?.blockName || '',
        type: PRODUCTION_TRENDS_TYPES.DAY,
      }),
    {
      onSuccess: (data: any) => {
        const lengthObj = { length: getDayFromDate(dateRange.endDate) };
        let seriesData: any[] = data.data.map((item: any) => {
          let array: number[] = Array.from(lengthObj, () => 0);
          item?.dayWiseInfo?.forEach((info: any) => {
            array[getDayFromDate(info?.WeighingDate) - 1] = info.NetWeight / 1000;
          });
          let series = {
            name: item.Product,
            data: array,
            type: 'line',
            areaStyle: {
              opacity: 0.2,
            },
            smooth: true,
            emphasis: { focus: 'series' },
          };
          return series;
        });

        const weighbridgeByProductLine = {
          title: {
            text: 'Weighbridge by Product',
            show: true,
            subtext: 'Date wise Product Chart',
          },
          legend: {
            type: 'scroll',
            orient: 'vertical',
            top: '65%',
            width: '80%',
            right: 'center',
            textStyle: {
              fontSize: 10,
            },
            selector: [
              {
                type: 'all',
                title: 'Select All',
              },
              {
                type: 'inverse',
                title: 'Unselect All',
              },
            ],
            selectorLabel: {
              borderRadius: 2,
            },
          },
          grid: {
            right: 'center',
            top: '15%',
            width: '75%',
            height: '225px',
          },
          color: colorPalette.lineAreaChart,
          emphasis: {
            label: {
              show: true,
              fontSize: 10,
              fontWeight: 'bold',
            },
          },
          tooltip: {
            trigger: 'item',
            formatter: `{a} <b>{c}</b>mt<br>{b}`,
          },
          xAxis: {
            data: seriesData?.length ? Array.from(lengthObj, (_, i) => (i + 1).toString()) : NO_DATA_LABEL,
          },
          yAxis: {
            axisLabel: {
              formatter: '{value} mt',
            },
          },
          series: seriesData.length
            ? seriesData
            : Array.from(lengthObj, (_, i) => ({
                name: '',
                data: [],
              })),
        };

        let weighbridgeData = data?.data && data?.data.length ? [...data?.data] : [];
        let groupKey: string = CROP_HIERARCHY.LOCATION;
        if (levelHierarchyObject?.estateId && !levelHierarchyObject?.divisionName && !levelHierarchyObject?.blockName) {
          groupKey = CROP_HIERARCHY.DIV_NAME;
        } else if (
          levelHierarchyObject?.estateId &&
          levelHierarchyObject?.divisionName &&
          !levelHierarchyObject?.blockName
        ) {
          groupKey = CROP_HIERARCHY.BLOCK_NAME;
        } else if (
          levelHierarchyObject?.estateId &&
          levelHierarchyObject?.divisionName &&
          levelHierarchyObject?.blockName
        ) {
          groupKey = CROP_HIERARCHY.BLOCK_NAME;
        } else {
          groupKey = CROP_HIERARCHY.LOCATION;
        }

        const groupedChartBarData: any = {};
        for (let item of weighbridgeData) {
          const name = item[groupKey];
          if (!groupedChartBarData[name]) {
            groupedChartBarData[name] = 0;
          }

          groupedChartBarData[name] += item.NetWeight / 1000;
        }

        let mappedChartBarData = Object.keys(groupedChartBarData).map((item) => ({
          name: item,
          value: groupedChartBarData[item],
        }));

        let finalChartBarData: IAnalyticsTabs3DData[] = [];
        if (mappedChartBarData.length) {
          const highestValueObject = mappedChartBarData.reduce((prev: any, current: any) => {
            return prev.value > current.value ? prev : current;
          }, {});

          finalChartBarData = mappedChartBarData.map((ele: any) => {
            return {
              ...ele,
              severity: findSeverity(highestValueObject?.value || 0, ele?.value || 0),
            };
          });
        }

        setMonthlyProductWeightData(weighbridgeByProductLine);
        dispatch(setWeighbridgeByProduct(finalChartBarData));
      },
    }
  );

  return (
    <Grid>
      <ReactEcharts option={monthlyProductWeightData} notMerge={true} style={{ width: '100%', height: '55vh' }} />
    </Grid>
  );
};
