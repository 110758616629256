import { Grid, InputLabel, useTheme } from '@mui/material';
import { AxiosResponse } from 'axios';
import { FILE_TYPE, LABEL_DATA_RASTERS, LayerTypeEnum, mapFileLabel } from 'common/defines/constants';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import { Formik } from 'formik';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { QUERY_KEY } from '../../../../constants/constants';
import { deleteRasterAnalysisData, getRasterDetails } from '../../../../services/analytics/apiAnalyticsConfig.services';
import { ButtonCustom } from '../../../Common/ButtonCustom';
import SelectFile from '../../../Common/SelectFile';
import { ErrorsDialog } from './ErrorDialog';

interface CommonTreeFormProps {
  sensorId: string;
  createAnalyticFunc: (params: any) => Promise<AxiosResponse>;
  fileType: FILE_TYPE;
  label: string;
  layerType: LayerTypeEnum;
  deleteFileGeojson: (id: string) => Promise<AxiosResponse<any, any>>;
}

const CommonTreeForm: FC<CommonTreeFormProps> = ({
  sensorId,
  fileType,
  label,
  layerType,
  createAnalyticFunc,
  deleteFileGeojson,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const formRef = useRef<any>();
  const theme = useTheme();
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [dataErrors, setDataErrors] = useState(null as any);

  const closeErrorModal = () => {
    setOpenErrorModal(false);
  };

  const { data: analyticDetails, refetch } = useQuery([QUERY_KEY.RASTER_DETAILS, sensorId], () =>
    getRasterDetails(sensorId)
  );

  const createAnalyticMutation = useMutation(createAnalyticFunc, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY.ANALYSIS_UPLOAD_HISTORY);
      refetch();
      toast.success(`Update ${label} analytic successful`, { toastId: 1 });
    },
    onError: (e: any) => {
      setOpenErrorModal(true);
      setDataErrors(e?.data?.message);
    },
  });

  useEffect(() => {
    formRef.current && formRef.current.resetForm();
  }, [sensorId, formRef]);

  const initialFields = useMemo(() => {
    let initParam: any = { file: undefined, intervalLimitFile: undefined };
    if (analyticDetails?.data?.uploadHistory) {
      analyticDetails.data.uploadHistory.forEach((_item: any) => {
        if (_item.fileType === 'GEO_JSON') {
          if (_item.geoJsonType === 'INTERVAL_LIMIT') initParam.intervalLimitFile = _item;
          else initParam.file = _item;
        } else {
          initParam[_item.layerType] = _item;
        }
      });
    }
    return initParam;
  }, [analyticDetails]);

  const renderRasterList = () => {
    switch (layerType) {
      case LayerTypeEnum.STRESS_TREE:
        return [LayerTypeEnum.STRESS_FIELD, LayerTypeEnum.STRESS_TREE];
      case LayerTypeEnum.VIGOR_TREE:
        return [LayerTypeEnum.VIGOR_FIELD, LayerTypeEnum.VIGOR_TREE];
      case LayerTypeEnum.CHLOROPHYLL_TREE:
        return [LayerTypeEnum.CHLOROPHYLL_FIELD, LayerTypeEnum.CHLOROPHYLL_TREE];
      case LayerTypeEnum.WATER_UPTAKE_TREE:
        return [LayerTypeEnum.WATER_UPTAKE_FIELD, LayerTypeEnum.WATER_UPTAKE_TREE];
      case LayerTypeEnum.PLANT_HEALTH_TREE:
        return [LayerTypeEnum.PLANT_HEALTH_FIELD, LayerTypeEnum.PLANT_HEALTH_TREE];
      default:
        return [];
    }
  };

  return (
    <div>
      <Formik
        innerRef={formRef}
        initialValues={Object.assign(initialFields)}
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          const formData = new FormData();
          const fileKey = fileType === FILE_TYPE.PLAN_HEALTH_OILPALM_FILE ? 'mainGeoJsonFile' : 'dataFile';
          formData.append('analysisId', sensorId);
          formData.append(fileKey, values.file || '');
          formData.append('intervalLimitFile', values.intervalLimitFile || '');
          createAnalyticMutation.mutate(formData, {
            onSuccess: () => {
              resetForm();
            },
          });
        }}>
        {({ values, setFieldValue, handleSubmit, submitForm }) => (
          <form onSubmit={handleSubmit}>
            <InputLabel sx={{ fontWeight: '600', mt: '24px' }}>{t('trans.geojson')}</InputLabel>
            <Grid display="flex" width="100%">
              <Grid
                sx={{
                  maxWidth: '700px',
                  width: '700px',
                }}>
                <Grid display="flex" alignItems="center" mt={2} justifyContent="space-between">
                  <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                    {t(convertTextToMultiLanguage(mapFileLabel[fileType], 'file_name'))}
                  </InputLabel>
                  <SelectFile
                    acceptFile={new RegExp(/.json$/)}
                    fileSelected={values.file}
                    disabled={createAnalyticMutation.isLoading}
                    onSelectFile={(file) => {
                      setFieldValue('file', file);
                    }}
                    analysisId={sensorId}
                    fileType={fileType}
                    deleteFileGeojson={deleteFileGeojson}
                  />
                </Grid>
                <Grid display="flex" alignItems="center" mt={2} justifyContent="space-between">
                  <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                    {t(convertTextToMultiLanguage(mapFileLabel[FILE_TYPE.INTERVALS_FILE], 'file_name'))}
                  </InputLabel>
                  <SelectFile
                    acceptFile={new RegExp(/.json$/)}
                    fileSelected={values.intervalLimitFile}
                    disabled={createAnalyticMutation.isLoading}
                    onSelectFile={(file) => {
                      setFieldValue('intervalLimitFile', file);
                    }}
                    analysisId={sensorId}
                    fileType={FILE_TYPE.INTERVALS_FILE}
                    deleteFileGeojson={deleteFileGeojson}
                  />
                </Grid>
              </Grid>
              <Grid marginLeft={'auto'}>
                <ButtonCustom
                  title={t('trans.save')}
                  border="0px"
                  colorLabel="#FFF"
                  sx={{ width: '160px', height: '1.4375em' }}
                  backgroundColor={theme.palette.primary.main}
                  onClick={() => submitForm()}
                  disabled={createAnalyticMutation.isLoading}
                  loading={createAnalyticMutation.isLoading}
                />
              </Grid>
            </Grid>
            {/* Raster area  */}
            <InputLabel sx={{ fontWeight: '600', mt: '64px' }}>{t('trans.raster')}</InputLabel>
            <Grid display="flex" width="100%">
              <Grid sx={{ maxWidth: '700px', width: '700px' }}>
                {renderRasterList().map((item) => (
                  <Grid display="flex" alignItems="center" mt={2} justifyContent="space-between">
                    <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                      {t(convertTextToMultiLanguage(LABEL_DATA_RASTERS[item], 'label_raster'))}
                    </InputLabel>
                    <SelectFile
                      acceptFile={new RegExp(/.zip$/)}
                      fileSelected={values[item]}
                      onSelectFile={(file) => setFieldValue(item, file)}
                      analysisId={sensorId}
                      layerType={item}
                      deleteFileGeojson={deleteRasterAnalysisData}
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid marginLeft={'auto'} sx={{ minWidth: '160px' }}></Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <ErrorsDialog
        title="Error Upload"
        open={openErrorModal}
        onClose={closeErrorModal}
        data={dataErrors}
        onCancel={closeErrorModal}
        submit={false}
      />
    </div>
  );
};

export default CommonTreeForm;
