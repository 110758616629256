import { centerOfMass, polygon, Position } from '@turf/turf';
import { getBoundsForPoints } from 'common/utils/util';
import { DEFAULT_VIEWPORT, QUERY_KEY } from 'constants/constants';
import { IParent, IPhotoIntelligenceMLevel } from 'constants/photoIntelligence';
import { get, isArray, isEmpty } from 'lodash';
import polylabel from 'polylabel';
import { FC, Fragment, MutableRefObject, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { getPhotoIntelligenceAllLocation } from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changePointCenters, mapViewSelector } from 'store/slices/mapViewSlice';
import { MarkerPoint } from './MarkerPoint';

interface MarkerPointLayerProps {
  mapRef: MutableRefObject<any>;
  containerMapRef: MutableRefObject<any>;
  dataMapView: any;
}
const MarkerPointLayer: FC<MarkerPointLayerProps> = ({ mapRef, containerMapRef, dataMapView }) => {
  const { isLastLevelId, levelId } = useAppSelector(mapViewSelector);
  const dispatch = useAppDispatch();

  const { data: dataPhotoIntelligence } = useQuery<IPhotoIntelligenceMLevel[]>(
    [QUERY_KEY.ALL_LOCATION_PHOTO_INTELLIGENCE, levelId],
    () => getPhotoIntelligenceAllLocation(levelId!),
    {
      enabled: !!levelId && !isLastLevelId,
    }
  );

  useEffect(() => {
    const allPointList = dataMapView.data
      .map((item: any) => {
        const geometryType = get(item, 'location.features[0].geometry.type');

        if (geometryType === 'MultiPolygon') {
          const coordinateList = get(item, 'location.features[0].geometry.coordinates');
          return coordinateList.flat(2);
        } else if (geometryType === 'Polygon') {
          const coordinates = get(item, 'location.features[0].geometry.coordinates');
          return coordinates.flat();
        }
        return [];
      })
      .flat();

    const calculatorViewport = (pointsPolygon: any[], mapRefCurrent: any) => {
      try {
        if (mapRefCurrent.current && !isEmpty(pointsPolygon)) {
          return getBoundsForPoints(
            pointsPolygon,
            mapRefCurrent.current.clientWidth,
            mapRefCurrent.current.clientHeight
          );
        }
      } catch (error) {
        console.log(error);
      }

      return DEFAULT_VIEWPORT;
    };
    const pointCenter = calculatorViewport(allPointList, containerMapRef);
    mapRef.current?.flyTo({
      center: [pointCenter.longitude, pointCenter.latitude],
      zoom: pointCenter.zoom,
      essential: true,
      speed: 1.0,
      curve: 1,
    });
  }, [containerMapRef, dataMapView.data, mapRef]);

  const centerListPoints = useMemo(() => {
    return dataMapView.data.map((item: any) => {
      const geometryType = get(item, 'location.features[0].geometry.type');

      if (geometryType === 'MultiPolygon') {
        const coordinateList = get(item, 'location.features[0].geometry.coordinates');
        const centerList = coordinateList?.map((item: Position[][]) => {
          const polygonGeo = polygon(item);
          const centerCoordinates = centerOfMass(polygonGeo).geometry.coordinates;
          return centerCoordinates;
        });
        const pointCenterPolygon = polylabel([centerList], 0.001);
        return {
          ...item,
          pointCenterPolygon,
        };
      } else if (geometryType === 'Polygon') {
        const coordinates = get(item, 'location.features[0].geometry.coordinates');
        const pointCenterPolygon = polylabel(coordinates, 0.001);
        return {
          ...item,
          pointCenterPolygon,
        };
      }
      return [];
    });
  }, [dataMapView.data]);

  useEffect(() => {
    // center point icons
    const pointsCenter = centerListPoints?.map((point: any) => {
      const { _id, pointCenterPolygon } = point;
      return {
        longitude: pointCenterPolygon?.[0] || 0,
        latitude: pointCenterPolygon?.[1] || 0,
        id: _id,
      };
    });
    dispatch(changePointCenters(pointsCenter));
  }, [centerListPoints, dispatch]);

  const findRecursiveLevelId = (levelInfo: IParent, pointId: string): boolean => {
    if (levelInfo._id === pointId) return true;
    if (levelInfo.parent) {
      return findRecursiveLevelId(levelInfo.parent, pointId);
    }
    return false;
  };

  const renderMarker = (item: any, photoIntelligenceListData: IPhotoIntelligenceMLevel[]) => {
    const { pointCenterPolygon } = item || {};
    if (!isArray(pointCenterPolygon)) return null;
    return (
      <Fragment key={item._id}>
        <MarkerPoint
          item={item}
          pointCenter={{
            longitude: pointCenterPolygon[0],
            latitude: pointCenterPolygon[1],
          }}
          photoIntelligenceListData={photoIntelligenceListData || []}
        />
      </Fragment>
    );
  };

  if (!dataMapView?.data || isLastLevelId) {
    return <></>;
  }

  return centerListPoints?.map((item: { _id: string }) => {
    const photoIntelligenceListData =
      dataPhotoIntelligence?.filter((ele) => findRecursiveLevelId(ele.level, item._id)) || [];
    return renderMarker(item, photoIntelligenceListData);
  });
};

export default MarkerPointLayer;
