/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import {
  IClientData,
  ICreateClientAdminFormData,
  ICreateClientsFormData,
  IOptionalProperty,
  IPersonInChargeInfo,
  MapGlDrawSchema,
  RenderTree,
} from '../../common/defines/clients';
import type { RootState } from '../store';

export interface IClientState {
  data: ICreateClientsFormData | null;
  clientData: IClientData | null;
  clientAdminData: ICreateClientAdminFormData | null;
  clientsAdminList: ICreateClientAdminFormData[] | null;
  levelAssetStructureData: RenderTree[] | null;
  createLevelAssetStructureData: RenderTree | null;
  isFetchingClients: boolean;
  isFetchClientsSuccess: boolean;
  isFetchClientsError: boolean;
  isCreating: boolean;
  isCreateSuccess: boolean;
  isCreateError: boolean;
  isFetchClientById: boolean;
  isFetchClientByIdSuccess: boolean;
  isFetchClientByIdError: boolean;
  isUploadImage: boolean;
  isUploadImageSuccess: boolean;
  isUploadImageError: boolean;
  isUpdateBasicInfo: boolean;
  isUpdateBasicInfoSuccess: boolean;
  isUpdateBasicInfoError: boolean;
  isUpdateLevelProp: boolean;
  isUpdateLevelPropSuccess: boolean;
  isUpdateLevelPropError: boolean;
  isDeleteClientById: boolean;
  isDeleteClientByIdSuccess: boolean;
  isDeleteClientByIdError: boolean;
  isClientAdminData: boolean;
  isClientAdminDataSuccess: boolean;
  isClientAdminDataError: boolean;
  isClientsAdminListFetch: boolean;
  isClientsAdminListSuccess: boolean;
  isClientsAdminListError: boolean;
  isClientAdminDelete: boolean;
  isClientAdminDeleteSuccess: boolean;
  isClientAdminDeleteError: boolean;
  isGetClientAdminById: boolean;
  isGetClientAdminByIdSuccess: boolean;
  isGetClientAdminByIdError: boolean;
  isUpdateClientAdminById: boolean;
  isUpdateClientAdminByIdSuccess: boolean;
  isUpdateClientAdminByIdError: boolean;
  errorMessage: string[];
  location: MapGlDrawSchema | null;
  pastLocation: Array<MapGlDrawSchema | null>;
  futureLocation: Array<MapGlDrawSchema | null>;
  properties: IOptionalProperty[];
  personInCharge: IPersonInChargeInfo[];
  allLocationCropType: any[];
  area: number;
  rolesData: any[];
  avatar: string;
  fileBoundaryInfo: any;
  clientSettingData: any;
  tableName: string | null;
  cropManagementAssetId: string | null;
}

export const initialState: IClientState = {
  data: null,
  clientData: null,
  clientAdminData: null,
  clientsAdminList: null,
  levelAssetStructureData: null,
  createLevelAssetStructureData: null,
  isFetchingClients: false,
  isFetchClientsSuccess: false,
  isFetchClientsError: false,
  isCreating: false,
  isCreateSuccess: false,
  isCreateError: false,
  isFetchClientById: false,
  isFetchClientByIdSuccess: false,
  isFetchClientByIdError: false,
  isUploadImage: false,
  isUploadImageSuccess: false,
  isUploadImageError: false,
  isUpdateBasicInfo: false,
  isUpdateBasicInfoSuccess: false,
  isUpdateBasicInfoError: false,
  isUpdateLevelProp: false,
  isUpdateLevelPropSuccess: false,
  isUpdateLevelPropError: false,
  isDeleteClientById: false,
  isDeleteClientByIdSuccess: false,
  isDeleteClientByIdError: false,
  isClientAdminData: false,
  isClientAdminDataSuccess: false,
  isClientAdminDataError: false,
  isClientsAdminListFetch: false,
  isClientsAdminListSuccess: false,
  isClientsAdminListError: false,
  isClientAdminDelete: false,
  isClientAdminDeleteSuccess: false,
  isClientAdminDeleteError: false,
  isGetClientAdminById: false,
  isGetClientAdminByIdSuccess: false,
  isGetClientAdminByIdError: false,
  isUpdateClientAdminById: false,
  isUpdateClientAdminByIdSuccess: false,
  isUpdateClientAdminByIdError: false,
  errorMessage: [],
  location: null,
  pastLocation: [],
  futureLocation: [],
  properties: [],
  personInCharge: [],
  allLocationCropType: [],
  area: 0,
  rolesData: [],
  avatar: '',
  fileBoundaryInfo: null,
  clientSettingData: null,
  tableName: null,
  cropManagementAssetId: null,
};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    clearClientState: (state) => {
      state.data = null;
      state.clientData = null;
      state.clientAdminData = null;
      (state.levelAssetStructureData = null),
        (state.createLevelAssetStructureData = null),
        (state.isFetchingClients = false);
      (state.levelAssetStructureData = null),
        (state.createLevelAssetStructureData = null),
        (state.isFetchingClients = false);
      state.isFetchClientsSuccess = false;
      state.isFetchClientsError = false;
      state.isCreateError = false;
      state.isCreateSuccess = false;
      state.isCreating = false;
      state.isFetchClientById = false;
      state.isFetchClientByIdSuccess = false;
      state.isFetchClientByIdError = false;
      state.isUploadImage = false;
      state.isUploadImageSuccess = false;
      state.isUploadImageError = false;
      state.isUpdateBasicInfo = false;
      state.isUpdateBasicInfoSuccess = false;
      state.isUpdateBasicInfoError = false;
      state.isUpdateLevelProp = false;
      state.isUpdateLevelPropSuccess = false;
      state.isUpdateLevelPropError = false;
      state.isClientAdminData = false;
      state.isClientAdminDataSuccess = false;
      state.isClientAdminDataError = false;
      state.isClientsAdminListFetch = false;
      state.isClientsAdminListSuccess = false;
      state.isClientsAdminListError = false;
      state.isClientAdminDelete = false;
      state.isClientAdminDeleteSuccess = false;
      state.isClientAdminDeleteError = false;
      state.isGetClientAdminById = false;
      state.isGetClientAdminByIdSuccess = false;
      state.isGetClientAdminByIdError = false;
      state.errorMessage = [];
      state.location = null;
      state.pastLocation = [];
      state.futureLocation = [];
      state.avatar = '';
      return state;
    },
    clearClientUpdateState: (state) => {
      state.clientAdminData = null;
      state.isCreateSuccess = false;
      state.isUpdateBasicInfoSuccess = false;
      state.isFetchClientById = false;
      state.isUpdateLevelPropSuccess = false;
      state.isClientAdminDataError = false;
      state.isClientAdminDataSuccess = false;
      state.isClientAdminDeleteSuccess = false;
      state.isClientAdminDeleteError = false;
    },
    clearClientUserAdminState: (state) => {
      (state.clientAdminData = null), (state.isClientAdminData = false);
      state.isClientAdminDataSuccess = false;
      state.isClientAdminDataError = false;
      state.isClientsAdminListFetch = false;
      state.isClientsAdminListSuccess = false;
      state.isClientsAdminListError = false;
      state.isClientAdminDelete = false;
      state.isClientAdminDeleteSuccess = false;
      state.isClientAdminDeleteError = false;
      state.isGetClientAdminById = false;
      state.isGetClientAdminByIdSuccess = false;
      state.isGetClientAdminByIdError = false;
    },

    clearClientUserAdminUpdateState: (state) => {
      state.isUpdateClientAdminById = false;
      state.isUpdateClientAdminByIdSuccess = false;
      state.isUpdateClientAdminByIdError = false;
    },

    changeLocation: (state, action: PayloadAction<MapGlDrawSchema | null>) => {
      const { pastLocation, location } = state;
      state.pastLocation = [...pastLocation, location];
      state.location = action.payload;
      state.futureLocation = [];
    },

    undoLocation: (state) => {
      const { pastLocation, location, futureLocation } = state;
      if (isEmpty(pastLocation)) {
        return;
      }
      const previous = pastLocation[pastLocation.length - 1];

      const newPast = pastLocation.slice(0, pastLocation.length - 1);
      state.pastLocation = newPast;
      state.location = previous;
      state.futureLocation = [location, ...futureLocation];
    },

    redoLocation: (state) => {
      const { pastLocation, location, futureLocation } = state;
      if (isEmpty(futureLocation)) {
        return;
      }
      const next = futureLocation[0];
      if (!next) {
        return;
      }
      const newFuture = futureLocation.slice(1);
      state.pastLocation = [...pastLocation, location];
      state.location = next;
      state.futureLocation = newFuture;
    },

    setLevelProperties: (
      state,
      action: PayloadAction<{
        location: MapGlDrawSchema | null;
        properties: IOptionalProperty[];
        personInCharge: IPersonInChargeInfo[];
        area: number;
        fileBoundaryInfo: any;
        tableName: string | null;
        cropManagementAssetId: string | null;
      }>
    ) => {
      state.properties = action.payload.properties;
      state.location = action.payload.location;
      state.personInCharge = action.payload.personInCharge;
      state.area = action.payload.area;
      state.fileBoundaryInfo = action.payload.fileBoundaryInfo;
      state.tableName = action.payload.tableName;
      state.cropManagementAssetId = action.payload.cropManagementAssetId;
    },

    updateAllLocationCropType: (state, action: PayloadAction<any>) => {
      state.allLocationCropType = action.payload;
    },

    clearLevelState: (state) => {
      state.properties = [];
      state.location = null;
      state.personInCharge = [];
      state.allLocationCropType = [];
      state.area = 0;
    },

    createClientError: (state, { payload }) => {
      state.isCreateError = true;
      state.errorMessage = payload.message || ['Failed to add new client.'];
    },

    setClientData: (state, { payload }) => {
      state.clientData = payload;
    },

    setRolesData: (state, { payload }) => {
      state.rolesData = payload;
    },
    changeFileProperty: (state, { payload }) => {
      state.properties = payload;
    },
    setClientDataSetting: (state, { payload }) => {
      state.clientSettingData = payload;
    },
  },
});

export const {
  clearClientState,
  clearClientUpdateState,
  clearClientUserAdminState,
  clearClientUserAdminUpdateState,
  changeLocation,
  undoLocation,
  redoLocation,
  setLevelProperties,
  updateAllLocationCropType,
  clearLevelState,
  setClientData,
  setRolesData,
  changeFileProperty,
  setClientDataSetting,
} = clientSlice.actions;

export const clientSelector = (state: RootState) => state.client;

export default clientSlice.reducer;
