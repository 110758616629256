import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Avatar, Box, Button, Dialog, DialogContent, Popover, TextField, Typography, useTheme } from '@mui/material';
import { IMetadata } from 'common/defines/clients';
import CommonModal from 'components/Common/CommonModal';
import { QUERY_KEY } from 'constants/constants';
import { IPhotoIntelligenceComment, IPhotoIntelligenceData } from 'constants/photoIntelligence';
import { t } from 'i18next';
import moment from 'moment';
import { FC, useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import {
  deleteCommentPhotoIntelligence,
  editCommentPhotoIntelligence,
  getPhotoIntelligenceComment,
} from 'services/clients/apiClient.services';
import { useAppDispatch } from 'store/hooks';
import { changeIsShowCommentViewModal } from 'store/slices/map-view/photoIntelligenceAnalytics';
import TextFieldArea from './TextFieldArea';

interface CommentViewModalProps {
  data: IPhotoIntelligenceData | undefined;
}

const CommentViewModal: FC<CommentViewModalProps> = ({ data }) => {
  const theme = useTheme();
  const [listComments, setListComments] = useState<IPhotoIntelligenceComment[]>([]);
  const [page, setPage] = useState<number>(1);
  const [isShowUploadDocumentModal, setIsShowUploadDocumentModal] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentComment, setCurrentComment] = useState<IPhotoIntelligenceComment | null>(null);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openPopoverId, setOpenPopoverId] = useState<string | null>(null);

  const [commentToDeleteId, setCommentToDeleteId] = useState<string | null>(null);

  const handleCancel = () => {
    setIsOpenModalDelete(false);
    setIsEditModalOpen(false);
  };

  const deleteCommentMutation = useMutation({
    mutationFn: deleteCommentPhotoIntelligence,
    onSuccess: () => {
      setListComments((prevComments) => prevComments.filter((comment) => comment._id !== commentToDeleteId));
      setIsOpenModalDelete(false);
      setCommentToDeleteId(null);
      toast.success('Comment has been deleted successfully');
    },
    onError: (error) => {
      console.error('Failed to delete comment:', error);
    },
  });

  const handleOpenDeleteModal = (id: string) => {
    setCommentToDeleteId(id);
    setIsOpenModalDelete(true);
    handleClose();
  };

  const handleDeleteComment = () => {
    if (!commentToDeleteId) return;
    deleteCommentMutation.mutate(commentToDeleteId);
  };

  const handleOpenEditModal = (comment: IPhotoIntelligenceComment) => {
    setCurrentComment(comment);
    setIsEditModalOpen(true);
    handleClose();
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setCurrentComment(null);
  };

  const handleSaveComment = async () => {
    try {
      if (currentComment && currentComment._id) {
        await editCommentPhotoIntelligence({
          _id: currentComment._id,
          content: currentComment.content,
          media: currentComment.media,
        });
        setListComments((prevComments) =>
          prevComments.map((comment) =>
            comment._id === currentComment._id ? { ...comment, content: currentComment.content } : comment
          )
        );
        handleCloseEditModal();
        toast.success('Comment has been edited successfully');
      }
    } catch (error) {
      console.error('Failed to update comment:', error);
    }
  };

  const scrollbarsRef = useRef<Scrollbars | null>(null);

  useEffect(() => {
    if (scrollbarsRef.current) {
      scrollbarsRef.current.scrollTop(scrollbarsRef.current.getScrollHeight());
    }
  }, [listComments]);

  const handleClickOpen = (imageUrl: string) => {
    setCurrentImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentImage('');
    setAnchorEl(null);
  };

  const { _id } = data || {};
  const dispatch = useAppDispatch();

  useQuery(
    [QUERY_KEY.PHOTO_INTELLIGENCE_COMMENT_LIST, _id, page],
    () => getPhotoIntelligenceComment({ page, perPage: 50, photoIntelligenceId: _id! }),
    {
      enabled: !!_id,
      onSuccess(res) {
        const commentData = res.data?.data as IPhotoIntelligenceComment[];
        const metaData = res.data?.metadata as IMetadata;

        setListComments((prev) => [...prev, ...commentData]);
        if (metaData?.nextPage) {
          setPage((prev) => prev + 1);
        }
      },
    }
  );
  const isImageFile = (mediaUrl: string) => {
    const imagePattern = /\.(jpeg|jpg|gif|png)$/i;
    return imagePattern.test(mediaUrl);
  };

  const renderDialog = (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      PaperProps={{
        style: {
          height: '100vh',
          maxHeight: '100vh',
          width: '100%',
          margin: 0,
          padding: 0,
        },
      }}>
      <DialogContent>
        <img
          src={currentImage}
          alt="preview"
          style={{
            height: '100%',
            width: '100%',
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      </DialogContent>
    </Dialog>
  );

  const opens = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, id: string) => {
    setAnchorEl(event.currentTarget);
    setOpenPopoverId(id);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenPopoverId(null);
  };

  return (
    <CommonModal
      style={{ height: '820px', width: '600px', borderRadius: '8px', padding: '20px' }}
      isShow={true}
      handleClose={() => dispatch(changeIsShowCommentViewModal({ id: '', isShow: false }))}>
      <Scrollbars ref={scrollbarsRef} style={{ height: '700px' }} autoHide>
        <Box
          style={{
            borderRadius: '8px',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}>
          <Box
            style={{
              borderRadius: '8px',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}>
            <Box style={{ marginTop: '20px' }}>
              {listComments?.map((item, index) => (
                <Box
                  key={index}
                  style={{
                    marginBottom: '15px',
                    border: '1px solid #333',
                    borderRadius: '8px',
                    padding: '10px',
                  }}>
                  <Box
                    style={{
                      fontSize: '12px',
                      color: theme.palette.color.grey2,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    {moment(item.updatedAt).format('DD-MM-YYYY')}
                    <Box
                      component="button"
                      aria-describedby={id}
                      onClick={(e: React.MouseEvent<HTMLElement>) => handlePopoverOpen(e, item._id)}
                      style={{
                        height: '20px',
                        width: '26px',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        color: theme.palette.color.grey2,
                      }}>
                      <MoreVertIcon />
                    </Box>
                    <Popover
                      id={id}
                      open={opens && openPopoverId === item._id}
                      anchorEl={anchorEl}
                      onClose={handlePopoverClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}>
                      <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left' }}>
                        <Button
                          onClick={() => handleOpenEditModal(item)}
                          style={{
                            display: 'flex',
                            justifyContent: 'left',
                            height: '40px',
                            width: '80px',
                            cursor: 'pointer',
                            color: theme.palette.color.grey2,
                          }}>
                          <EditIcon fontSize="small" /> Edit
                        </Button>
                        <Button
                          onClick={() => handleOpenDeleteModal(item._id)}
                          style={{
                            display: 'flex',
                            justifyContent: 'left',
                            height: '40px',
                            width: '80px',
                            cursor: 'pointer',
                            color: theme.palette.color.grey2,
                          }}>
                          <DeleteIcon fontSize="small" /> Delete
                        </Button>
                      </Box>
                    </Popover>
                  </Box>
                  <Box style={{ fontSize: '14px', fontWeight: 'bold' }}>{item.user?.name}</Box>
                  <Box style={{ fontSize: '14px', whiteSpace: 'pre-wrap' }}>{item.content}</Box>
                  {item.media && (
                    <Box style={{ marginTop: '10px' }}>
                      <>
                        {isImageFile(item.media) ? (
                          <Box
                            style={{
                              width: '280px',
                              height: '210px',
                            }}>
                            <img
                              src={item.media}
                              alt="uploaded-img"
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: '8px',
                                cursor: 'pointer',
                              }}
                              onClick={() => handleClickOpen(item.media)}
                              loading="lazy"
                            />
                          </Box>
                        ) : (
                          <a
                            href={item.media}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
                            <Avatar sx={{ width: 48, height: 48, mr: 1 }}>
                              <InsertDriveFileIcon sx={{ color: 'white' }} />
                            </Avatar>
                            <Box display="flex" flexDirection="column">
                              <Typography variant="body1" fontWeight="bold">
                                {item.media
                                  ? decodeURIComponent(
                                      item.media
                                        .split('/')
                                        .pop()
                                        ?.replace(/^\d{4}-\d{2}-\d{2}(_\d{2}%3A\d{2}%3A\d{4})?/, '') || ''
                                    )
                                  : ''}
                              </Typography>
                            </Box>
                          </a>
                        )}
                        {renderDialog}
                      </>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Scrollbars>
      <CommonModal
        style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '20px' }}
        isShow={isEditModalOpen}
        handleClose={handleCloseEditModal}>
        <Typography variant="h6">Edit comment</Typography>
        <TextField
          type="text"
          placeholder={t('trans.type_new_messages')}
          style={{ flex: 1 }}
          size="small"
          fullWidth
          value={currentComment?.content || ''}
          onChange={(e) =>
            setCurrentComment({ ...currentComment, content: e.target.value } as IPhotoIntelligenceComment)
          }
          minRows={1}
          maxRows={3}
          multiline
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '0px 70px' }}>
          <Button
            onClick={handleSaveComment}
            style={{ background: '#4FE3AE', color: 'white', height: '30px', width: '80px' }}>
            Update
          </Button>
          <Button
            onClick={handleCloseEditModal}
            style={{ marginLeft: '10px', background: '#FF0506', color: 'white', height: '30px', width: '80px' }}>
            Cancel
          </Button>
        </Box>
      </CommonModal>

      <CommonModal
        isShow={isOpenModalDelete}
        handleClose={handleCancel}
        closeIconColor="none"
        style={{ height: '140px', width: '360px' }}>
        <Box sx={{ whiteSpace: 'pre-line', padding: '30px', textAlign: 'center' }}>
          Do you want to delete this message
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '0px 70px' }}>
          <Button
            onClick={handleDeleteComment}
            style={{ background: '#4FE3AE', color: 'white', height: '30px', width: '80px' }}>
            {t('trans.yes')}
          </Button>
          <Button
            onClick={handleCancel}
            style={{ marginLeft: '10px', background: '#FF0506', color: 'white', height: '30px', width: '80px' }}>
            {t('trans.cancel')}
          </Button>
        </Box>
      </CommonModal>

      <Box display="flex" flexDirection="column" alignItems="center">
        <TextFieldArea
          _id={_id}
          setListComments={setListComments}
          setIsShowUploadDocumentModal={setIsShowUploadDocumentModal}
        />
      </Box>
    </CommonModal>
  );
};

export default CommentViewModal;
