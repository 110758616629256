import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { Box, useTheme } from '@mui/material';
import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeIsShowImageViewModal,
  photoIntelligenceSelector,
} from 'store/slices/map-view/photoIntelligenceAnalytics';

interface ImageViewProps {
  id: string;
}

const ImageView: FC<ImageViewProps> = ({ id }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {
    imageViewModal: { id: selectedId },
  } = useAppSelector(photoIntelligenceSelector);

  const handleClick = () => {
    dispatch(changeIsShowImageViewModal({ id, isShow: true }));
  };

  return (
    <Box onClick={handleClick}>
      <ImageOutlinedIcon
        fontSize="medium"
        sx={{
          cursor: 'pointer',
          color: selectedId === id ? theme.palette.primary.main : theme.palette.color.grey2,
        }}
      />
    </Box>
  );
};

export default ImageView;
