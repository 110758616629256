import PieChartIcon from '@mui/icons-material/PieChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import { Box, Skeleton } from '@mui/material';
import { IPhotoIntelligenceMLevel } from 'constants/photoIntelligence';
import { FC, useState } from 'react';
import FindingsChart from './FindingsChart';
import SeverityLevelChart from './SeverityLevelChart';
import StatusChart from './StatusChart';

interface ChartAreaProps {
  photoIntelligenceData: IPhotoIntelligenceMLevel[];
  isLoading: boolean;
}

const ChartArea: FC<ChartAreaProps> = ({ photoIntelligenceData, isLoading }) => {
  const [isChartMode, setIsChartMode] = useState<boolean>(true);
  const flattedData = photoIntelligenceData?.map((item) => item.data)?.flat();

  const renderSkeleton = (
    <Box>
      {[1, 2, 3].map((item) => (
        <Skeleton
          key={item}
          variant="rectangular"
          sx={{
            display: 'flex',
            height: '200px',
            width: '100%',
            marginY: '12px',
          }}
        />
      ))}
    </Box>
  );

  const renderChart = (
    <Box
      sx={{
        pt: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
      }}>
      <StatusChart data={flattedData} isChartMode={isChartMode} />
      <SeverityLevelChart data={flattedData} isChartMode={isChartMode} />
      <FindingsChart data={flattedData} isChartMode={isChartMode} />
    </Box>
  );

  return isLoading ? (
    renderSkeleton
  ) : (
    <Box position="relative">
      <Box
        sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, cursor: 'pointer' }}
        onClick={() => setIsChartMode(!isChartMode)}>
        {isChartMode ? <PieChartIcon /> : <TableChartIcon />}
      </Box>
      {renderChart}
    </Box>
  );
};

export default ChartArea;
