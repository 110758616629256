import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import { Box, useTheme } from '@mui/material';
import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeIsShowCommentViewModal,
  photoIntelligenceSelector,
} from 'store/slices/map-view/photoIntelligenceAnalytics';

interface CommentViewProps {
  id: string;
}

const CommentView: FC<CommentViewProps> = ({ id }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {
    commentViewModal: { id: selectedId },
  } = useAppSelector(photoIntelligenceSelector);

  return (
    <Box onClick={() => dispatch(changeIsShowCommentViewModal({ id: id, isShow: true }))}>
      <MessageOutlinedIcon
        fontSize="medium"
        sx={{ cursor: 'pointer', color: selectedId === id ? theme.palette.primary.main : theme.palette.color.grey2 }}
      />
    </Box>
  );
};

export default CommentView;
