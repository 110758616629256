import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { countOccurrencesArray } from 'common/utils/count';
import { IPhotoIntelligenceData, SeverityColor, SeverityLevelEnum, ToggleEnumType } from 'constants/photoIntelligence';
import { FC } from 'react';
import CircleBox from './CircleBox';

interface KpDetailProps {
  item: IPhotoIntelligenceData;
}

const KpDetail: FC<KpDetailProps> = ({ item }) => {
  const { names, severityLevels, kpName } = item;
  const kpData = countOccurrencesArray(item.severityLevels, ToggleEnumType.SEVERITY_LEVELS);
  const convertedKpData = kpData?.map((item) => ({
    key: item.key,
    label: item.value,
    color: SeverityColor[item.key],
  }));

  const tooltipHashMap: Record<SeverityLevelEnum, string> = {
    [SeverityLevelEnum.HIGH]: '',
    [SeverityLevelEnum.MEDIUM]: '',
    [SeverityLevelEnum.LOW]: '',
    [SeverityLevelEnum.GROUND_FEATURES]: '',
  };
  severityLevels.forEach((level, index) => {
    tooltipHashMap[level] = names[index];
  });

  const renderTooltip = (key: SeverityLevelEnum) => {
    return tooltipHashMap[key];
  };

  return (
    <Box
      sx={{
        background: 'rgba(0, 0, 0, 0.5)',
        width: '120px',
        padding: '4px 8px',
        borderRadius: '10px',
      }}>
      <Stack>
        <Typography>{kpName}</Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '104px',
            mt: '4px',
          }}>
          {convertedKpData?.map((item) => (
            <Tooltip title={renderTooltip(item.key)}>
              <CircleBox key={item.key} color={item.color} label={item.label} isCollapseSpace />
            </Tooltip>
          ))}
        </Box>
      </Stack>
    </Box>
  );
};

export default KpDetail;
