import { Box, FormControl, FormControlLabel, MenuItem, Select, useTheme } from '@mui/material';
import { ICreateClientsFormData } from 'common/defines/clients';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { ErrorsDialog } from 'components/Dashboard/AnalyticsConfig/Form/ErrorDialog';
import { QUERY_KEY } from 'constants/constants';
import { FC, useState } from 'react';
import { Control, Controller, UseFormReturn } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getListDatabaseCropManagement } from 'services/clients/apiClient.services';

export interface IOptions {
  id: number;
  value: string;
  label: string;
}

interface SwitchCropManagementProps {
  control: Control<ICreateClientsFormData, any>;
  form: UseFormReturn<ICreateClientsFormData, any>;
}

export const SwitchCropManagement: FC<SwitchCropManagementProps> = ({ control, form }) => {
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [listDatabases, setListDatabases] = useState<IOptions[]>([]);
  const {
    watch,
    setValue,
    formState: { errors },
  } = form;
  const theme = useTheme();
  const isShowSelectCropManagement = watch('enableCropManagement');

  useQuery([QUERY_KEY.LIST_DATABASE_CROP_MANAGEMENT], () => getListDatabaseCropManagement(), {
    onSuccess(res) {
      const resData = res.data as { name: string }[];
      const newListDatabases = resData.map((item, index) => ({
        id: index + 1,
        value: item.name,
        label: item.name,
      }));
      setListDatabases(newListDatabases);
    },
  });

  const closeErrorModal = () => {
    setOpenErrorModal(false);
  };

  return (
    <Box className="basic-info">
      <Controller
        name="enableCropManagement"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            sx={{
              ml: 0.5,
              mt: '23px',
            }}
            label="Crop Management"
            control={
              <>
                <SwitchCustom
                  {...field}
                  checked={field.value}
                  onChange={(e: any) => {
                    const checked = e.target.checked;
                    field.onChange(checked);
                    if (!checked) {
                      setValue('databaseName', '');
                    } else {
                      setOpenErrorModal(true);
                    }
                  }}
                />
              </>
            }
          />
        )}
      />

      <Box className="basic-info" sx={{ my: '23px' }}>
        {isShowSelectCropManagement && (
          <Controller
            name="databaseName"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth size="small">
                <Select
                  defaultValue=""
                  labelId="databaseName"
                  id="databaseName"
                  value={listDatabases?.length ? field.value : ''}
                  onChange={field.onChange}>
                  {listDatabases?.map((option) => (
                    <MenuItem
                      sx={{
                        '.MuiChip-root': {
                          backgroundColor: 'rgba(35,190,106,0.1)',
                        },
                        '.MuiChip-label': {
                          pr: 2.5,
                          color: theme.palette.primary.main,
                        },
                      }}
                      key={option.id}
                      value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.databaseName && (
                  <Box
                    component="div"
                    className="errorMsg"
                    sx={{
                      fontFamily: 'Barlow',
                      fontSize: '14px',
                      lineHeight: '12px',
                      color: '#FF4646',
                      mt: 1,
                    }}>
                    {errors.databaseName.message}
                  </Box>
                )}
              </FormControl>
            )}
          />
        )}
      </Box>

      <ErrorsDialog
        title="Warning"
        open={openErrorModal}
        onClose={closeErrorModal}
        onCancel={closeErrorModal}
        data="Making sure you want to add crop management data!"
        submit={false}
      />
    </Box>
  );
};
