import VideoCameraBackOutlinedIcon from '@mui/icons-material/VideoCameraBackOutlined';
import { Box, useTheme } from '@mui/material';
import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeIsShowVideoViewModal,
  photoIntelligenceSelector,
} from 'store/slices/map-view/photoIntelligenceAnalytics';

interface VideoViewProps {
  id: string;
}

const VideoView: FC<VideoViewProps> = ({ id }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {
    videoViewModal: { id: selectedId },
  } = useAppSelector(photoIntelligenceSelector);

  return (
    <Box onClick={() => dispatch(changeIsShowVideoViewModal({ id: id, isShow: true }))}>
      <VideoCameraBackOutlinedIcon
        fontSize="medium"
        sx={{ cursor: 'pointer', color: selectedId === id ? theme.palette.primary.main : theme.palette.color.grey2 }}
      />
    </Box>
  );
};

export default VideoView;
