import ReportIcon from '@mui/icons-material/Report';
import { Box, Grid, IconButton, InputLabel, TextField, Tooltip, useTheme } from '@mui/material';
import { AxiosResponse } from 'axios';
import { FILE_TYPE, mapFileLabel } from 'common/defines/constants';
import { ButtonCustom } from 'components/Common/ButtonCustom';
import SelectFile from 'components/Common/SelectFile';
import { QUERY_KEY } from 'constants/constants';
import { Formik } from 'formik';
import { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  deleteFileJsonphotointelligenceAnalytic,
  getRasterDetails,
  updateAnalyticRelabel,
} from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { anaLyticConfigSelector, changePageRasterPagination } from 'store/slices/analyticsConfigSlice';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { ErrorsDialog } from './ErrorDialog';

interface PhotoIntelligenceFormProps {
  sensorId: string;
  createAnalyticFunc: (params: any) => Promise<AxiosResponse>;
  deleteFileGeojson: (id: string) => Promise<AxiosResponse<any, any>>;
}

const PhotoIntelligenceForm: FC<PhotoIntelligenceFormProps> = ({ sensorId, createAnalyticFunc, deleteFileGeojson }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const formRef = useRef<any>();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { pageRaster } = useAppSelector(anaLyticConfigSelector);
  const { levelId } = useAppSelector(mapViewSelector);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [dataErrors, setDataErrors] = useState(null as any);

  const closeErrorModal = () => {
    setOpenErrorModal(false);
  };

  const { data: analyticDetails, refetch } = useQuery(
    [QUERY_KEY.RASTER_DETAILS, sensorId],
    () => getRasterDetails(sensorId),
    {
      enabled: !!sensorId,
    }
  );

  const createAnalyticMutation = useMutation(createAnalyticFunc, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY.ANALYSIS_UPLOAD_HISTORY);
      refetch();
      toast.success(`Update Photo Intelligence Analytic Successful`, { toastId: 1 });
    },
    onError: (e: any) => {
      setOpenErrorModal(true);
      setDataErrors(e?.data?.message);
    },
  });

  const updateAnalyticMutation = useMutation((data: any) => updateAnalyticRelabel(data), {
    onSuccess: (res: any) => {
      refetch();
      toast.success('Update Label Successful', { toastId: 1 });
      dispatch(changePageRasterPagination(1));
      queryClient.invalidateQueries([QUERY_KEY.RASTER_PAGINATION, pageRaster, levelId]);
    },
    onError: (e: any) => {
      setOpenErrorModal(true);
      setDataErrors(e?.data?.message || 'Something went wrong!');
    },
  });

  const initialFields = useMemo(() => {
    let initParam: any = { zipFile: undefined, videoFile: undefined };
    if (analyticDetails?.data?.relabel) {
      initParam.reLabel = analyticDetails.data.relabel;
    }
    if (analyticDetails?.data?.uploadHistory) {
      analyticDetails.data.uploadHistory.forEach((_item: any) => {
        if (_item?.fileType === 'VIDEO') {
          initParam.videoFile = _item;
        } else if (_item?.layerType) {
          initParam[_item.layerType] = _item;
        } else {
          initParam.zipFile = _item;
        }
      });
    }
    return initParam;
  }, [analyticDetails]);

  const handleDelete = useMutation((id: string) => deleteFileJsonphotointelligenceAnalytic(id), {
    onSuccess: () => {
      dispatch(changePageRasterPagination(1));
      queryClient.invalidateQueries([QUERY_KEY.RASTER_PAGINATION, pageRaster, levelId]);
    },
    onError: () => {
      toast.error('Delete Raster Analytics Error', { toastId: 1 });
    },
  });

  const onDelete = (id: string) => {
    return handleDelete.mutateAsync(id);
  }; //todo

  const handleSubmitUpload = () => {
    toast.info('Upload time is long, please wait', {
      autoClose: 100000,
    });
  };

  const renderRelabelPart = (values: any, handleChange: any) => {
    return (
      <Grid display="flex" alignItems="center" mt={2} justifyContent="space-between" sx={{ marginBottom: '50px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
            {t('trans.relabel')}
          </InputLabel>
          {/* todo */}
          <Tooltip title={t('trans.type_in_the_custom_name_for_the_field_analytics')}>
            <IconButton>
              <ReportIcon sx={{ fontSize: '18px' }} />
            </IconButton>
          </Tooltip>
        </Box>
        <TextField
          type="text"
          size="small"
          name="reLabel"
          onChange={handleChange}
          value={values.reLabel}
          sx={{
            width: '350px',
            mt: '7px',
            '.MuiOutlinedInput-root': {
              height: '40px',
              justifyContent: 'end',
            },
          }}
        />
      </Grid>
    );
  };

  return (
    <div>
      <Formik
        innerRef={formRef}
        initialValues={Object.assign(initialFields)}
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          const formData = new FormData();
          formData.append('analysisId', sensorId);
          formData.append('dataFile', values.zipFile || '');
          formData.append('videoFile', values.videoFile || '');

          // Only new file upload has lastModified field
          const hasNewFile = Object.values(values).find((item: any) => item?.lastModified);

          if (!!values.reLabel) {
            updateAnalyticMutation.mutate(
              {
                id: sensorId,
                relabel: values.reLabel,
              },
              {
                onSuccess: () => {
                  toast.success('Delete Raster Analytics Successful', { toastId: 1 });
                  resetForm();
                },
              }
            );
          }

          if (hasNewFile) {
            createAnalyticMutation.mutate(formData, {
              onSuccess: () => {
                resetForm();
              },
            });
          }
        }}>
        {({ values, setFieldValue, handleSubmit, submitForm, handleChange }) => (
          <form onSubmit={handleSubmit}>
            <Grid display="flex" width="100%">
              <Grid sx={{ maxWidth: '700px', width: '700px' }}>
                {renderRelabelPart(values, handleChange)}
                <Grid display="flex" flexDirection="row" alignItems="center" mt={2} justifyContent="space-between">
                  <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                    {mapFileLabel[FILE_TYPE.PHOTO_INTELLIGENCE_ZIP]}
                  </InputLabel>
                  <SelectFile
                    acceptFile={'.zip'}
                    fileSelected={values.zipFile}
                    disabled={createAnalyticMutation.isLoading}
                    onSelectFile={(file: any) => {
                      setFieldValue('zipFile', file);
                    }}
                    analysisId={sensorId}
                    fileType={FILE_TYPE.PHOTO_INTELLIGENCE_ZIP}
                    deleteFileGeojson={onDelete} //todo
                  />
                </Grid>
                <Grid display="flex" flexDirection="row" alignItems="center" mt={2} justifyContent="space-between">
                  <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                    {mapFileLabel[FILE_TYPE.PHOTO_INTELLIGENCE_VIDEO]}
                  </InputLabel>
                  <SelectFile
                    acceptFile={'.mp4|.m4v|.mkv|.mov|.mpg|.wmv'}
                    fileSelected={values.videoFile}
                    disabled={createAnalyticMutation.isLoading}
                    onSelectFile={(file: any) => {
                      setFieldValue('videoFile', file);
                    }}
                    analysisId={sensorId}
                    fileType={FILE_TYPE.PHOTO_INTELLIGENCE_VIDEO}
                    deleteFileGeojson={onDelete} //todo
                  />
                </Grid>
              </Grid>
              <Grid marginLeft="auto">
                <ButtonCustom
                  border="0px"
                  colorLabel="#fff"
                  sx={{
                    width: '160px',
                    height: '1.4375em',
                  }}
                  title={t('trans.save')}
                  backgroundColor={theme.palette.primary.main}
                  onClick={() => {
                    handleSubmitUpload();
                    submitForm();
                  }}
                  disabled={createAnalyticMutation.isLoading}
                  loading={createAnalyticMutation.isLoading}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <ErrorsDialog
        title="Error Upload"
        open={openErrorModal}
        onClose={closeErrorModal}
        data={dataErrors}
        onCancel={closeErrorModal}
        submit={false}
      />
    </div>
  );
};

export default PhotoIntelligenceForm;
