import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FrequencyEnum,
  IPhotoIntelligenceData,
  IToggle,
  PhotoIntelligenceStatusEnum,
  SeverityLevelEnum,
} from 'constants/photoIntelligence';
import { RootState } from 'store/store';

interface IShowModal {
  id: string;
  isShow: boolean;
}

interface IAssociatedKp {
  date: string;
  id: string;
  isDone: boolean;
}

interface IBarDetail {
  id: string;
  isOpenCollapse: boolean;
}

type ISelectedKpInfo = Pick<IPhotoIntelligenceData, '_id' | 'lat' | 'lon'> & { isDone: boolean };

interface IPhotoIntelligence {
  status: {
    isSelectAll: boolean;
    listData: IToggle[];
  };
  severityLevel: {
    isSelectAll: boolean;
    listData: IToggle[];
  };
  frequency: {
    isSelectAll: boolean;
    listData: IToggle[];
  };
  imageViewModal: IShowModal;
  videoViewModal: IShowModal;
  commentViewModal: IShowModal;
  associatedKp: IAssociatedKp;
  barDetailList: IBarDetail[];
  selectedKp: ISelectedKpInfo;
}

const initialState: IPhotoIntelligence = {
  status: {
    isSelectAll: true,
    listData: [
      { key: PhotoIntelligenceStatusEnum.OPEN, label: 'trans.open', value: true },
      { key: PhotoIntelligenceStatusEnum.CLOSED, label: 'trans.close', value: true },
    ],
  },
  severityLevel: {
    isSelectAll: true,
    listData: [
      { key: SeverityLevelEnum.HIGH, label: 'trans.high', value: true },
      { key: SeverityLevelEnum.MEDIUM, label: 'trans.medium', value: true },
      { key: SeverityLevelEnum.LOW, label: 'trans.low', value: true },
      { key: SeverityLevelEnum.GROUND_FEATURES, label: 'trans.ground_features', value: true },
    ],
  },
  frequency: {
    isSelectAll: true,
    listData: [
      { key: FrequencyEnum.WEEKLY, label: 'trans.weekly', value: true },
      { key: FrequencyEnum.MONTHLY, label: 'trans.monthly', value: true },
      { key: FrequencyEnum.THREE_MONTHS, label: 'trans.3_months', value: true },
      { key: FrequencyEnum.SIX_MONTHS, label: 'trans.6_months', value: true },
    ],
  },
  imageViewModal: {
    id: '',
    isShow: false,
  },
  videoViewModal: {
    id: '',
    isShow: false,
  },
  commentViewModal: {
    id: '',
    isShow: false,
  },
  barDetailList: [],
  associatedKp: {
    date: '',
    id: '',
    isDone: true,
  },
  selectedKp: {
    _id: '',
    lat: 0,
    lon: 0,
    isDone: true,
  },
};

export const photoIntelligenceSlice = createSlice({
  name: 'photoIntelligence',
  initialState,
  reducers: {
    changeIsSelectAllStatus(state, action: PayloadAction<boolean>) {
      state.status.isSelectAll = action.payload;
      state.status.listData = state.status.listData.map((item) => ({ ...item, value: action.payload }));
    },
    changeStatusToggle(state, action: PayloadAction<Pick<IToggle, 'key' | 'value'>>) {
      state.status.listData = state.status.listData.map((item) => ({
        ...item,
        value: item.key === action.payload.key ? action.payload.value : item.value,
      }));
      if (state.status.listData.some((item) => !item.value)) {
        state.status.isSelectAll = false;
      }
      if (state.status.listData.every((item) => item.value)) {
        state.status.isSelectAll = true;
      }
    },

    changeIsSelectAllSeverityLevel(state, action: PayloadAction<boolean>) {
      state.severityLevel.isSelectAll = action.payload;
      state.severityLevel.listData = state.severityLevel.listData.map((item) => ({ ...item, value: action.payload }));
    },
    changeSeverityLevelToggle(state, action: PayloadAction<Pick<IToggle, 'key' | 'value'>>) {
      state.severityLevel.listData = state.severityLevel.listData.map((item) => ({
        ...item,
        value: item.key === action.payload.key ? action.payload.value : item.value,
      }));
      if (state.severityLevel.listData.some((item) => !item.value)) {
        state.severityLevel.isSelectAll = false;
      }
      if (state.severityLevel.listData.every((item) => item.value)) {
        state.severityLevel.isSelectAll = true;
      }
    },

    changeIsSelectAllFrequency(state, action: PayloadAction<boolean>) {
      state.frequency.isSelectAll = action.payload;
      state.frequency.listData = state.frequency.listData.map((item) => ({ ...item, value: action.payload }));
    },
    changeFrequencyToggle(state, action: PayloadAction<Pick<IToggle, 'key' | 'value'>>) {
      state.frequency.listData = state.frequency.listData.map((item) => ({
        ...item,
        value: item.key === action.payload.key ? action.payload.value : item.value,
      }));
      if (state.frequency.listData.some((item) => !item.value)) {
        state.frequency.isSelectAll = false;
      }
      if (state.frequency.listData.every((item) => item.value)) {
        state.frequency.isSelectAll = true;
      }
    },

    changeIsShowImageViewModal(state, action: PayloadAction<IShowModal>) {
      state.imageViewModal = action.payload;
    },
    changeIsShowVideoViewModal(state, action: PayloadAction<IShowModal>) {
      state.videoViewModal = action.payload;
    },
    changeIsShowCommentViewModal(state, action: PayloadAction<IShowModal>) {
      state.commentViewModal = action.payload;
    },

    changeAssociatedKpInfo(state, action: PayloadAction<IAssociatedKp>) {
      state.associatedKp = action.payload;
    },
    changeDetailBarList(state, action: PayloadAction<IBarDetail[]>) {
      state.barDetailList = action.payload;
    },
    changeSelectedKpInfo(state, action: PayloadAction<ISelectedKpInfo>) {
      state.selectedKp = action.payload;
    },

    resetPhotoIntelligenceData(state) {
      state = initialState;
    },
  },
});

export const {
  changeIsSelectAllStatus,
  changeIsSelectAllSeverityLevel,
  changeStatusToggle,
  changeSeverityLevelToggle,
  changeIsSelectAllFrequency,
  changeFrequencyToggle,
  changeIsShowImageViewModal,
  changeIsShowVideoViewModal,
  changeIsShowCommentViewModal,
  changeAssociatedKpInfo,
  changeDetailBarList,
  changeSelectedKpInfo,
  resetPhotoIntelligenceData,
} = photoIntelligenceSlice.actions;

export const photoIntelligenceSelector = (state: RootState) => state.photoIntelligence;

export default photoIntelligenceSlice.reducer;
