import { Grid, useTheme } from '@mui/material';
import { IAnalyticsTabs3DData } from 'common/defines/clients';
import { CROP_HIERARCHY, PIE_CHART_NO_DATA_LABEL, QUERY_KEY, SEVERITY } from 'constants/constants';
import ReactEcharts, { EChartsOption } from 'echarts-for-react';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getDashboardTripStatusByBlockProduct } from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setLogisticTripStatus } from 'store/slices/dashboardWidgetSlice';
import { bottomBarSelector } from 'store/slices/map-view/bottomBarSlice';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { getColorPaletteForAnalyticsTab } from '../analyticsTabConstants';

export const TripStatusChart = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { clientId } = useParams();
  const { selectedLevelHierarchyList } = useAppSelector(mapViewSelector);
  const [tripStatusByBlock, setTripStatusByBlock] = useState<EChartsOption>({});
  const [tripStatusDataByBlockProduct, setTripStatusDataByBlockProduct] = useState<EChartsOption>({});
  const { dateRange } = useAppSelector(bottomBarSelector);
  const [levelHierarchyObject, setLevelHierarchyObject] = useState<any>(null);

  const findSeverity = (totalValue: number = 0, value: number = 0): string => {
    const partSize = totalValue / 3;
    if (totalValue && value) {
      if (value <= partSize) {
        return SEVERITY.LOW;
      } else if (value <= partSize * 2) {
        return SEVERITY.MEDIUM;
      } else {
        return SEVERITY.HIGH;
      }
    } else {
      return SEVERITY.LOW;
    }
  };

  const colorPalette = getColorPaletteForAnalyticsTab(theme.palette.background.paper);

  const formLevelBasedQuery = () => {
    let levelData: any[] =
      selectedLevelHierarchyList && selectedLevelHierarchyList.length ? [...selectedLevelHierarchyList] : [];

    if (levelData.length) {
      let estateObj = levelData.filter((ele) => ele?.level === 1)[0] || {};
      let divisionObj = levelData.filter((ele) => ele?.level === 2)[0] || {};
      let blockObj = levelData.filter((ele) => ele?.level === 3)[0] || {};
      setLevelHierarchyObject({
        estateId: estateObj?.cropManagementAssetId || '',
        divisionName: divisionObj?.name || '',
        blockName: blockObj?.name || '',
      });
    } else {
      setLevelHierarchyObject(null);
    }
  };

  useEffect(() => {
    formLevelBasedQuery();
  }, [selectedLevelHierarchyList]);

  useEffect(() => {
    refetchTripStatusByBlockProduct();
  }, [dateRange, levelHierarchyObject]);

  const { refetch: refetchTripStatusByBlockProduct } = useQuery(
    [QUERY_KEY.GET_TRIP_STATUS_BY_BLOCK_PRODUCT, dateRange, levelHierarchyObject],
    () =>
      getDashboardTripStatusByBlockProduct({
        clientId: clientId!,
        fromDate: dateRange.startDate,
        toDate: dateRange.endDate,
        estateId: levelHierarchyObject?.estateId || '',
        divName: levelHierarchyObject?.divisionName || '',
        blockName: levelHierarchyObject?.blockName || '',
      }),
    {
      onSuccess: (data: any) => {
        const result = [
          {
            value: data.data.firstRow[0].running,
            name: 'Running',
          },
          {
            value: data.data.firstRow[0].confirmed,
            name: 'Confirmed',
          },
        ];

        const tripStatusByBlockPie = {
          title: {
            text: 'Trip Status',
            show: true,
            subtext: 'Second Weight, by Product',
          },
          tooltip: {
            trigger: 'item',
            formatter: `{b} <b>{c}</b>`,
          },
          series: [
            {
              name: 'Testing',
              type: 'pie',
              radius: ['40%', '70%'],
              top: '10%',
              itemStyle: {
                borderRadius: 10,
                borderWidth: 2,
                borderColor: colorPalette.borderColor,
              },
              label: {
                fontSize: 12,
              },
              color: colorPalette.pieChart,
              data: result,
            },
          ],
        };

        let testing = new Map();
        let xAxis: any[] = [];
        let runningData: any[] = [];
        let confirmedData: any[] = [];

        data.data.secondRow.forEach((item: any) => {
          let currentConfirmedTrips = testing.get(item.Block)?.confirmedTrips || 0;
          let currentRunningTrips = testing.get(item.Block)?.runningTrips || 0;

          testing.set(item.Product, {
            name: item.Product,
            confirmedTrips: currentConfirmedTrips + item.Confirmed,
            runningTrips: currentRunningTrips + item.Trips,
          });
        });

        Array.from(testing.values(), ({ name, confirmedTrips, runningTrips }) => {
          xAxis.push(name);
          runningData.push(runningTrips);
          confirmedData.push(confirmedTrips);
        });

        const tripStatusByBlockStackedBar = {
          color: colorPalette.stackedBarChart,
          emphasis: {
            label: {
              show: true,
              fontSize: 10,
              fontWeight: 'bold',
            },
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          title: {
            show: false,
            text: 'Trip Status',
            subtext: 'by Block',
          },
          xAxis: {
            data: xAxis?.length > 0 ? xAxis : ['No Data Avaliable'],
            axisLabel: { interval: 0, rotate: 30 },
          },
          yAxis: {},
          series: [
            {
              name: 'Running',
              data: runningData?.length ? runningData : PIE_CHART_NO_DATA_LABEL,
              type: 'bar',
              stack: 'total',
              itemStyle: {},
            },
            {
              name: 'Confirmed',
              data: confirmedData?.length ? confirmedData : PIE_CHART_NO_DATA_LABEL,
              type: 'bar',
              stack: 'total',
              itemStyle: {},
            },
          ],
        };

        let logisticSecondRowData =
          data?.data?.secondRow && data?.data?.secondRow.length ? [...data?.data?.secondRow] : [];
        let groupKey: string = CROP_HIERARCHY.LOCATION;
        if (levelHierarchyObject?.estateId && !levelHierarchyObject?.divisionName && !levelHierarchyObject?.blockName) {
          groupKey = CROP_HIERARCHY.DIV_NAME;
        } else if (
          levelHierarchyObject?.estateId &&
          levelHierarchyObject?.divisionName &&
          !levelHierarchyObject?.blockName
        ) {
          groupKey = CROP_HIERARCHY.BLOCK_NAME;
        } else if (
          levelHierarchyObject?.estateId &&
          levelHierarchyObject?.divisionName &&
          levelHierarchyObject?.blockName
        ) {
          groupKey = CROP_HIERARCHY.BLOCK_NAME;
        } else {
          groupKey = CROP_HIERARCHY.LOCATION;
        }

        const groupedChartBarData: any = {};
        for (let item of logisticSecondRowData) {
          const name = item[groupKey];
          if (!groupedChartBarData[name]) {
            groupedChartBarData[name] = 0;
          }

          groupedChartBarData[name] += item.Netweight / 1000;
        }

        let mappedChartBarData = Object.keys(groupedChartBarData).map((item) => ({
          name: item,
          value: groupedChartBarData[item],
        }));

        let finalChartBarData: IAnalyticsTabs3DData[] = [];
        if (mappedChartBarData.length) {
          const highestValueObject = mappedChartBarData.reduce((prev: any, current: any) => {
            return prev.value > current.value ? prev : current;
          }, {});

          finalChartBarData = mappedChartBarData.map((ele: any) => {
            return {
              ...ele,
              severity: findSeverity(highestValueObject?.value || 0, ele?.value || 0),
            };
          });
        }

        setTripStatusByBlock(tripStatusByBlockStackedBar);
        setTripStatusDataByBlockProduct(tripStatusByBlockPie);
        dispatch(setLogisticTripStatus(finalChartBarData));
      },
    }
  );

  return (
    <Grid>
      <ReactEcharts option={tripStatusDataByBlockProduct} />
      <ReactEcharts option={tripStatusByBlock} />
    </Grid>
  );
};
