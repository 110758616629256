import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import { Box } from '@mui/material';
import KpDetail from 'components/KpDetail';
import { QUERY_KEY } from 'constants/constants';
import { IPhotoIntelligenceData } from 'constants/photoIntelligence';
import useCheckingEnableAllLevels from 'hooks/common/useCheckingEnableAllLevels';
import { useMemo, useState } from 'react';
import { Marker } from 'react-map-gl';
import { useQuery } from 'react-query';
import { getPhotoIntelligenceAnalysis } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';

const usePhotoIntelligenceAnalytics = () => {
  const [popupId, setPopupId] = useState<string>('');
  const { analyticId } = useAppSelector(mapViewSelector);
  const { isEnableAnalyticAllLevels } = useCheckingEnableAllLevels();
  const { data: listData } = useQuery<IPhotoIntelligenceData[]>(
    [QUERY_KEY.PHOTO_INTELLIGENCE_ANALYSIS, analyticId],
    () => getPhotoIntelligenceAnalysis(analyticId!),
    {
      enabled: !!analyticId && isEnableAnalyticAllLevels,
    }
  );

  const handleMouseEnter = (id: string) => {
    setPopupId(id);
  };

  const handleMouseLeave = () => {
    setPopupId('');
  };

  const layerPhotoIntelligence = useMemo(() => {
    return (
      <>
        {listData?.map((item) => (
          <Marker longitude={item.lon} latitude={item.lat} anchor="center">
            <Box
              position="relative"
              onMouseEnter={() => handleMouseEnter(item._id)}
              onMouseLeave={handleMouseLeave}
              sx={{ height: '24px', width: '24px' }}>
              <FmdGoodOutlinedIcon color="error" />
              {popupId === item._id && (
                <Box sx={{ position: 'absolute', left: '22px', top: 0, zIndex: 100 }}>
                  <KpDetail key={item._id} item={item} />
                </Box>
              )}
            </Box>
          </Marker>
        ))}
      </>
    );
  }, [popupId, listData]);

  return { layerPhotoIntelligence };
};

export default usePhotoIntelligenceAnalytics;
