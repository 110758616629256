import { Button, Grid, useTheme } from '@mui/material';
import DataViewModal from 'components/OverviewClient/DataViewModal';
import { CROP_HIERARCHY, PIE_CHART_NO_DATA_LABEL, QUERY_KEY } from 'constants/constants';
import ReactEcharts, { EChartsOption } from 'echarts-for-react';
import GlobalModel from 'echarts/types/src/model/Global';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getDashboardProductionCostByBlock } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { getColorPaletteForAnalyticsTab, getTopThreeSortedPieChartData, IPieChatData } from '../analyticsTabConstants';

export const CostPerBlock = (props: { dateRange: any }) => {
  const theme = useTheme();
  const { dateRange } = props;
  const { clientId } = useParams();
  const [dataViewOptions, setDataViewOptions] = useState<EChartsOption>({});
  const [dataViewModal, setDataViewModal] = useState<boolean>(false);
  const [productionCostPHData, setProductionCostPHData] = useState<EChartsOption>({});
  const [levelHierarchyObject, setLevelHierarchyObject] = useState<any>(null);
  const { selectedLevelHierarchyList } = useAppSelector(mapViewSelector);
  const [showAllData, setShowAllData] = useState(false);
  const [productionPieChartdata, setProductionPieChartdata] = useState<IPieChatData[]>([{ name: '', value: 0 }]);

  const iconPath =
    'path://M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,5v3H5V5H19z M19,10v4H5v-4H19z M5,19v-3h14v3H5z';
  const colorPalette = getColorPaletteForAnalyticsTab(theme.palette.background.paper);

  useEffect(() => {
    let levelData: any[] =
      selectedLevelHierarchyList && selectedLevelHierarchyList.length ? [...selectedLevelHierarchyList] : [];

    if (levelData.length) {
      let estateObj = levelData.filter((ele) => ele?.level === 1)[0] || {};
      let divisionObj = levelData.filter((ele) => ele?.level === 2)[0] || {};
      let blockObj = levelData.filter((ele) => ele?.level === 3)[0] || {};
      setLevelHierarchyObject({
        estateId: estateObj?.cropManagementAssetId || '',
        divisionName: divisionObj?.name || '',
        blockName: blockObj?.name || '',
      });
    } else {
      setLevelHierarchyObject(null);
    }
  }, [selectedLevelHierarchyList]);

  const pieDataRevamp = (pieList: IPieChatData[]) => {
    setProductionCostPHData((prevState: any) => ({
      ...prevState,
      series: [{ ...prevState?.series[0], data: pieList }],
    }));
  };

  useEffect(() => {
    if (productionPieChartdata?.length) {
      if (showAllData) {
        pieDataRevamp(productionPieChartdata);
      } else if (productionPieChartdata?.length > 3) {
        pieDataRevamp(getTopThreeSortedPieChartData(productionPieChartdata));
      }
    }
  }, [showAllData, productionPieChartdata]);

  useQuery(
    [QUERY_KEY.GET_PRODUCTION_COST, dateRange, levelHierarchyObject],
    () =>
      getDashboardProductionCostByBlock({
        clientId: clientId!,
        year: moment(dateRange.startDate).format('YYYY').toString(),
        month: moment(dateRange.startDate).format('M').toString(),
        estateId: levelHierarchyObject?.estateId || '',
        divName: levelHierarchyObject?.divisionName || '',
        blockName: levelHierarchyObject?.blockName || '',
      }),
    {
      onSuccess: (data: any) => {
        let productionCost: any[] = [];
        let costData = data?.data && data?.data.length ? [...data?.data] : [];
        let groupKey: string = CROP_HIERARCHY.LOCATION;
        if (levelHierarchyObject?.estateId && !levelHierarchyObject?.divisionName && !levelHierarchyObject?.blockName) {
          groupKey = CROP_HIERARCHY.DIV_NAME;
        } else if (
          levelHierarchyObject?.estateId &&
          levelHierarchyObject?.divisionName &&
          !levelHierarchyObject?.blockName
        ) {
          groupKey = CROP_HIERARCHY.BLOCK_NAME;
        } else if (
          levelHierarchyObject?.estateId &&
          levelHierarchyObject?.divisionName &&
          levelHierarchyObject?.blockName
        ) {
          groupKey = CROP_HIERARCHY.BLOCK_NAME;
        } else {
          groupKey = CROP_HIERARCHY.LOCATION;
        }

        const groupedChartProductionCostData: any = {};
        const groupedChartBarData: any = {};
        for (let item of costData) {
          const name = item[groupKey];
          if (!groupedChartBarData[name]) {
            groupedChartBarData[name] = 0;
          }
          groupedChartBarData[name] += (item?.totalYOPMillwieght || 0) / 1000;

          if (!groupedChartProductionCostData[name]) {
            groupedChartProductionCostData[name] = 0;
          }

          groupedChartProductionCostData[name] += item?.perHectrageCost || 0;
        }

        productionCost = Object.keys(groupedChartProductionCostData).map((item) => ({
          name: item,
          value: groupedChartProductionCostData[item] ? groupedChartProductionCostData[item].toFixed(2) : 0,
        }));

        const productionCostByBlockDonut = {
          color: colorPalette.donutProductionChart,
          colorBy: 'series',
          tooltip: {
            trigger: 'item',
            formatter: `{b} <b>RM {c}</b>`,
          },
          title: {
            text: 'Production Cost',
            show: true,
            subtext: 'Per Hectare, Block',
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },

              saveAsImage: { show: true },
              myTool1: {
                show: true,
                readOnly: true,
                title: 'Data View',
                onclick: function (opt: GlobalModel) {
                  handleDataViewModal(true, opt, 'productionCostPerHectare');
                },
                icon: iconPath,
              },
            },
          },
          series: [
            {
              type: 'pie',
              radius: ['40%', '70%'],
              top: '10%',
              avoidLabelOverlap: true,
              itemStyle: {
                borderRadius: 10,
                borderWidth: 2,
                borderColor: colorPalette.borderColor,
              },
              label: {
                show: true,
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 10,
                  fontWeight: 'bold',
                  formatter: 'RM {c}',
                },
              },
              labelLine: {
                show: true,
              },
              color: productionCost?.length ? null : ['#ddd'],
              data: productionCost?.length ? productionCost : PIE_CHART_NO_DATA_LABEL,
            },
          ],
        };
        setProductionPieChartdata(productionCost);
        setProductionCostPHData(productionCostByBlockDonut);
      },
    }
  );

  const handleDataViewModal = (isShow: boolean, EChartOption: EChartsOption, chartName: string) => {
    setDataViewOptions({ ...EChartOption, chartName: chartName });
    setDataViewModal(isShow);
  };

  return (
    <>
      <DataViewModal
        EChartsOption={dataViewOptions}
        dataViewModal={dataViewModal}
        setDataViewModal={setDataViewModal}
      />
      <Grid sx={{ position: 'relative' }}>
        {productionPieChartdata?.length > 3 && (
          <Button
            sx={{ position: 'absolute', right: '60px', zIndex: '1' }}
            onClick={() => setShowAllData(!showAllData)}>
            {showAllData ? 'Hide Others' : 'Show All'}
          </Button>
        )}
        <ReactEcharts option={productionCostPHData} />
      </Grid>
    </>
  );
};
