import { Box, Typography, useTheme } from '@mui/material';
import { countOccurrencesArray } from 'common/utils/count';
import { IPhotoIntelligenceData, SeverityLevelEnum, ToggleEnumType } from 'constants/photoIntelligence';
import ReactECharts from 'echarts-for-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { photoIntelligenceSelector } from 'store/slices/map-view/photoIntelligenceAnalytics';
import VisualizationTableCommon from './components/VisualizationTableCommon';

interface FindingChartProps {
  data: IPhotoIntelligenceData[] | undefined;
  isChartMode: boolean;
}

const FindingsChart: FC<FindingChartProps> = ({ data, isChartMode }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { status, severityLevel, frequency } = useAppSelector(photoIntelligenceSelector);

  const filteredListData = data
    ?.filter((item) =>
      status.listData
        .filter((each) => each.value)
        ?.map((each) => each.key)
        ?.includes(item.status)
    )
    ?.filter((item) =>
      severityLevel.listData
        .filter((each) => each.value)
        ?.map((each) => each.key)
        ?.some((key) => typeof key === 'string' && item.severityLevels.includes(key as SeverityLevelEnum))
    )
    ?.filter((item) =>
      frequency.listData
        .filter((each) => each.value)
        ?.map((each) => each.key)
        ?.includes(item.frequency)
    )
    ?.map((item) => ({
      names: item.names,
    }));

  const reducedListData = filteredListData?.reduce(
    (acc, cur) => {
      acc.names = [...acc.names, ...cur.names];
      return acc;
    },
    { names: [] }
  );

  const occurrenceData = countOccurrencesArray(reducedListData?.names || [], ToggleEnumType.FINDINGS);

  const finalListData = occurrenceData?.map((item) => ({
    value: item.value,
    name: item.key,
  }));

  const optionFinding = {
    tooltip: {
      trigger: 'item',
      position: function (point: number[]) {
        const posX = point[0] + 20;
        const posY = point[1];
        return [posX, posY];
      },
      textStyle: {
        fontSize: 11,
      },
    },
    legend: {
      type: 'scroll',
      orient: 'vertical',
      right: 0,
      top: 0,
      width: 'auto',
      textStyle: {
        color: theme.palette.mode === 'dark' ? '#fff' : '#000',
      },
      pageIconColor: theme.palette.mode === 'dark' ? '#fff' : '#000',
      pageIconInactiveColor: theme.palette.mode === 'dark' ? '#fff' : '#000',
      pageTextStyle: { color: theme.palette.mode === 'dark' ? '#fff' : '#000' },
      formatter: function (name: string) {
        return name.length > 20 ? name.slice(0, 20) + '...' : name;
      },
      tooltip: {
        show: true,
        textStyle: {
          fontSize: 11,
        },
        whiteSpace: 'pre-line',
        formatter: function (params: { name: string }) {
          const name = params.name;
          if (name.length > 40) {
            return name.match(/.{1,40}/g)?.join('<br/>') || name;
          }
          return name;
        },
      },
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['20%', '30%'],
        center: ['20%', '20%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center',
        },
        labelLine: {
          show: false,
        },
        left: 0,
        data: finalListData || [],
      },
    ],
  };

  const renderChart = <ReactECharts option={optionFinding} style={{ height: '300px', top: '20px' }}></ReactECharts>;
  const renderTable = <VisualizationTableCommon data={occurrenceData} fieldName="Finding" />;

  return (
    <Box style={{ alignItems: 'center', width: '100%' }}>
      <Typography>{t('trans.findings')}</Typography>
      {isChartMode ? renderChart : renderTable}
    </Box>
  );
};

export default FindingsChart;
