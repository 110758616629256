import { Box } from '@mui/material';
import { countOccurrences } from 'common/utils/count';
import CircleBox from 'components/KpDetail/CircleBox';
import {
  IPhotoIntelligenceMLevel,
  SeverityColor,
  SeverityLevelEnum,
  ToggleEnumType,
} from 'constants/photoIntelligence';
import { FC } from 'react';
import { useAppSelector } from 'store/hooks';
import { photoIntelligenceSelector } from 'store/slices/map-view/photoIntelligenceAnalytics';

interface PhotoIntelligenceMarkerProps {
  data: IPhotoIntelligenceMLevel[];
}

const PhotoIntelligenceMarker: FC<PhotoIntelligenceMarkerProps> = ({ data }) => {
  const flattedData = data?.map((item) => item.data)?.flat();
  const { status, severityLevel, frequency } = useAppSelector(photoIntelligenceSelector);

  const filteredListData = flattedData
    ?.filter((item) =>
      status.listData
        .filter((each) => each.value)
        ?.map((each) => each.key)
        ?.includes(item.status)
    )
    ?.filter((item) =>
      frequency.listData
        .filter((each) => each.value)
        ?.map((each) => each.key)
        ?.includes(item.frequency)
    )
    ?.map((item) => ({
      severityLevels: item.severityLevels.filter((ele) =>
        severityLevel.listData
          .filter((each) => each.value)
          ?.map((each) => each.key)
          ?.includes(ele)
      ),
    }));

  const finalListData = filteredListData?.reduce(
    (acc, cur) => {
      acc.severityLevels = [...acc.severityLevels, ...cur.severityLevels];
      return acc;
    },
    { severityLevels: [] }
  );
  const kpData = countOccurrences(finalListData?.severityLevels, ToggleEnumType.SEVERITY_LEVELS);

  const kpList = Object.entries(kpData).map(([key, value]) => ({
    key: key as SeverityLevelEnum,
    value: value as number,
  }));

  const convertedKpData = kpList?.map((item) => ({
    key: item.key,
    label: item.value,
    color: SeverityColor[item.key],
  }));

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '104px',
        my: '8px',
      }}>
      {convertedKpData?.map((item) => (
        <CircleBox key={item.key} color={item.color} label={item.label} />
      ))}
    </Box>
  );
};

export default PhotoIntelligenceMarker;
