import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { IUpdateProperties } from 'common/defines/clients';
import { QUERY_KEY } from 'constants/constants';
import { FC, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getListTableCropManagement } from 'services/clients/apiClient.services';

interface IOption {
  id: number;
  label: string;
  value: string;
}

interface SelectTableCropManagementProps {
  control: Control<IUpdateProperties, any>;
}

const SelectTableCropManagement: FC<SelectTableCropManagementProps> = ({ control }) => {
  const [listTables, setListTables] = useState<IOption[]>([]);

  useQuery([QUERY_KEY.LIST_TABLE_CROP_MANAGEMENT], getListTableCropManagement, {
    onSuccess(res) {
      const resData = res?.data as { name: string }[];
      const newListTables = resData.map((item, index) => ({
        id: index + 1,
        value: item.name,
        label: item.name,
      }));
      setListTables(newListTables);
    },
  });

  return (
    <Controller
      name={`tableName`}
      control={control}
      render={({ field }) => (
        <>
          <InputLabel>Table</InputLabel>
          <FormControl fullWidth size="small">
            <Select
              defaultValue=""
              labelId="tableName"
              id="tableName"
              size="small"
              value={listTables.length ? field.value : ''}
              onChange={field.onChange}>
              {listTables?.map((option) => (
                <MenuItem key={option.id} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
    />
  );
};

export default SelectTableCropManagement;
