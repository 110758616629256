import { IClientData } from 'common/defines/clients';
import { QUERY_KEY } from 'constants/constants';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientByIdNew } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';

const useCheckingEnableAllLevels = () => {
  const { clientId } = useParams();
  const { cropType, isLastLevelId, levelId } = useAppSelector(mapViewSelector);

  const { data: newClientData } = useQuery<IClientData>(
    [QUERY_KEY.CLIENT_DATA_NEW, clientId],
    () => getClientByIdNew(clientId || ''),
    {
      enabled: !!clientId,
    }
  );

  const { uploadAnalysisConfig } = newClientData || {};

  const foundAnalysis = uploadAnalysisConfig?.find((item) => item.cropType === cropType);

  const isEnableAnalyticAllLevels = !!levelId && (isLastLevelId || (foundAnalysis?.isUpload ?? false));

  return { isEnableAnalyticAllLevels };
};

export default useCheckingEnableAllLevels;
