export function compareKpStrings(a: string, b: string) {
  // Regular expression to extract numbers (e.g., '1.3' from 'kp 1.3')
  const regex = /kp\s*(\d+)\.(\d+)/i;

  const matchA = a.match(regex);
  const matchB = b.match(regex);

  if (!matchA || !matchB) {
    return a.localeCompare(b);
  }

  // Parse major and minor numbers
  const majorA = parseInt(matchA[1], 10);
  const minorA = parseInt(matchA[2], 10);

  const majorB = parseInt(matchB[1], 10);
  const minorB = parseInt(matchB[2], 10);

  // Compare major numbers
  if (majorA < majorB) return -1;
  if (majorA > majorB) return 1;

  // If major numbers are equal, compare minor numbers
  if (minorA < minorB) return -1;
  if (minorA > minorB) return 1;

  // If both major and minor numbers are equal
  return 0;
}
