import { InputLabel, MenuItem, Select } from '@mui/material';
import { IUpdateProperties } from 'common/defines/clients';
import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { IOption } from '.';

interface SelectNameCropManagementProps {
  control: Control<IUpdateProperties, any>;
  listNames: IOption[];
}

const SelectNameCropManagement: FC<SelectNameCropManagementProps> = ({ control, listNames }) => {
  return (
    <Controller
      name={`selectName`}
      control={control}
      render={({ field }) => (
        <>
          <InputLabel>Name</InputLabel>
          <Select
            defaultValue=""
            labelId="name"
            id="name"
            size="small"
            sx={{ width: '100%' }}
            value={listNames.length ? field.value : ''}
            onChange={field.onChange}>
            {listNames?.map((option) => (
              <MenuItem key={option.id} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
    />
  );
};

export default SelectNameCropManagement;
