import { Grid, InputLabel, styled } from '@mui/material';
import {
  FILE_TYPE,
  LABEL_DATA_RASTERS,
  LayerTypeEnum,
  TypeRaster,
  TYPE_ANALYTICS_MAP_VIEW,
} from 'common/defines/constants';
import { useMemo } from 'react';
import {
  createChlorophyllAnalytic,
  createChlorophyllPaddyAnalytic,
  createCustomFieldAnalytic,
  createElevationFieldAnalytic,
  createFissureAnalytic,
  createLeafPhenologyFieldAnalytic,
  createPhotoIntelligence,
  createPlantHealthAnalytic,
  createPlantHealthOilPalmAnalytic,
  createSlopeFieldAnalytic,
  createSoilErosionFieldAnalytic,
  createSoilWaterContentFieldAnalytic,
  createStressAnalytic,
  createStressFieldAnalytic,
  createVegetationEncroachmentAnalytic,
  createVigor,
  createVigorPaddyAnalytic,
  createWaterUptakeAnalytic,
  createWaterUptakeFieldAnalytic,
  deleteChlorophyllFieldGeojsonFile,
  deleteChlorophyllTreeGeojsonFile,
  deleteCircumferenceAnalysisData,
  deleteCo2SequestrationAnalysisData,
  deleteCropCoverAnalysisData,
  deleteCropPlantationData,
  deleteCrownAreaAnalysisData,
  deleteCustomFieldAnalysisData,
  deleteElevationFieldAnalysisData,
  deleteFissureAnalysisData,
  deleteGapAnalysisData,
  deleteLandUseAnalysisData,
  deleteLeafPhenologyFieldAnalysisData,
  deletePlantHealthAnalysisGeojsonFile,
  deletePlantHealthOilPalmGeojsonFile,
  deleteRasterAnalysisData,
  deleteSlopeFieldAnalysisData,
  deleteSoilErosionFieldAnalysisData,
  deleteSoilMoistureContentAnalysisData,
  deleteSoilWaterContentFieldAnalysisData,
  deleteStandCountAnalysisData,
  deleteStressFieldGeojsonFile,
  deleteStressTreeGeojsonFile,
  deleteTillerDensityAnalysisData,
  deleteTreeHeightAnalysisData,
  deleteTreeTiltFallenAnalysisData,
  deleteVacantAreaAnalysisData,
  deleteVegetationEncroachmentAnalysisData,
  deleteVirgoFieldGeojsonFile,
  deleteVirgoTreeGeojsonFile,
  deleteWatershedBasinData,
  deleteWaterUptakeFieldGeojsonFile,
  deleteWaterUptakeTreeGeojsonFile,
  deleteWeekInvasionAnalysisData,
  removePhotoIntelligenceGeojsonFile,
} from 'services/analytics/apiAnalyticsConfig.services';
import { useAppSelector } from 'store/hooks';
import { anaLyticConfigSelector } from 'store/slices/analyticsConfigSlice';
import { CircumferenceForm } from './Form/CircumferenceForm';
import { Co2SequestrationForm } from './Form/Co2SequestrationForm';
import CommonFieldForm from './Form/CommonFieldForm';
import CommonTreeForm from './Form/CommonTreeForm';
import { CropCoverForm } from './Form/CropCoverForm';
import { CrownAreaForm } from './Form/CrownForm';
import { GapForm } from './Form/GapForm';
import { LandUseForm } from './Form/LandUseForm';
import PhotoIntelligenceForm from './Form/PhotoIntelligenceForm';
import SoilMoistureContentForm from './Form/SoilMoistureContentForm';
import { StandCountForm } from './Form/StandCountForm';
import TillerDensityForm from './Form/TillerDensityForm';
import { TreeHeightForm } from './Form/TreeHeightForm';
import { TreeTiltFallenForm } from './Form/TreeTiltFallen';
import { UpdateRaster } from './Form/UpdateRaster';
import VacantAreaForm from './Form/VacantAreaForm';
import { WatershedBasinForm } from './Form/WatershedBasin';
import WeedInvasionForm from './Form/WeedInvasionForm';

const Card = styled(Grid)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  height: 'fit-content',
  minHeight: 'calc( 100vh - 400px )',
  padding: '0',
  display: 'flex',
  flexDirection: 'column',
}));

export const InfoRasterCard = () => {
  const { sensorId, typeOfAnalytics } = useAppSelector(anaLyticConfigSelector);

  const form = useMemo(() => {
    if (!sensorId) {
      return <></>;
    }
    switch (typeOfAnalytics) {
      case TypeRaster.RGB:
      case TypeRaster.RGB_MULTI_SPECTRAL:
        return (
          <UpdateRaster
            sensorId={sensorId}
            name={typeOfAnalytics as TypeRaster}
            deleteFileGeojson={deleteRasterAnalysisData}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.LAND_USE_ANALYSIS:
        return <LandUseForm sensorId={sensorId} deleteFileGeojson={deleteLandUseAnalysisData} />;
      case TYPE_ANALYTICS_MAP_VIEW.CROP_COVERAGE:
        return (
          <CropCoverForm
            sensorId={sensorId}
            deleteAnalysisData={deleteCropCoverAnalysisData}
            deleteCropPlantation={deleteCropPlantationData}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT:
        return <StandCountForm sensorId={sensorId} deleteFileGeojson={deleteStandCountAnalysisData} />;
      case TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS:
        return <CircumferenceForm sensorId={sensorId} deleteFileGeojson={deleteCircumferenceAnalysisData} />;
      case TYPE_ANALYTICS_MAP_VIEW.TREE_HEIGHT:
        return <TreeHeightForm sensorId={sensorId} deleteFileGeojson={deleteTreeHeightAnalysisData} />;
      case TYPE_ANALYTICS_MAP_VIEW.CO2_SEQUESTRATION:
        return <Co2SequestrationForm sensorId={sensorId} deleteFileGeojson={deleteCo2SequestrationAnalysisData} />;
      case TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA:
        return <CrownAreaForm sensorId={sensorId} deleteFileGeojson={deleteCrownAreaAnalysisData} />;
      case TYPE_ANALYTICS_MAP_VIEW.GAP:
        return <GapForm sensorId={sensorId} deleteFileGeojson={deleteGapAnalysisData} />;
      case TYPE_ANALYTICS_MAP_VIEW.TREE_TILT_FALLEN:
        return <TreeTiltFallenForm sensorId={sensorId} deleteFileGeojson={deleteTreeTiltFallenAnalysisData} />;
      case TYPE_ANALYTICS_MAP_VIEW.WEED_INVASION:
        return <WeedInvasionForm sensorId={sensorId} deleteFileGeojson={deleteWeekInvasionAnalysisData} />;
      case TYPE_ANALYTICS_MAP_VIEW.VACANT_AREA:
        return <VacantAreaForm sensorId={sensorId} deleteFileGeojson={deleteVacantAreaAnalysisData} />;
      case TYPE_ANALYTICS_MAP_VIEW.TILLER_DENSITY:
        return <TillerDensityForm sensorId={sensorId} deleteFileGeojson={deleteTillerDensityAnalysisData} />;
      case TYPE_ANALYTICS_MAP_VIEW.SOIL_MOISTURE_CONTENT:
        return (
          <SoilMoistureContentForm sensorId={sensorId} deleteFileGeojson={deleteSoilMoistureContentAnalysisData} />
        );
      case TYPE_ANALYTICS_MAP_VIEW.WATERSHED_BASIN:
        return <WatershedBasinForm sensorId={sensorId} deleteFileGeojson={deleteWatershedBasinData} />;
      case TYPE_ANALYTICS_MAP_VIEW.PHOTO_INTELLIGENCE:
        return (
          <PhotoIntelligenceForm
            sensorId={sensorId}
            createAnalyticFunc={createPhotoIntelligence} //todo
            deleteFileGeojson={removePhotoIntelligenceGeojsonFile}
          />
        ); //todo
      case TYPE_ANALYTICS_MAP_VIEW.VIGOR_TREE:
        return (
          <CommonTreeForm
            sensorId={sensorId}
            createAnalyticFunc={createVigor}
            fileType={FILE_TYPE.VIGOR_FILE}
            label={LABEL_DATA_RASTERS.VIGOR_TREE}
            layerType={LayerTypeEnum.VIGOR_TREE}
            deleteFileGeojson={deleteVirgoTreeGeojsonFile}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.STRESS_TREE:
        return (
          <CommonTreeForm
            sensorId={sensorId}
            createAnalyticFunc={createStressAnalytic}
            fileType={FILE_TYPE.STRESS_FILE}
            label={LABEL_DATA_RASTERS.STRESS_TREE}
            layerType={LayerTypeEnum.STRESS_TREE}
            deleteFileGeojson={deleteStressTreeGeojsonFile}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_TREE:
        return (
          <CommonTreeForm
            sensorId={sensorId}
            createAnalyticFunc={createWaterUptakeAnalytic}
            fileType={FILE_TYPE.WATER_UPTAKE_FILE}
            label={LABEL_DATA_RASTERS.WATER_UPTAKE_TREE}
            layerType={LayerTypeEnum.WATER_UPTAKE_TREE}
            deleteFileGeojson={deleteWaterUptakeTreeGeojsonFile}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_TREE:
        return (
          <CommonTreeForm
            sensorId={sensorId}
            createAnalyticFunc={createChlorophyllAnalytic}
            fileType={FILE_TYPE.CHLOROPHYLL_FILE}
            label={LABEL_DATA_RASTERS.CHLOROPHYLL_TREE}
            layerType={LayerTypeEnum.CHLOROPHYLL_TREE}
            deleteFileGeojson={deleteChlorophyllTreeGeojsonFile}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_TREE:
        return (
          <CommonTreeForm
            sensorId={sensorId}
            createAnalyticFunc={createPlantHealthOilPalmAnalytic}
            fileType={FILE_TYPE.PLAN_HEALTH_OILPALM_FILE}
            label={LABEL_DATA_RASTERS.PLANT_HEALTH_TREE}
            layerType={LayerTypeEnum.PLANT_HEALTH_TREE}
            deleteFileGeojson={deletePlantHealthOilPalmGeojsonFile}
          />
        );

      case TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_FIELD:
        return (
          <CommonFieldForm
            sensorId={sensorId}
            createAnalyticFunc={createPlantHealthAnalytic}
            fileType={FILE_TYPE.PLANT_HEALTH_GEOJSON}
            label={LABEL_DATA_RASTERS.PLANT_HEALTH_FIELD}
            layerType={LayerTypeEnum.PLANT_HEALTH_FIELD}
            deleteFileGeojson={deletePlantHealthAnalysisGeojsonFile}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_FIELD:
        return (
          <CommonFieldForm
            sensorId={sensorId}
            createAnalyticFunc={createChlorophyllPaddyAnalytic}
            fileType={FILE_TYPE.CHLOROPHYLL_PADDY_GEOJSON}
            label={LABEL_DATA_RASTERS.CHLOROPHYLL_FIELD}
            layerType={LayerTypeEnum.CHLOROPHYLL_FIELD}
            deleteFileGeojson={deleteChlorophyllFieldGeojsonFile}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.VIGOR_FIELD:
        return (
          <CommonFieldForm
            sensorId={sensorId}
            createAnalyticFunc={createVigorPaddyAnalytic}
            fileType={FILE_TYPE.VIGOR_PADDY_GEOJSON}
            label={LABEL_DATA_RASTERS.VIGOR_FIELD}
            layerType={LayerTypeEnum.VIGOR_FIELD}
            deleteFileGeojson={deleteVirgoFieldGeojsonFile}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.STRESS_FIELD:
        return (
          <CommonFieldForm
            sensorId={sensorId}
            createAnalyticFunc={createStressFieldAnalytic}
            fileType={FILE_TYPE.STRESS_FIELD_GEOJSON}
            label={LABEL_DATA_RASTERS.STRESS_FIELD}
            layerType={LayerTypeEnum.STRESS_FIELD}
            deleteFileGeojson={deleteStressFieldGeojsonFile}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_FIELD:
        return (
          <CommonFieldForm
            sensorId={sensorId}
            createAnalyticFunc={createWaterUptakeFieldAnalytic}
            fileType={FILE_TYPE.WATER_UPTAKE_FIELD_GEOJSON}
            label={LABEL_DATA_RASTERS.WATER_UPTAKE_FIELD}
            layerType={LayerTypeEnum.WATER_UPTAKE_FIELD}
            deleteFileGeojson={deleteWaterUptakeFieldGeojsonFile}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.SOIL_WATER_CONTENT_FIELD:
        return (
          <CommonFieldForm
            sensorId={sensorId}
            createAnalyticFunc={createSoilWaterContentFieldAnalytic}
            fileType={FILE_TYPE.SOIL_WATER_CONTENT_FIELD_GEOJSON}
            label={LABEL_DATA_RASTERS.SOIL_WATER_CONTENT_FIELD}
            layerType={LayerTypeEnum.SOIL_WATER_CONTENT_FIELD}
            deleteFileGeojson={deleteSoilWaterContentFieldAnalysisData}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.SOIL_EROSION_FIELD:
        return (
          <CommonFieldForm
            sensorId={sensorId}
            createAnalyticFunc={createSoilErosionFieldAnalytic}
            fileType={FILE_TYPE.SOIL_EROSION_FIELD_GEOJSON}
            label={LABEL_DATA_RASTERS.SOIL_EROSION_FIELD}
            layerType={LayerTypeEnum.SOIL_EROSION_FIELD}
            deleteFileGeojson={deleteSoilErosionFieldAnalysisData}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.LEAF_PHENOLOGY_FIELD:
        return (
          <CommonFieldForm
            sensorId={sensorId}
            createAnalyticFunc={createLeafPhenologyFieldAnalytic}
            fileType={FILE_TYPE.LEAF_PHENOLOGY_FIELD_GEOJSON}
            label={LABEL_DATA_RASTERS.LEAF_PHENOLOGY_FIELD}
            layerType={LayerTypeEnum.LEAF_PHENOLOGY_FIELD}
            deleteFileGeojson={deleteLeafPhenologyFieldAnalysisData}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.ELEVATION_FIELD:
        return (
          <CommonFieldForm
            sensorId={sensorId}
            createAnalyticFunc={createElevationFieldAnalytic}
            fileType={FILE_TYPE.ELEVATION_FIELD_GEOJSON}
            label={LABEL_DATA_RASTERS.ELEVATION_FIELD}
            layerType={LayerTypeEnum.ELEVATION_FIELD}
            deleteFileGeojson={deleteElevationFieldAnalysisData}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.SLOPE_FIELD:
        return (
          <CommonFieldForm
            sensorId={sensorId}
            createAnalyticFunc={createSlopeFieldAnalytic}
            fileType={FILE_TYPE.SLOPE_FIELD_GEOJSON}
            label={LABEL_DATA_RASTERS.SLOPE_FIELD}
            layerType={LayerTypeEnum.SLOPE_FIELD}
            deleteFileGeojson={deleteSlopeFieldAnalysisData}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.FISSURE:
        return (
          <CommonFieldForm
            sensorId={sensorId}
            createAnalyticFunc={createFissureAnalytic}
            fileType={FILE_TYPE.FISSURE_GEOJSON}
            label={LABEL_DATA_RASTERS.FISSURE}
            layerType={LayerTypeEnum.FISSURE}
            deleteFileGeojson={deleteFissureAnalysisData}
            acceptFile="(.geojson|.json)"
            typeOfAnalytics={TYPE_ANALYTICS_MAP_VIEW.FISSURE}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.VEGETATION_ENCROACHMENT:
        return (
          <CommonFieldForm
            sensorId={sensorId}
            createAnalyticFunc={createVegetationEncroachmentAnalytic}
            fileType={FILE_TYPE.VEGETATION_ENCROACHMENT_GEOJSON}
            label={LABEL_DATA_RASTERS.VEGETATION_ENCROACHMENT}
            layerType={LayerTypeEnum.VEGETATION_ENCROACHMENT}
            deleteFileGeojson={deleteVegetationEncroachmentAnalysisData}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.CUSTOM_FIELD:
        return (
          <CommonFieldForm
            typeOfAnalytics={typeOfAnalytics}
            sensorId={sensorId}
            createAnalyticFunc={createCustomFieldAnalytic}
            fileType={FILE_TYPE.CUSTOM_FIELD_GEOJSON}
            label={LABEL_DATA_RASTERS.CUSTOM_FIELD}
            layerType={LayerTypeEnum.CUSTOM_FIELD}
            deleteFileGeojson={deleteCustomFieldAnalysisData}
          />
        );
      default:
        return <></>;
    }
  }, [typeOfAnalytics, sensorId]);

  return (
    <>
      {typeOfAnalytics && sensorId && (
        <Card sx={{ height: '100%' }}>
          <Grid width="100%">
            <InputLabel sx={{ fontWeight: '600', pt: 2 }}>{typeOfAnalytics}</InputLabel>
            {form}
          </Grid>
        </Card>
      )}
    </>
  );
};
