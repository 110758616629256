import {
  Box,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { EChartsOption } from 'echarts-for-react/src/types';
import { Dispatch, FC, SetStateAction } from 'react';

const useStyles = makeStyles((theme) => ({
  chartTitle: {
    lineHeight: '2',
    textAlign: 'center',
    marginTop: '5px',
  },
  chartSubTitle: {
    textAlign: 'center',
  },
}));

interface DataViewModalProps {
  setDataViewModal: Dispatch<SetStateAction<boolean>>;
  dataViewModal: boolean;
  EChartsOption: EChartsOption;
}
const DataViewModal: FC<DataViewModalProps> = ({ setDataViewModal, dataViewModal, EChartsOption }) => {
  const classes = useStyles();
  const chartName = EChartsOption?.chartName;
  const chartTitle = EChartsOption?.option?.title[0].text;
  const chartSubTitle = EChartsOption?.option?.title[0].subtext;

  if (chartName === 'productionTrendsLine') {
    const xAxis = EChartsOption?.option?.xAxis[0].data;
    const lineData = EChartsOption?.option?.series;

    return (
      <Dialog
        open={dataViewModal}
        onClose={() => {
          setDataViewModal(false);
        }}
        maxWidth={'xl'}>
        <Box sx={{ width: 700, margin: '20px' }}>
          <Paper elevation={0}>
            <Typography variant={'h1'} className={classes.chartTitle}>
              {chartTitle}
            </Typography>
            <Typography variant={'h3'} className={classes.chartSubTitle}>
              {chartSubTitle}
            </Typography>
          </Paper>

          <TableContainer sx={{ maxHeight: 440, marginTop: 5 }}>
            <Table stickyHeader aria-label="simple table" sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>PRODUCTION/MONTH</TableCell>
                  {xAxis?.map((row: any) => (
                    <TableCell align="center" key={row}>
                      {row}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {lineData?.map((row: any, index: number) => (
                  <TableRow
                    key={row.name}
                    style={{
                      borderBottom: '1px solid #dddddd',
                      backgroundColor: index % 2 === 0 ? '#ffffff' : '#e8f4fc', // alternate background color
                    }}>
                    <TableCell>{row.name}</TableCell>
                    {row.data.map((data: any) => (
                      <TableCell>
                        <span>{data}</span>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Dialog>
    );
  } else if (chartName === 'tonnageStack' || chartName === 'tripStack') {
    const xAxis = EChartsOption?.option.xAxis[0].data;
    const firstData = EChartsOption?.option.series[0].data;
    const secondData = EChartsOption?.option.series[1].data;

    return (
      <Dialog
        open={dataViewModal}
        onClose={() => {
          setDataViewModal(false);
        }}
        maxWidth={'xl'}>
        <Box sx={{ width: 700, margin: '20px' }}>
          <Paper elevation={0}>
            <Typography variant={'h1'} className={classes.chartTitle}>
              {chartTitle}
            </Typography>
            <Typography variant={'h3'} className={classes.chartSubTitle}>
              {chartSubTitle}
            </Typography>
          </Paper>
          <TableContainer sx={{ maxHeight: 440, marginTop: 5 }}>
            <Table stickyHeader aria-label="simple table" sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell key="first">BLOCK/ESTATE</TableCell>
                  <TableCell align="center">CONFIRMED</TableCell>
                  <TableCell align="center">RUNNING</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {xAxis?.map((item: any, index: any) => (
                  <TableRow key={item} style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#e8f4fc' }}>
                    <TableCell component="th" scope="row">
                      {item}
                    </TableCell>
                    <TableCell align="center">{firstData[index]}</TableCell>
                    <TableCell align="center">{secondData[index]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Dialog>
    );
  } else {
    const chartData = EChartsOption?.option?.series[0]?.data;

    let col1 = '';
    let col2 = '';

    if (chartName === 'productionTrendsPie') {
      col1 = 'PRODUCTION';
      col2 = 'TOTAL';
    } else if (chartName === 'tonnageDistributionPie') {
      col1 = 'ESTATE';
      col2 = 'TOTAL';
    } else if (chartName === 'productionCostPMT') {
      col1 = 'BLOCK';
      col2 = 'TONNE';
    } else if (chartName === 'productionCostPerHectare') {
      col1 = 'BLOCK';
      col2 = 'HECTARE';
    } else if (chartName === 'productionExpenditure') {
      col1 = 'BLOCK';
      col2 = 'EXPENDITURE';
    } else {
      col1 = 'STATUS';
      col2 = 'TOTAL';
    }

    return (
      <Dialog
        open={dataViewModal}
        onClose={() => {
          setDataViewModal(false);
        }}
        maxWidth={'md'}>
        <Box sx={{ width: 600, margin: '70px' }}>
          <Paper elevation={0}>
            <Typography variant={'h1'} className={classes.chartTitle}>
              {chartTitle}
            </Typography>
            <Typography variant={'h3'} className={classes.chartSubTitle}>
              {chartSubTitle}
            </Typography>
          </Paper>
          <TableContainer sx={{ maxHeight: 600, marginTop: 5 }}>
            <Table stickyHeader aria-label="simple table" sx={{ minWidth: 400 }}>
              <TableHead>
                <TableRow>
                  <TableCell key="first">{col1}</TableCell>
                  <TableCell align="center">{col2}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {chartData?.map((row: any, index: any) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#e8f4fc' }}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="center">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Dialog>
    );
  }
};

export default DataViewModal;
