import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Collapse, Grid, Tooltip, Typography } from '@mui/material';
import { compareKpStrings } from 'common/utils/compare';
import { useBoxLayout, useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { FORMAT_DATE, QUERY_KEY } from 'constants/constants';
import { IPhotoIntelligenceDetailData, IPhotoIntelligenceMLevel, SeverityLevelEnum } from 'constants/photoIntelligence';
import { useCheckRTL } from 'hooks/common/useCheckRLF';
import moment from 'moment';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getPhotoIntelligenceAllLocation } from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeAssociatedKpInfo,
  changeDetailBarList,
  changeSelectedKpInfo,
  photoIntelligenceSelector,
} from 'store/slices/map-view/photoIntelligenceAnalytics';
import { changeParentAnalyticsId, mapViewSelector } from 'store/slices/mapViewSlice';
import { rightBarSelector } from 'store/slices/rightBarSlice';

interface ISubCollapseControl {
  key: SeverityLevelEnum;
  isOpen: boolean;
  label: string;
}

const PhotoIntelligenceLastLevelSummary = () => {
  const [isShowPhotoIntelligence, setIsShowPhotoIntelligence] = useState<boolean>(false);
  const [subCollapseControl, setSubCollapseControl] = useState<ISubCollapseControl[]>([
    { key: SeverityLevelEnum.GROUND_FEATURES, isOpen: false, label: 'trans.ground_features' },
    { key: SeverityLevelEnum.LOW, isOpen: false, label: 'trans.low' },
    { key: SeverityLevelEnum.MEDIUM, isOpen: false, label: 'trans.medium' },
    { key: SeverityLevelEnum.HIGH, isOpen: false, label: 'trans.high' },
  ]);

  const classes = useLayerSelectCardStyle();
  const classes2 = useBoxLayout();
  const { t } = useTranslation();
  const { isRTL } = useCheckRTL();
  const { levelId, isLastLevelId } = useAppSelector(mapViewSelector);
  const { analyticName } = useAppSelector(rightBarSelector);
  const dispatch = useAppDispatch();
  const { barDetailList } = useAppSelector(photoIntelligenceSelector);

  const { data: photoIntelligenceData } = useQuery<IPhotoIntelligenceMLevel[]>(
    [QUERY_KEY.ALL_LOCATION_PHOTO_INTELLIGENCE, levelId],
    () => getPhotoIntelligenceAllLocation(levelId!),
    {
      enabled: !!levelId && isLastLevelId,
    }
  );

  const summaryData = photoIntelligenceData
    ?.map((item) => item.data)
    ?.flat() as unknown as IPhotoIntelligenceDetailData[];

  const sortedData = summaryData?.sort((a, b) => {
    const value = compareKpStrings(a.kpName, b.kpName);
    if (value === 0) return moment(a.analysisId.date).isBefore(b.analysisId.date) ? 1 : -1;
    return value;
  });

  const convertedData = sortedData?.reduce<Record<SeverityLevelEnum, IPhotoIntelligenceDetailData[]>>(
    (acc, cur) => {
      [...new Set([...cur.severityLevels])].forEach((name: SeverityLevelEnum) => {
        acc[name] = [...acc[name], cur];
      });
      return acc;
    },
    {
      [SeverityLevelEnum.GROUND_FEATURES]: [],
      [SeverityLevelEnum.LOW]: [],
      [SeverityLevelEnum.MEDIUM]: [],
      [SeverityLevelEnum.HIGH]: [],
    }
  );

  const handleClickSubCollapse = (key: SeverityLevelEnum) => {
    const newSubCollapseControl = subCollapseControl?.map((item) =>
      item.key === key ? { ...item, isOpen: !item.isOpen } : item
    );
    setSubCollapseControl(newSubCollapseControl);
  };

  const onClickTargetKp = (item: IPhotoIntelligenceDetailData) => {
    const {
      _id: targetKpId,
      lon,
      lat,
      analysisId: { date, parentId, _id: analysisId },
    } = item;
    const formattedDate = moment.utc(date).format(FORMAT_DATE);
    dispatch(changeAssociatedKpInfo({ id: targetKpId, date: formattedDate, isDone: false }));
    dispatch(changeParentAnalyticsId({ parentId: parentId, analyticId: analysisId }));

    const newBarDetailList = barDetailList.map((item) =>
      item.id === targetKpId ? { ...item, isOpenCollapse: true } : { ...item, isOpenCollapse: false }
    );
    dispatch(changeDetailBarList(newBarDetailList));
    dispatch(changeSelectedKpInfo({ _id: targetKpId, lon, lat, isDone: false }));

    onScrollToTargetKp(targetKpId);
  };

  const onScrollToTargetKp = (targetId: string) => {
    const element = document.getElementById(`photo_bar_${targetId}`);
    element?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
  };

  return (
    <Box className={classes2.boxLayout} style={{ padding: '20px 20px', borderRadius: '20px', marginBottom: '20px' }}>
      <Button
        endIcon={
          isShowPhotoIntelligence ? (
            <ExpandMoreIcon style={{ fontWeight: 'bold' }} />
          ) : (
            <ChevronRightIcon style={{ fontWeight: 'bold' }} />
          )
        }
        onClick={() => setIsShowPhotoIntelligence(!isShowPhotoIntelligence)}
        classes={{ root: classes.buttonTextStyle }}
        style={{ width: '100%' }}>
        <Typography
          className={classes.buttonTextStyle}
          style={{ marginRight: 'auto', fontWeight: 'bold', fontSize: '14px !important' }}>
          Summary of Photo Intelligence
        </Typography>
      </Button>
      <Collapse in={isShowPhotoIntelligence} timeout="auto" unmountOnExit sx={{ px: 1.5 }}>
        {subCollapseControl?.map((item) => (
          <Fragment key={item.key}>
            <Button
              startIcon={
                item.isOpen ? (
                  <ExpandMoreIcon style={{ fontWeight: 'bold' }} />
                ) : (
                  <ChevronRightIcon style={{ fontWeight: 'bold' }} />
                )
              }
              onClick={() => handleClickSubCollapse(item.key)}
              classes={{ root: classes.buttonTextStyle }}
              style={{ width: '100%' }}>
              <Typography
                className={classes.buttonTextStyle}
                style={{
                  marginRight: isRTL ? 0 : 'auto',
                  marginLeft: isRTL ? 'auto' : 0,
                  fontWeight: 'bold',
                  fontSize: '13px !important',
                  textAlign: isRTL ? 'right' : 'left',
                }}>
                {t(item.label)}
              </Typography>
            </Button>
            <Collapse in={item.isOpen} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
              <Grid container>
                {convertedData &&
                  convertedData[item.key]?.map((ele) => (
                    <Grid item xs={6} key={ele._id}>
                      {analyticName ? (
                        <Box
                          component="span"
                          sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                          onClick={() => onClickTargetKp(ele)}>
                          {ele.kpName}
                        </Box>
                      ) : (
                        <Tooltip title={'Please select analytic to enable this feature'}>
                          <Box component="span" sx={{ textDecoration: 'underline' }}>
                            {ele.kpName}
                          </Box>
                        </Tooltip>
                      )}
                      <Box component="span" sx={{ ml: '4px', opacity: 0.7 }}>
                        {`(${moment(ele.analysisId?.date).format('DD-MM-YYYY')})`}
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            </Collapse>
          </Fragment>
        ))}
      </Collapse>
    </Box>
  );
};

export default PhotoIntelligenceLastLevelSummary;
