import { yupResolver } from '@hookform/resolvers/yup';
import { Add } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DatePicker from '@mui/lab/DatePicker';
import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  InputLabel,
  Skeleton,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { VALIDATION_REQUIRED } from 'common/defines/constants';
import { QUERY_KEY } from 'constants/constants';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import {
  createClientReactQuery,
  getClientById,
  getClientByIdNew,
  updateClientById,
} from 'services/clients/apiClient.services';
import { getAllSettingsDataCroptype } from 'services/settings/settings.services';
import { changeId } from 'store/slices/clientDataUserSlice';
import * as yup from 'yup';
import {
  ClientFormState,
  IAdditionalInformationEdit,
  IClientData,
  IClientType,
  ICreateClientsFormData,
  ICropType,
  IFormType,
  InforArray,
  INumberOfLevel,
  IOptionalPropertyType,
  SelectionOptionType,
} from '../../../common/defines/clients';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearClientUpdateState, clientSelector, setClientData } from '../../../store/slices/clientSlice';
import { ClientActionsDialog } from '../ClientActionsDialog';
import { ClientFormActions } from '../ClientFormActions';
import { ClientMenu } from '../ClientMenu';
import { ClientsAdditionalModal } from '../ClientsAdditionalModal';
import { CustomSelectionOptionsType } from '../ClientsInfo.type';
import { PropertyTypeFile } from './PropertyTypeFile';
import { SwitchCropManagement } from './SwitchCropManagement';

const Input = styled('input')({
  display: 'none',
});
const createClientsValidationSchema = yup
  .object({
    email: yup.string().email(VALIDATION_REQUIRED.inValidEmail).required(VALIDATION_REQUIRED.email),
    name: yup.string().required(VALIDATION_REQUIRED.companyName),
    ssm: yup.string().required(VALIDATION_REQUIRED.ssm),
    contactAddress: yup.string().required(VALIDATION_REQUIRED.address),
    cropType: yup
      .array()
      .of(
        yup.object({
          name: yup.string(),
          _id: yup.string(),
          color: yup.string(),
          icon: yup.string(),
          analyticsDisplay: yup.array().of(yup.number()),
        })
      )
      .min(1, VALIDATION_REQUIRED.cropType),
    clientType: yup.mixed<IClientType>().oneOf(Object.values(IClientType)),
    avatar: yup.lazy((val) =>
      val
        ? yup.string().matches(/[^\s]+(\.(jpe?g|png))$/im, 'Please upload an image type .png, .jpg, .jpeg')
        : yup.string().notRequired()
    ),
    optionalProperties: yup.array().of(
      yup.object({
        name: yup.string(),
        value: yup.mixed(),
        type: yup.string(),
        isDefault: yup.boolean(),
        level: yup.number().min(0).max(5),
        cropType: yup.string(),
      })
    ),
    additionalInformation: yup.array().of(
      yup.object({
        name: yup.string(),
        value: yup.lazy((val) =>
          Array.isArray(val) ? yup.array().of(yup.string().defined()).min(1) : yup.string().defined().required().min(1)
        ),
        type: yup.mixed<IOptionalPropertyType>().oneOf(Object.values(IOptionalPropertyType)),
      })
    ),
    databaseName: yup.string().when('enableCropManagement', {
      is: true,
      then: yup.string().required(VALIDATION_REQUIRED.databaseName),
      otherwise: yup.string().nullable(),
    }),
  })
  .required();

const customSelectionOptions: CustomSelectionOptionsType[] = [];

export const ClientBasicInfo = ({ clientId, formType, nextStep, setIsUpdateData }: ClientFormState) => {
  const dispatch = useAppDispatch();
  const { clientData } = useAppSelector(clientSelector);
  const navigate = useNavigate();
  const theme = useTheme();
  const {
    data: _clientData,
    refetch: refetchClientData,
    isFetching,
  } = useQuery<IClientData>([QUERY_KEY.CLIENT_DATA, clientId], () => getClientById(clientId!), {
    enabled: !!clientId,
    keepPreviousData: true,
  });

  const {
    data: _newClientData,
    refetch: refetchNewClientData,
    isFetching: isNewClientDataFetching,
  } = useQuery<IClientData>([QUERY_KEY.CLIENT_DATA_NEW, clientId], () => getClientByIdNew(clientId!), {
    enabled: !!clientId,
    keepPreviousData: true,
  });

  useEffect(() => {
    if (_clientData) {
      dispatch(setClientData(_clientData));
      refetchClientData();
    }
  }, [_clientData, dispatch, refetchClientData]);

  const [open, setOpen] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [cropTypeValues, setCropTypeValues] = useState<ICropType[]>([]);
  const [editCropType, setEditCropType] = useState<ICropType[]>([]);
  const [exitsFile, setExitsFile] = useState('');
  const [errorFile, setErrorFile] = useState(false);
  const [indexField, setIndexField] = useState<null | number>(null);
  const [editAdditionalData, setEditAdditionalData] = useState<IAdditionalInformationEdit | null>();
  const [avatarUrl, setAvatarUrl] = useState('');
  const [isCreateError, setIsCreateError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string[]>([]);

  // Call API get croptype data
  const { data } = useQuery([QUERY_KEY.USER_SETTINGS_CROPTYPE], () => getAllSettingsDataCroptype());
  const dataSettingsAnalyticsValue = useMemo(() => {
    return data?.data || [];
  }, [data]);

  const initialAnchorEl = {
    field: null,
    removeField: null,
  };

  const [anchorEl, setAnchorEl] = useState<{
    field: null | HTMLElement;
    removeField: null | HTMLElement;
  }>(initialAnchorEl);

  const openMenu = (anchorName: string, isDeleteLevel?: boolean) => (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl({
      ...anchorEl,
      [anchorName]: event.currentTarget,
    });
  };
  const closeMenu = (anchorName: string) => () => {
    setAnchorEl({
      ...anchorEl,
      [anchorName]: null,
    });
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setEditAdditionalData(null);
    setOpen(false);
  };

  const handleOpenWarningModal = () => {
    setWarningModal(true);
  };

  const handleCloseWarningModal = () => {
    setWarningModal(false);
  };

  const form = useForm<ICreateClientsFormData>({
    defaultValues: {
      name: '',
      email: '',
      ssm: '',
      contactNumber: '',
      contactAddress: '',
      cropType: [],
      enableCropManagement: false,
      databaseName: '',
      clientType: IClientType.INDEPENDENT,
      avatar: '',
      avatarFile: '',
      optionalProperties: [],
      additionalInformation: [],
    },
    resolver: yupResolver(createClientsValidationSchema),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = form;

  useEffect(() => {
    setIsUpdateData && setIsUpdateData(isDirty);
  }, [setIsUpdateData, isDirty]);

  const handleOnAgreeWarningModal = () => {
    setValue('cropType', editCropType);
    setCropTypeValues(editCropType);
    setWarningModal(false);
  };

  const cropTypeArrayValue = useMemo(() => {
    const options = dataSettingsAnalyticsValue.filter((item: any) => {
      return clientData?.cropType?.some((val: any) => item._id === val._id);
    });
    return options.sort((a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name));
  }, [clientData, dataSettingsAnalyticsValue]);

  useEffect(() => {
    setCropTypeValues(cropTypeArrayValue);
  }, [cropTypeArrayValue]);

  useEffect(() => {
    if (formType === IFormType.EDIT && clientId) {
      refetchClientData();
    }
  }, [clientId, formType, refetchClientData]);

  const additionalValueCheck = (data: ICreateClientsFormData) => {
    data.additionalInformation?.forEach((infor, index, arr) => {
      if (!Array.isArray(infor.value)) {
        arr[index].value = [infor.value] as unknown as string;
      }
    });
    return data;
  };

  const handleSubmitCropTypeId = (data: ICreateClientsFormData) => {
    const cropTypeIdArray: any[] = [];
    data.cropType?.forEach((cropType: any) => {
      cropTypeIdArray.push(cropType?._id || '');
    });
    return (data = {
      ...data,
      cropType: cropTypeIdArray,
    });
  };

  const handleCreateClient = useMutation((data: ICreateClientsFormData) => createClientReactQuery(data), {
    onSuccess: (clientInfoResponse) => {
      setIsCreateError(false);
      dispatch(setClientData(clientInfoResponse.data));
      dispatch(changeId(clientInfoResponse.data._id));
      if (nextStep) {
        nextStep(1);
        if (formType === IFormType.ADD) {
          navigate(`/clients/edit/${clientInfoResponse.data._id}`);
        }
      }
    },
    onError: (err: any) => {
      setIsCreateError(true);
      setErrorMessage(err?.data?.message || ['Failed to add new client.']);
    },
  });

  const handleUpdateClient = useMutation((data: ICreateClientsFormData) => updateClientById(data.id || '', data), {
    onSuccess: (res: any) => {
      nextStep && nextStep(1);
      setIsCreateError(false);
      dispatch(setClientData(res.data));
    },
    onError: (err: any) => {
      setIsCreateError(true);
      setErrorMessage(err?.data?.message || ['Failed to update client information.']);
    },
  });
  const handleClear = () => {
    reset({
      name: '',
      email: '',
      ssm: '',
      contactNumber: '',
      contactAddress: '',
      cropType: [],
      clientType: IClientType.INDEPENDENT,
      avatar: '',
      avatarFile: '',
      optionalProperties: [],
      additionalInformation: [],
    });
    setCropTypeValues([]);
  };

  const handleSubmitForm = (data: any) => {
    if (errorFile) return;

    const fixedData = handleSubmitCropTypeId(data);

    const setNumberOfLevel: INumberOfLevel[] = fixedData.cropType.map((type: any) => {
      return {
        type,
        value: 1,
      };
    });
    if (clientData?.numberOfLevel) {
      clientData?.numberOfLevel.forEach((value) => {
        setNumberOfLevel
          .filter((val) => val.type === value.type)
          .forEach((_, index, arr) => (arr[index].value = value.value));
      });
    }

    if (formType === IFormType.EDIT && clientId) {
      additionalValueCheck(fixedData);
      const _cropTypes = fixedData.cropType;
      const _cropTypeListNot = _cropTypes.filter((v: any) => {
        return !fixedData.optionalProperties?.some((val: any) => val.cropType === v && val.name === 'Name');
      });
      return handleUpdateClient.mutate({
        ...fixedData,
        id: clientId || clientData?._id,
        numberOfLevel: setNumberOfLevel,
        additionalInformation: fixedData.additionalInformation,
        optionalProperties: [
          ...(fixedData.optionalProperties || []),
          ..._cropTypeListNot.map((v: any) => ({
            isDefault: true,
            level: 0,
            name: 'Name',
            cropType: v,
            value: [],
            type: IOptionalPropertyType.TEXT,
          })),
        ],
      });
    }
    if (formType === IFormType.EDIT) {
      additionalValueCheck(fixedData);
      return handleUpdateClient.mutate({
        ...fixedData,
        id: clientData?._id,
        numberOfLevel: setNumberOfLevel,
        additionalInformation: fixedData.additionalInformation,
      });
    }
    additionalValueCheck(fixedData);
    return handleCreateClient.mutate({
      ...fixedData,
      numberOfLevel: setNumberOfLevel,
      additionalInformation: fixedData.additionalInformation,
    });
  };

  const handleMultipleChange = (name: any, data?: SelectionOptionType[], dataCroptype?: ICropType[]) => {
    if (dataCroptype && name === 'cropType') {
      setCropTypeValues(dataCroptype);
      return setValue(name, dataCroptype);
    }
    const multipList = data?.map((dt: SelectionOptionType) => dt.key);
    setValue(name, multipList);
  };

  const handleOptionSelectField = (name?: string) => {
    const optionField = customSelectionOptions.find((option) => option.key === name);
    return optionField?.value || [];
  };

  const { fields, append, remove, update } = useFieldArray({
    name: 'additionalInformation',
    control,
  });

  const mapCustomSelectOptions = (name: string, selectOptions?: string) => {
    const newSelectOptions: SelectionOptionType[] = [];
    let options = selectOptions && selectOptions.split(/,(?! )/, -1);
    options = Array.from(new Set(options));
    options.forEach((dt: string) => {
      newSelectOptions.push({ value: dt, key: dt });
    });
    customSelectionOptions.push({ key: name, value: newSelectOptions });
  };

  useEffect(() => {
    dispatch(clearClientUpdateState());

    clientData?.additionalInformation?.forEach((infor) => {
      if (infor.type === IOptionalPropertyType.SELECT) {
        mapCustomSelectOptions(infor.name, (infor?.listValue as Array<string>)?.join(','));
      }
    });

    if (!clientId) {
      reset({
        name: '',
        email: '',
        ssm: '',
        contactNumber: '',
        contactAddress: '',
        cropType: [],
        clientType: IClientType.INDEPENDENT,
        avatar: '',
        avatarFile: '',
        optionalProperties: [],
        additionalInformation: [],
        databaseName: '',
        enableCropManagement: false,
      });
      setCropTypeValues([]);
    } else {
      reset({
        name: clientData?.name || '',
        email: clientData?.email || '',
        ssm: clientData?.ssm || '',
        contactNumber: clientData?.contactNumber || '',
        contactAddress: clientData?.contactAddress || '',
        cropType: clientData?.cropType || [],
        clientType: clientData?.clientType || IClientType.INDEPENDENT,
        avatar: clientData?.avatar || '',
        avatarFile: clientData?.avatarFile || '',
        optionalProperties: clientData?.optionalProperties || [],
        additionalInformation: clientData?.additionalInformation || [],
        enableCropManagement: clientData?.enableCropManagement,
        databaseName: clientData?.databaseName,
      });
      setValue('cropType', clientData?.cropType || []);
      setValue('avatar', clientData?.avatar || '');
    }

    let avatarName: string[] = [];
    if (clientData?.avatar) {
      avatarName = clientData.avatar.split('-');
      setExitsFile(avatarName[7]);
    } else {
      setExitsFile('');
    }
  }, [clientData, dispatch, reset, setValue]);

  const handleFormatSelectOptions = (data: string) => {
    let options = data.split(/,(?! )/, -1);
    options = Array.from(new Set(options));
    return options;
  };

  const additionalField = (data: InforArray) => {
    if (data.index === 0 || data.index) {
      mapCustomSelectOptions(data.name, data?.selectOptions);
      update(data.index, {
        name: data.name,
        type: data.propertyType,
        value: '',
        listValue: handleFormatSelectOptions(data?.inputLength || data?.selectOptions),
      });
      return;
    }
    switch (data.propertyType) {
      case IOptionalPropertyType.DATE:
        append({
          name: data.name,
          type: IOptionalPropertyType.DATE,
          value: '',
        });
        break;
      case IOptionalPropertyType.FILE:
        append({
          name: data.name,
          type: IOptionalPropertyType.FILE,
          value: '',
        });
        break;
      case IOptionalPropertyType.SELECT:
        mapCustomSelectOptions(data.name, data?.selectOptions);
        append({
          name: data.name,
          type: IOptionalPropertyType.SELECT,
          value: '',
          listValue: handleFormatSelectOptions(data?.selectOptions),
        });
        break;
      case IOptionalPropertyType.TEXT:
        append({
          name: data.name,
          type: IOptionalPropertyType.TEXT,
          value: '',
          listValue: handleFormatSelectOptions(data?.inputLength),
        });
        break;
      default:
        break;
    }
  };

  const handleTrimValue = (e: ChangeEvent<HTMLInputElement>, fieldName: keyof ICreateClientsFormData) => {
    const trimInputValue = e.target.value.replace(/\s+/g, ' ');
    setValue(fieldName, trimInputValue);
  };

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>, fieldName: keyof ICreateClientsFormData) => {
    const pureFile = e.target.files;
    const formData = new FormData();
    let value = '';
    const token = localStorage.getItem('token');
    if (!pureFile) return;
    if (pureFile[0].name.includes('-')) {
      return setErrorFile(true);
    }
    formData.append('file', pureFile[0]);
    setErrorFile(false);
    setExitsFile(pureFile[0].name);
    fetch(`${process.env.REACT_APP_API_DEV}/media/uploadGeneral`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        value = result.path;
        setValue(fieldName, value);
        setAvatarUrl(value);
        dispatch(setClientData({ ...clientData, avatar: value }));
        return value;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGetValueCustom = useCallback(
    (key?: string) => {
      const defaultOptions = [] as SelectionOptionType[];

      const optionsByKey = clientData?.additionalInformation?.find((item) => item.key === key)?.value;
      Array.isArray(optionsByKey) &&
        optionsByKey.forEach((item: string) => {
          defaultOptions.push({ value: item, key: item });
        });
      return defaultOptions;
    },
    [clientData?.additionalInformation]
  );

  useEffect(() => {
    clientData?.avatar && setAvatarUrl(clientData.avatar);
  }, [clientData]);

  useEffect(() => {
    return () => {
      setAvatarUrl('');
      reset({
        name: '',
        email: '',
        ssm: '',
        contactNumber: '',
        contactAddress: '',
        cropType: [],
        clientType: IClientType.INDEPENDENT,
        avatar: '',
        avatarFile: '',
        optionalProperties: [],
        additionalInformation: [],
      });
    };
  }, [reset]);

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
      }}>
      {clientId && !clientData && <Skeleton animation="wave" />}
      <Stack component="form" onSubmit={handleSubmit(handleSubmitForm)}>
        <Stack>
          <Typography
            sx={{
              fontFamily: 'Barlow',
              fontStyle: 'SemiBold',
              fontSize: '20px',
              lineHeight: '21px',
              color: (theme) => (theme.palette.mode === 'dark' ? '#FFFFFF' : '#616161'),
              fontWeight: 'bold',
            }}>
            Basic Information
          </Typography>
          <Stack
            className="form-basic-info"
            alignItems="center"
            sx={{ mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ width: '325px', textAlign: 'center', position: 'relative' }}>
              <Badge
                badgeContent={
                  <>
                    <label htmlFor="icon-button-file">
                      <Input
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeFile(e, 'avatar')}
                      />
                      <IconButton aria-label="upload picture" component="span">
                        <AddCircleIcon color="disabled" />
                      </IconButton>
                    </label>
                  </>
                }
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Avatar
                  src={avatarUrl}
                  sx={{ width: 150, height: 150, border: `1px solid ${theme.palette.divider}` }}
                />
              </Badge>
              <Stack sx={{ alignItems: 'center', mt: 2 }}>
                {errors.avatar && (
                  <Typography
                    component="div"
                    className="errorMsg"
                    sx={{
                      fontFamily: 'Barlow',
                      lineHeight: '12px',
                      fontSize: '14px',
                      color: '#FF4646',
                      maxWidth: '243px',
                      mt: 1,
                    }}>
                    {errors.avatar.message}
                  </Typography>
                )}
                {errorFile && (
                  <Typography
                    component="div"
                    className="errorMsg"
                    sx={{
                      fontFamily: 'Barlow',
                      lineHeight: '12px',
                      fontSize: '14px',
                      color: '#FF4646',
                      maxWidth: '243px',
                      mt: 1,
                    }}>
                    Your avatar name must not contain "-" !
                  </Typography>
                )}
              </Stack>
            </Box>
            <Stack spacing={2} direction="row">
              <Stack className="basic-info" spacing={2}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Box key="name">
                      <InputLabel>
                        Company Name{' '}
                        <span
                          style={{
                            color: 'red',
                          }}>
                          *
                        </span>{' '}
                      </InputLabel>
                      <TextField
                        type="text"
                        size="small"
                        fullWidth
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleTrimValue(e, 'name')}
                      />
                      {errors.name && (
                        <Typography
                          component="div"
                          className="errorMsg"
                          sx={{
                            fontFamily: 'Barlow',
                            fontSize: '14px',
                            lineHeight: '12px',
                            color: '#FF4646',
                            mt: 1,
                          }}>
                          {errors.name.message}
                        </Typography>
                      )}
                    </Box>
                  )}
                />
                <Controller
                  name="ssm"
                  control={control}
                  render={({ field }) => (
                    <Box key="ssm">
                      <InputLabel>
                        Business Registration{' '}
                        <span
                          style={{
                            color: 'red',
                          }}>
                          *
                        </span>{' '}
                      </InputLabel>
                      <TextField
                        type="text"
                        size="small"
                        fullWidth
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleTrimValue(e, 'ssm')}
                      />
                      {errors.ssm && (
                        <Typography
                          component="div"
                          className="errorMsg"
                          sx={{
                            fontFamily: 'Barlow',
                            fontSize: '14px',
                            lineHeight: '12px',
                            color: '#FF4646',
                            mt: 1,
                          }}>
                          {errors.ssm.message}
                        </Typography>
                      )}
                    </Box>
                  )}
                />
                <Controller
                  name="contactAddress"
                  control={control}
                  render={({ field }) => (
                    <Box key="contactAddress">
                      <InputLabel>
                        Address{' '}
                        <span
                          style={{
                            color: 'red',
                          }}>
                          *
                        </span>{' '}
                      </InputLabel>
                      <TextField
                        type="text"
                        size="small"
                        fullWidth
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleTrimValue(e, 'contactAddress')}
                      />
                      {errors.contactAddress && (
                        <Typography
                          component="div"
                          className="errorMsg"
                          sx={{
                            fontFamily: 'Barlow',
                            fontSize: '14px',
                            lineHeight: '12px',
                            color: '#FF4646',
                            mt: 1,
                          }}>
                          {errors.contactAddress.message}
                        </Typography>
                      )}
                    </Box>
                  )}
                />
                <Stack direction="row">
                  <Stack sx={{ width: '50%', mr: 1 }}>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <Box key="email">
                          <InputLabel>
                            Email{' '}
                            <span
                              style={{
                                color: 'red',
                              }}>
                              *
                            </span>{' '}
                          </InputLabel>
                          <TextField
                            type="text"
                            size="small"
                            sx={{
                              mt: '7px',
                              width: '100%',
                            }}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                          />
                          {errors.email && (
                            <Typography
                              component="div"
                              className="errorMsg"
                              sx={{
                                fontFamily: 'Barlow',
                                fontSize: '14px',
                                lineHeight: '12px',
                                color: '#FF4646',
                                maxWidth: '243px',
                                mt: 1,
                              }}>
                              {errors.email.message}
                            </Typography>
                          )}
                        </Box>
                      )}
                    />
                  </Stack>
                  <Stack sx={{ width: '50%', ml: 1 }}>
                    <Controller
                      name="contactNumber"
                      control={control}
                      render={({ field }) => (
                        <Box key="contactNumber">
                          <InputLabel>Contact Number</InputLabel>
                          <TextField
                            type="tel"
                            size="small"
                            sx={{
                              mt: '7px',
                              width: '100%',
                            }}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleTrimValue(e, 'contactNumber')}
                          />
                          {errors.contactNumber && (
                            <Typography
                              component="div"
                              className="errorMsg"
                              sx={{
                                fontFamily: 'Barlow',
                                fontSize: '14px',
                                lineHeight: '12px',
                                color: '#FF4646',
                                maxWidth: '243px',
                                mt: 1,
                              }}>
                              {errors.contactNumber.message}
                            </Typography>
                          )}
                        </Box>
                      )}
                    />
                  </Stack>
                </Stack>
                <Controller
                  name="cropType"
                  control={control}
                  render={({ field }) => (
                    <Box>
                      <InputLabel>Type</InputLabel>
                      <Autocomplete
                        multiple
                        key="cropType"
                        id="tags-outlined"
                        size="small"
                        fullWidth
                        options={dataSettingsAnalyticsValue}
                        isOptionEqualToValue={(option, value) => option._id === value._id}
                        getOptionLabel={(option) => option.name}
                        defaultValue={cropTypeArrayValue}
                        value={cropTypeValues}
                        onChange={(_, values, reason) => {
                          if ((clientId || clientData) && reason === 'removeOption') {
                            setEditCropType(values);
                            handleOpenWarningModal();
                          } else {
                            handleMultipleChange('cropType', undefined, values);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...field}
                            sx={{
                              '.MuiChip-root': {
                                backgroundColor: 'rgba(35,190,106,0.1)',
                              },
                              '.MuiChip-label': {
                                pr: 2.5,
                                color: theme.palette.primary.main,
                              },
                            }}
                          />
                        )}
                      />
                      {errors.cropType && (
                        <Typography
                          component="div"
                          className="errorMsg"
                          sx={{
                            fontFamily: 'Barlow',
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: '#FF4646',
                            mt: 1,
                          }}>
                          {(errors.cropType as any).message}
                        </Typography>
                      )}
                    </Box>
                  )}
                />
                {/* {New Infomation} */}
                {watch('additionalInformation')?.map((item, index) => {
                  return (
                    <Typography component="div" key={index} sx={{ width: '100%' }}>
                      {item.type === IOptionalPropertyType.TEXT && (
                        <Controller
                          name={`additionalInformation.${index}.value`}
                          control={control}
                          render={({ field }) => (
                            <Box onMouseLeave={() => setIndexField(null)} onMouseEnter={() => setIndexField(index)}>
                              <InputLabel>{item.name}</InputLabel>
                              <Box sx={{ display: 'flex' }}>
                                <TextField
                                  inputProps={{
                                    maxLength: item.value[0] === 'Length' ? 256 : 128,
                                  }}
                                  type="text"
                                  size="small"
                                  fullWidth
                                  {...field}
                                />
                                {indexField === index && (
                                  <ClientMenu
                                    styles={{ position: 'absolute', marginLeft: '600px' }}
                                    open={Boolean(anchorEl.removeField)}
                                    anchorEl={anchorEl.removeField}
                                    onOpen={openMenu('removeField', true)}
                                    onDelete={() => {
                                      remove(index);
                                      setIndexField(null);
                                    }}
                                    handleEdit={() => {
                                      setEditAdditionalData({
                                        index: index,
                                        name: item.name,
                                        type: IOptionalPropertyType.TEXT,
                                        value: '',
                                      });
                                      handleOpenModal();
                                    }}
                                    onClose={closeMenu('removeField')}
                                    mainMenu
                                  />
                                )}
                              </Box>
                            </Box>
                          )}
                        />
                      )}
                      {item.type === IOptionalPropertyType.DATE && (
                        <Controller
                          name={`additionalInformation.${index}.value`}
                          control={control}
                          render={({ field }) => {
                            return (
                              <Box onMouseLeave={() => setIndexField(null)} onMouseEnter={() => setIndexField(index)}>
                                <InputLabel>{item.name}</InputLabel>
                                <Box sx={{ display: 'flex' }}>
                                  <DatePicker
                                    {...field}
                                    disableFuture
                                    onChange={(date: any) => {
                                      setValue(`additionalInformation.${index}.value`, [date]);
                                    }}
                                    value={field.value[0] || null}
                                    renderInput={(params: TextFieldProps) => (
                                      <TextField type="text" fullWidth {...params} size="small" />
                                    )}
                                  />
                                  {indexField === index && (
                                    <ClientMenu
                                      styles={{ position: 'absolute', marginLeft: '600px' }}
                                      open={Boolean(anchorEl.removeField)}
                                      anchorEl={anchorEl.removeField}
                                      onOpen={openMenu('removeField', true)}
                                      onDelete={() => {
                                        remove(index);
                                        setIndexField(null);
                                      }}
                                      handleEdit={() => {
                                        setEditAdditionalData({
                                          index: index,
                                          name: item.name,
                                          type: IOptionalPropertyType.DATE,
                                          value: '',
                                        });
                                        handleOpenModal();
                                      }}
                                      onClose={closeMenu('removeField')}
                                      mainMenu
                                    />
                                  )}
                                </Box>
                              </Box>
                            );
                          }}
                        />
                      )}
                      {item.type === IOptionalPropertyType.SELECT && !isFetching && (
                        <Controller
                          name={`additionalInformation.${index}.value`}
                          control={control}
                          render={({ field }) => (
                            <Box onMouseLeave={() => setIndexField(null)} onMouseEnter={() => setIndexField(index)}>
                              <InputLabel>{item.name}</InputLabel>
                              <Box sx={{ display: 'flex' }}>
                                <Autocomplete
                                  multiple
                                  id="tags-outlined"
                                  size="small"
                                  fullWidth
                                  options={handleOptionSelectField(item.name)}
                                  isOptionEqualToValue={(option, value) => option.value === value.value}
                                  getOptionLabel={(option) => option.value}
                                  filterSelectedOptions
                                  defaultValue={handleGetValueCustom(item.key)}
                                  onChange={(e, values) => {
                                    handleMultipleChange(`additionalInformation.${index}.value`, values, undefined);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      {...field}
                                      sx={{
                                        '.MuiChip-root': {
                                          backgroundColor: 'rgba(35,190,106,0.1)',
                                        },
                                        '.MuiChip-label': {
                                          color: theme.palette.primary.main,
                                        },
                                      }}
                                    />
                                  )}
                                />
                                {indexField === index && (
                                  <ClientMenu
                                    styles={{ position: 'absolute', marginLeft: '600px' }}
                                    open={Boolean(anchorEl.removeField)}
                                    anchorEl={anchorEl.removeField}
                                    onOpen={openMenu('removeField', true)}
                                    onDelete={() => {
                                      remove(index);
                                      setIndexField(null);
                                    }}
                                    handleEdit={() => {
                                      setEditAdditionalData({
                                        index: index,
                                        name: item.name,
                                        type: IOptionalPropertyType.SELECT,
                                        value: '',
                                      });
                                      handleOpenModal();
                                    }}
                                    onClose={closeMenu('removeField')}
                                    mainMenu
                                  />
                                )}
                              </Box>
                            </Box>
                          )}
                        />
                      )}
                      {item.type === IOptionalPropertyType.FILE && (
                        <PropertyTypeFile
                          index={index}
                          item={item}
                          control={control}
                          setValue={setValue}
                          remove={remove}
                          handleOpenModal={handleOpenModal}
                          setEditAdditionalData={setEditAdditionalData}
                        />
                      )}
                    </Typography>
                  );
                })}
              </Stack>
            </Stack>
            <SwitchCropManagement control={control} form={form} />
            <Stack>
              {isCreateError && (
                <Typography
                  sx={{
                    mt: 1,
                    minWidth: '600px',
                  }}>
                  {errorMessage.map((message) => {
                    return (
                      <Typography
                        component="li"
                        key={message}
                        className="errorMsg"
                        sx={{
                          fontFamily: 'Barlow',
                          fontSize: '14px',
                          lineHeight: '12px',
                          color: '#FF4646',
                          mt: 1,
                        }}>
                        {message}
                      </Typography>
                    );
                  })}
                </Typography>
              )}
              <Stack className="basic-button">
                <Button
                  endIcon={<Add />}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '48px',
                    minWidth: '600px',
                    textTransform: 'capitalize',
                    backgroundColor: '#009F400D',
                    color: '#009F40',
                    borderRadius: '8px',
                    border: 'none',
                    '&:hover': {
                      border: 'none',
                    },
                  }}
                  color="neutral"
                  variant="outlined"
                  onClick={handleOpenModal}>
                  Add New Information
                </Button>
              </Stack>
            </Stack>
            <Box className="basic-info">
              <ClientFormActions onBack={() => handleClear()} isClear={true} />
            </Box>
          </Stack>
        </Stack>
      </Stack>

      <ClientActionsDialog
        title="Type has level properties!"
        open={warningModal}
        onClose={handleCloseWarningModal}
        description=" All the structure organization related to those types will be deleted. Are you sure you want to remove those types?"
        onCancel={handleCloseWarningModal}
        onSave={handleOnAgreeWarningModal}
        submit={false}
      />

      <ClientsAdditionalModal
        open={open}
        handleClose={handleCloseModal}
        additionalField={additionalField}
        dataAdditional={editAdditionalData}
      />
    </Box>
  );
};
