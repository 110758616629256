import SwapVertIcon from '@mui/icons-material/SwapVert';
import {
  Box,
  InputLabel,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { AscIcon, DescIcon } from 'assets/icons';
import { BigNumber } from 'bignumber.js';
import { MeasurementUnit, SettingUnitName } from 'common/defines/clients';
import { ITableHeaders, TSortOrder } from 'common/defines/constants';
import { defaultClientUnitSetting } from 'common/dummy/dummyClients';
import { QUERY_KEY } from 'constants/constants';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientSettingsById } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { clientSelector } from 'store/slices/clientSlice';
import { clientAreaUnitMeasurement } from '../../IssuesTab/utils';
import TableCellHeaderSummary from '../components/TableCellHeaderSummary';
import TableCellSummary from '../components/TableCellSummary';

interface IRow {
  landUse: string;
  area: number;
}

enum TableKeyEnum {
  NAME = 'Name',
  AREA = 'Area',
  PERCENTAGE = 'Percentage',
}

const LandUseTableArea = () => {
  const { allLocationCropType } = useAppSelector(clientSelector);
  const theme = useTheme();
  const { t } = useTranslation();
  const { clientId } = useParams();

  const initialTableHeaders = [
    { key: TableKeyEnum.NAME, label: 'trans.main_category', sortOrder: null },
    { key: TableKeyEnum.AREA, label: 'trans.area', sortOrder: null },
    { key: TableKeyEnum.PERCENTAGE, label: 'trans.percentage', sortOrder: null },
  ];

  const [tableHeaders, setTableHeaders] = useState<ITableHeaders<TableKeyEnum>[]>(initialTableHeaders);
  const [sortInfo, setSortInfo] = useState<{ key: TableKeyEnum; sortOrder: TSortOrder }>({
    key: TableKeyEnum.NAME,
    sortOrder: null,
  });

  const { data: clientSetting } = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => getClientSettingsById(clientId || ''),
    {
      enabled: !!clientId,
    }
  );

  const areaSetting = useMemo(() => {
    return (
      clientSetting?.unitSetting?.find((data: any) => data.name === SettingUnitName.AREA) || defaultClientUnitSetting[0]
    );
  }, [clientSetting]);

  const areaUnit = useMemo(() => {
    const unitArea = areaSetting.unit;
    if (unitArea === MeasurementUnit.M2) {
      return 'm²';
    }
    if (unitArea === MeasurementUnit.KM2) {
      return 'km²';
    }
    return unitArea;
  }, [areaSetting]);

  const handleSortTable = (key: TableKeyEnum, sortOrder: TSortOrder) => {
    let newSortOrder: TSortOrder = null;
    if (!sortOrder) newSortOrder = 'DESC';
    if (sortOrder === 'ASC') newSortOrder = 'DESC';
    if (sortOrder === 'DESC') newSortOrder = 'ASC';
    setSortInfo({ key, sortOrder: newSortOrder });

    const newTableHeaders = tableHeaders.map((header) =>
      header.key === key ? { ...header, sortOrder: newSortOrder } : { ...header, sortOrder: null }
    );
    setTableHeaders(newTableHeaders);
  };

  const renderSortIcon = (sortOrder: TSortOrder) => {
    switch (sortOrder) {
      case 'ASC':
        return DescIcon;
      case 'DESC':
        return AscIcon;
      default:
        return SwapVertIcon;
    }
  };

  const formatData = (data: any[]) => {
    const helper = {} as any;
    const result = data.reduce((pre, current) => {
      const key = current.landUse;

      if (!helper[key]) {
        helper[key] = Object.assign({}, current);
        pre.push(helper[key]);
      } else {
        helper[key].area += current.area;
        helper[key].length += current.length;
      }

      return pre;
    }, []);
    return result;
  };

  const generatePercent = (data: any[]) => {
    const sumArea = data
      .map((_item: any) => _item.area)
      .reduce((previousValue: number, currentValue: number) => new BigNumber(previousValue).plus(currentValue), 0);
    let sumPercent = new BigNumber(0);

    const dataPercent = data.map((_item: any, index: number) => {
      if (index === data.length - 1) {
        return {
          ..._item,
          percent: new BigNumber(100).minus(sumPercent).toNumber(),
        };
      }
      const percent = new BigNumber(_item.area).div(sumArea).multipliedBy(100).toFixed(2);
      sumPercent = new BigNumber(percent).plus(sumPercent);
      return {
        ..._item,
        percent: +percent,
      };
    });
    return dataPercent;
  };

  const summaryLandUseAnalysis = useCallback((data: any[]) => {
    if (data.length > 0) {
      const result = formatData(data);
      const dataPercent = generatePercent(result);
      return dataPercent;
    }
    return [];
  }, []);

  const tableLandUseArea = useMemo(() => {
    if (allLocationCropType && allLocationCropType.length > 0) {
      const mergeArray = allLocationCropType.reduce((pre: any, curent: any) => {
        return pre.concat(curent.landUseAnalysisSummary);
      }, []);
      //if exist m_use value, the area value will follow m_use data -> ticket AER21163-943
      const convertedMergeArray = mergeArray.map((item: { museArea: number | null; area: number }) => ({
        ...item,
        area: item.museArea ? item.museArea : item.area,
      }));
      const formatArray = summaryLandUseAnalysis(convertedMergeArray);

      const totalArea = formatArray.reduce((pre: any, current: any) => {
        return pre + current.area;
      }, 0);

      const totalAreaPercent = formatArray.reduce((pre: any, current: any) => {
        return new BigNumber(pre).plus(current.percent);
      }, 0);

      const sortFunction = (first: IRow, second: IRow) => {
        const { key, sortOrder } = sortInfo;
        if (key === TableKeyEnum.NAME) {
          return sortOrder === 'ASC'
            ? first.landUse?.localeCompare(second.landUse)
            : second.landUse?.localeCompare(first.landUse);
        } else if (key === TableKeyEnum.AREA || key === TableKeyEnum.PERCENTAGE) {
          return sortOrder === 'ASC' ? first.area - second.area : second.area - first.area;
        }
        return 0;
      };

      const sortedArray = formatArray.sort((a: IRow, b: IRow) => sortFunction(a, b));

      if (sortedArray && sortedArray.length > 0) {
        return (
          <>
            {sortedArray.map((item: any, index: number) => {
              return (
                item.area > 0 && (
                  <TableRow key={index}>
                    <TableCellSummary>{item.landUse}</TableCellSummary>
                    <TableCellSummary>{clientAreaUnitMeasurement(item.area, areaSetting)}</TableCellSummary>
                    <TableCellSummary>{item.percent} %</TableCellSummary>
                  </TableRow>
                )
              );
            })}
            <TableRow>
              <TableCellSummary isBold={true}>{t('trans.total')}:</TableCellSummary>
              <TableCellSummary isBold={true}>{clientAreaUnitMeasurement(totalArea, areaSetting)}</TableCellSummary>
              <TableCellSummary isBold={true}>{totalAreaPercent.toNumber()} %</TableCellSummary>
            </TableRow>
          </>
        );
      }
    }
  }, [allLocationCropType, areaSetting, sortInfo, summaryLandUseAnalysis, t]);

  return (
    <>
      <InputLabel sx={{ color: (theme) => theme.palette.primary.main }}>{`${t('analytic.land_use')} (${t(
        'trans.area'
      )})`}</InputLabel>
      <TableContainer
        sx={{
          overflow: 'hidden',
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '5px',
          mt: '-1px',
          mb: '12px',
        }}>
        <Table>
          <TableHead>
            {
              <TableRow
                component="tr"
                sx={{
                  backgroundColor: (theme) => theme.palette.background.paper,
                }}>
                {tableHeaders.map((item) => (
                  <TableCellHeaderSummary key={item.key}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      {`${t(item.label)} ${item.key === TableKeyEnum.AREA ? `(${areaUnit})` : ''}`}
                      <SvgIcon
                        component={renderSortIcon(item.sortOrder)}
                        inheritViewBox
                        sx={{ fontSize: '14px', cursor: 'pointer' }}
                        onClick={() => handleSortTable(item.key, item.sortOrder)}
                      />
                    </Box>
                  </TableCellHeaderSummary>
                ))}
              </TableRow>
            }
          </TableHead>
          <TableBody
            sx={{
              pt: 0,
              flexDirection: 'column',
              minHeight: '510px',
              maxHeight: '510px',
              overflowY: 'scroll',
              '-ms-overflow-style': 'none' /* IE and Edge */,
              scrollbarWidth: 'none' /* Firefox */,
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}>
            {tableLandUseArea}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default LandUseTableArea;
