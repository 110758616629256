import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Collapse, Typography } from '@mui/material';
import { useBoxLayout, useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { useState } from 'react';
import PhotoIntelligenceSummaryTable from './PhotoIntelligenceSummaryTable';

const PhotoIntelligenceSummaryTableCollapse = () => {
  const [isOpenCollapse, setIsShowPhotoIntelligence] = useState<boolean>(true);

  const classes = useLayerSelectCardStyle();
  const classes2 = useBoxLayout();

  return (
    <Box className={classes2.boxLayout} style={{ padding: '20px 10px', borderRadius: '20px', marginBottom: '20px' }}>
      <Box sx={{ px: '10px' }}>
        <Button
          endIcon={
            isOpenCollapse ? (
              <ExpandMoreIcon style={{ fontWeight: 'bold' }} />
            ) : (
              <ChevronRightIcon style={{ fontWeight: 'bold' }} />
            )
          }
          onClick={() => setIsShowPhotoIntelligence(!isOpenCollapse)}
          classes={{ root: classes.buttonTextStyle }}
          style={{ width: '100%' }}>
          <Typography
            className={classes.buttonTextStyle}
            style={{ marginRight: 'auto', fontWeight: 'bold', fontSize: '14px !important' }}>
            Photo Intelligence Summary Table
          </Typography>
        </Button>
      </Box>
      <Collapse in={isOpenCollapse} timeout="auto" unmountOnExit sx={{ mt: '8px' }}>
        <PhotoIntelligenceSummaryTable />
      </Collapse>
    </Box>
  );
};

export default PhotoIntelligenceSummaryTableCollapse;
